import * as actionTypes from "./constants";

const defaultState = {
  userInfo: null,
  systemDate: null,
  loginStatus: false
}

export default function hotelDetail(state = defaultState, action) {
  switch(action.type) {
    case actionTypes.CHANGE_USER_INFO:
      return {
        ...state,
        userInfo: action.value
      };
    case actionTypes.CHANGE_SYSTEM_DATE:
      return {
        ...state,
        systemDate: action.value
      };
    case actionTypes.CHANGE_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.value
      };
    default:
      return state;
  }
}