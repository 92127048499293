import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { message } from 'antd';
import { systemInfo } from './utils/common';
// 全局配置message的位置
const sysInfo = systemInfo();
const top = (Math.ceil(sysInfo.height/2)-42) + 'px';
message.config({
  duration: 3,// 持续时间
  top, // 到页面顶部距离
  maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
});


ReactDOM.render(<App />,document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
