import React, { useState, useEffect, useRef, Suspense, lazy } from "react";
import { Spin } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from "react-router-dom";
import { gdsHotelRateDetail, cancelRequest, hotelRoom } from '../../../api';
import { getCheckDate } from '../../../store/home/action';
import { 
  getHotelBaseInfo,
  getRatePlan,
  changeRatePlanByRoomList,
  getMoreRatePlan,
  changeCode
} from '../../../store/hotelDetail/action';
import { changeRateDetail, changeRateDescription } from '../../../store/hotelBooking/action';
import { getUserInfo } from '../../../store/util/action';
import RatePlanByRoomList from './RatePlanByRoomList';
import { Calendar, Popup, PhotoSwiper, TabBar } from '../../../components';
import { loadingImage, hotelDefaultImage, hotelDetailTabBar } from '../../../utils/constants';
import { showRatePlanByRoomList } from '../../../utils/hotelDetailRateByRoom';
import { setCheckDateStorage } from '../../../utils/hotel';
import './style.scss';

const Rate = lazy(() => import('./Rate'));

function HotelDetail(props) {
  let loadingRateSubmit = false;
  const no = useParams().no;
  const dispatch = useDispatch();
  const checkDate = useSelector(state => state.home.checkDate);
  const { checkin, checkout } = checkDate;
  const userInfo = useSelector(state => state.util.userInfo);
  const hotelDetailPageData = useSelector(state => state.hotelDetail);
  const { loading, baseInfo, loadingRates, ratePlanByRoomList, resultReferenceCode } = hotelDetailPageData;
  const [rateInfo, setRateInfo] = useState(null);
  
  const popupRef = useRef();
  //日历组件状态
  const [calendarState, setCalendarState] = useState(false);
  const calendarRef = useRef();
  const roomListRef = useRef();
  
  
  // 初始化 
  useEffect(() => {
    if(!userInfo) {
      // 获取 用户信息
      dispatch(getUserInfo());
    }
    // 获取 酒店基本信息
    dispatch(getHotelBaseInfo(no));

    // 获取 酒店房型
    hotelRoom(no).then((res) => {
      roomListRef.current = res.data;
      dispatch(getRatePlan(no, {checkinDate:checkin, checkoutDate: checkout}, roomListRef.current));
    }).catch((err) => {});
    // 卸载组件时
    return () => {
      dispatch(changeCode(""));
      cancelRequest();
    };
  }, [no, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  // 如果有更多方案请求
  useEffect(() => {
    if(resultReferenceCode) {
      dispatch(getMoreRatePlan(no, {checkinDate:checkin, checkoutDate: checkout, resultReferenceCode}, ratePlanByRoomList))
    }
  }, [dispatch, resultReferenceCode]) // eslint-disable-line react-hooks/exhaustive-deps


  // 日期发生变化
  function getChangeDate(date) {
    calendarRef.current.hide();
    // 日期发生变化，取消之间的请求
    cancelRequest();
    dispatch(getCheckDate(date));
    setCheckDateStorage(date);
    dispatch(getRatePlan(no, {checkinDate:date.checkin, checkoutDate: date.checkout}, roomListRef.current))
  }


  // 展示相关房型下方案列表
  function showRoomList(roomIndex) {
    const data = showRatePlanByRoomList(roomIndex, ratePlanByRoomList);
    dispatch(changeRatePlanByRoomList([...data]));
  }
  

  // 点击预订
  function booking(type, data = {}) {
    const rateDetail  = {};
    rateDetail.isChina = baseInfo.isChina;
    rateDetail.hotelNo = no;
    rateDetail.checkDate = checkDate;

    if(type === 1) {
      rateDetail.roomDetail = data.roomDetail;
      rateDetail.rate = data.rate;
    }

    if(type === 2) {
      // 点击底部的预订，此时方案信息已经加载
      rateDetail.roomDetail = rateInfo.roomDetail;
      rateDetail.rate = rateInfo.rate;
      dispatch(changeRateDescription(rateInfo.rateDescription));
    }
    
    dispatch(changeRateDetail(rateDetail));

    // 前往booking页面
    props.history.push('/hotel/booking', {});
  }

  // 展示相关方案详情
  function showRateInfo(roomDetail, rate) {
    popupRef.current.show();
    rate.cnyTotal = Math.floor(rate.cnyTotal);
    const apartData = {roomDetail, rate, childPolicy: baseInfo.childPolicy};
    setRateInfo({
      ...apartData, 
      rateLoading: true
    });
    if(!loadingRateSubmit) {
      loadingRateSubmit = true;
      // 请求方案信息
      gdsHotelRateDetail(no, rate.no).then((res) => {
        loadingRateSubmit = false;
        const rateDescription = res.data;
        if(rateDescription.point.amount > 0) {
          if(rateDescription.point.amount >= 10000) {
            rateDescription.pointDengwu = rateDescription.point.amount/10000;
            if(rateDescription.point.amount%10000 > 0) {
              rateDescription.pointDengwu = rateDescription.pointDengwu.toFixed(1);
            }
          } else {
            rateDescription.pointDengwu = rateDescription.point.amount/4000;
            if(rateDescription.point.amount%4000 > 0) {
              rateDescription.pointDengwu = rateDescription.pointDengwu.toFixed(1);
            }
          }
        }
        setRateInfo({
          ...apartData, 
          rateLoading: false,
          rateDescription
        });
      }).catch((err) => {
        loadingRateSubmit = false;
        setRateInfo({...apartData, rateLoading: false, rateDescription: {error: '加载失败'}});
      });
    }
  }

  return (
    <>
      {!loading ? (
        <div className="hotel-detail-wrapper">
          {(baseInfo && baseInfo.no) ? (
            <>
              <div className="photos-wrapper">
                {baseInfo.images.length > 0? (
                  <Suspense fallback={<div>Loading...</div>}>
                    <PhotoSwiper images={baseInfo.images} />
                  </Suspense>
                ) : (
                  <img src={hotelDefaultImage} alt="" />
                )} 
              </div>
              <div className="base-info-container">
                <Link to={`/hotel/baseInfo/${baseInfo.no}`} className="flex-row h-between">
                  {baseInfo.nameCn ? (
                    <div>
                      <div className="name">{baseInfo.nameCn}</div>
                      {!baseInfo.isChina && <div className="name-en">{baseInfo.name}</div>}
                    </div>
                  ) : (
                    <div className="name">{baseInfo.name}</div>
                  )}
                  <div className="flex-row">
                    <span className="fs-12 text-link">详情</span>
                    <span className="arrow iconfont icon-xiangyoujiantou"></span>
                  </div>
                </Link>
                
                <div className="address">
                  <span className="iconfont icon-dizhi"></span>
                  <span className="span">{baseInfo.addressCn ? baseInfo.addressCn : baseInfo.address}</span>
                </div>
              </div>
              <div className="gray-space"></div>
              <div className="booking-container">
                {/* 展示方案 */}
                <div className="condition-box" onClick={() => {
                  setCalendarState(true);
                  calendarRef.current.show();
                }}>
                  <div className="static-date-box">
                    <div>
                      <span className="dateNum">{checkDate.checkinTxt}</span>
                      <span className="dateDay">{checkDate.checkinDay}</span>
                    </div>
                    <div className="line"></div>
                    <div>
                      <span className="dateNum">{checkDate.checkoutTxt}</span>
                      <span className="dateDay">{checkDate.checkoutDay}</span>
                    </div>
                  </div>
                  <div className="flex-row v-center">
                    <div className="flex-column">
                      <span className="fs-12 ta-center">共{checkDate.days}晚</span>
                      <div className="tax-tip">已含税总价</div>
                    </div>
                    <span className="arrow iconfont icon-xiangyoujiantou"></span>
                  </div>
                </div>
                {/* 加载方案 */}
                {loadingRates ? (
                  <div className="loading-box flex-row v-center">
                    <img className="icon" src={loadingImage} alt="" />
                    <span className="loading-txt">更低价格、更多优待，马上就来...</span>
                  </div>
                ) : (
                  <div>
                    {ratePlanByRoomList.length > 0 ? (
                      <RatePlanByRoomList 
                        userInfo={ userInfo } 
                        baseInfo={ baseInfo }
                        showRoomList={ showRoomList }
                        showRateInfo = { showRateInfo }
                        booking = {booking}
                        list={ ratePlanByRoomList }
                      />
                    ) : (
                      <div className="loading-box flex-column v-center">
                        <div>该酒店此段时间内暂无价格</div>
                        <div>请选择其他时间预订或选择其他酒店~</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <TabBar list={hotelDetailTabBar} />
            </>
          ) : (
            <div className="loading-wrapper">
              <div className="loading-fail">数据加载失败,刷新试试看~</div>
            </div>
          )}
        </div>
      ) : (
        <div className="loading-wrapper">
          <Spin style={{ width: "200px", transform: "translateX(-50%)" }} tip="酒店详情正在加载中...">
            <div style={{ display: 'none' }}></div>
          </Spin>
        </div>
      )}
       <Suspense fallback={<div>Loading...</div>}>
        <Popup ref={popupRef}>
          {rateInfo && (
          <Rate 
            checkDate={checkDate} 
            baseInfo={baseInfo} 
            rateData={rateInfo} 
            userInfo={userInfo}
            booking={booking}
          />)}
        </Popup>
       </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Calendar calendarState={calendarState} ref={calendarRef} checkDate={checkDate} onChange={getChangeDate} />
      </Suspense>
    </>
  );
}

export default React.memo (HotelDetail);