

export default (props) => {
  
  
  return (
    <div>
      <img 
        style={{
          display:'block',
          width: '100%',
          height: 'auto'
        }}
        src="https://staticfile.badazhou.com/20240620/7782139e0e0d65a31e6fb245ba5fdc84.png" 
        alt="" 
      />
    </div>
  );
}
