import React from "react";
import { Route, Switch } from "react-router";
// react 路由守卫
export const renderRoutes = (routes, extraProps = {}, switchProps = {}) =>
  routes ? (
    <Switch {...switchProps}>
        {routes.map((route, i) => (
        <Route
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          render={props => {
            document.title = route.title || "八大洲旅游";
            return route.render ? (
              route.render({...props, ...extraProps, route:route})
            ) : (
              <route.component {...props} {...extraProps} route={route} />
            )
          }}
        />
      ))}
    </Switch>
  ) : null;
