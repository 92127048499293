import dayjs from 'dayjs';
import * as actionTypes from './constants';
import { DEFAULT_CITY } from '../../utils/constants';
import { getStorage } from "../../utils/common";
import { getCheckDateTxt, getCityStorage, getCheckDateStorage } from '../../utils/hotel';

// 初始化先获取本地缓存
const city = getCityStorage() || DEFAULT_CITY;
let checkDate = getCheckDateStorage();
const today = dayjs(new Date()).format('YYYY-MM-DD');
const tomorrow = dayjs(today).add(1, 'day').format('YYYY-MM-DD');
const merchantNo = getStorage('merchantNo') || '';

if (checkDate) {
  // 判断本地缓存的 入住时间 是否在今天日期之前
  if(dayjs(checkDate.checkin).isBefore(dayjs(today))) {
    checkDate.checkin = today;
  }
  // 判断本地缓存的 离店时间 是否在明天之前
  if(dayjs(checkDate.checkout).isBefore(dayjs(tomorrow))) {
    checkDate.checkout = tomorrow;
  }
} else {
  checkDate = {checkin: today, checkout: tomorrow}
}
const checkDateTxt = getCheckDateTxt(checkDate);
checkDate = {...checkDate, ...checkDateTxt};

// 根据merchantNo 设置banner
let banner = actionTypes.defaultBanner;
if(merchantNo) {
  banner = actionTypes.merchantList[merchantNo] || actionTypes.defaultBanner;
}

const defaultState = {
  city,
  checkDate,
  selBrand: null,
  selMember: null,
  merchantNo,
  banner
};


export default function home(state = defaultState, action) {
  switch(action.type) {
    case actionTypes.GET_CITY:
      return {
        ...state,
        city: action.city
      };
    case actionTypes.GET_CHECKDATE:
      return {
        ...state,
        checkDate: action.checkDate
      };
    case actionTypes.GET_BRANDLIST:
      return {
        ...state,
        brands: action.brands
      };
    case actionTypes.CHANGE_BRAND:
      return {
        ...state,
        selBrand: action.value
      };
    case actionTypes.CHANGE_MEMBER:
      return {
        ...state,
        selMember: action.value
      };
    case actionTypes.CHANGE_MERCHANTNO:
      return {
        ...state,
        merchantNo: action.value
      };
    case actionTypes.CHANGE_BANNER:
      return {
        ...state,
        banner: action.value
      };
    default:
      return state;
  }
}
