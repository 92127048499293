import React, {  useEffect, useRef } from "react";
import { wechatGenerateScheme } from "../../../api";
import "./style.scss";

export default (props) => {
  const schemeRef = useRef();
 
  // 初始化设置参数
  useEffect(() => {
   
    const path = "/pages/index/index";
    const randomNum = parseInt(Math.random()*100000000);
    const query = `merchantNo=gVApC3XlLVFi&randomNum=${randomNum}`;

    // 外部浏览器打开的请求跳转吗
    wechatGenerateScheme({path, query}).then((res) => {
      schemeRef.current = res.data;
    }).catch((err) => {});

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function goToMiniApp() {
    window.location.href = schemeRef.current.scheme;
  }
  
  return (
    <div className="honor-wrapper h5">
      <div className="img-wrapper">
        <img src="https://staticfile.badazhou.com/20211229/1d50b4ae09e2b137d787c18aa42d66f5.jpeg" alt="" className="img img1" />
        <img src="https://staticfile.badazhou.com/20211229/19ec2bf6aa012ca9bf031655b6bdfd58.jpeg" alt="" className="img img2" />
        <img src="https://staticfile.badazhou.com/20211229/9d3fd13d2094b7164afd1737a9797265.jpeg" alt="" onClick={goToMiniApp} className="img img3" />
        <img src="https://staticfile.badazhou.com/20211229/bde96d70b241c4badd521a91520f5cce.jpeg" alt="" className="img img4" />
        <img src="https://staticfile.badazhou.com/20211229/0b63bd8abfceaa2feb3df5238626052c.jpeg" alt="" className="img img5" />
        <img src="https://staticfile.badazhou.com/20211229/903da04e9fb8e2d621071cd114a3ca7b.jpeg" alt="" className="img img6" />
      </div>
    </div>
  );
}
