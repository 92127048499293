import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Divider, message} from 'antd';
import { 
  getOrderList, 
  refreshMoreOrderList, 
  changePageCount, 
  changeAllLoading
 } from '../../../store/orderList/action';
import { forceCheck } from 'react-lazyload';
import { Scroll } from '../../../components';
import Order from './order';
import './style.scss';


let total = 1;
const PERPAGE = 20;

function OrderList(props) {

  const dispatch = useDispatch();
  let {orders, meta, pullupLoading, page, allLoaded} = useSelector(state => state.orderList);

  // 初始化获取数据 
  useEffect(()=> {
    dispatch(getOrderList({ page:1 }));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  // scroll 上拉加载更多订单数据
  function handlePullUp() {
    if(meta) {
      total =  Math.ceil(meta.total / PERPAGE);
      if (!pullupLoading && page < total) {
        dispatch(refreshMoreOrderList({ 
          page: page + 1
        }, orders));
        dispatch(changePageCount(page+1));
      }
      if (page === total) {
        dispatch(changeAllLoading(true));
      }
    }
  }

  // 点击订单
  function orderDetail(no, type) {
    if(type === 'GDS_HOTEL') {
      props.history.push(`/order/${no}/detail`, {});
    } else {
      message.info('请在当初预订平台，查看订单');
    }
  }

  return (
    <div className="order-list-wrapper">
      {/* <div className="search-box flex-row v-center h-between">
        <div className="flex-row v-center">
          <span className="iconfont icon-sousuo"></span>
          <span>订单快速查询</span>
        </div>
        <span className="arrow iconfont icon-xiangxiajiantou"></span>
      </div> */}

      {orders ? (
        <div className="order-scroll-wrapper">
          {(orders.length > 0) ? (
              <Scroll 
              onScroll={forceCheck}
              pullUpLoading={ pullupLoading } 
              pullUp={ handlePullUp }
            >
              <div className="list">
                {orders.map(item => 
                  <Order key={item.id} order={item} orderDetail={orderDetail} />
                )}
                {allLoaded && (
                  <Divider plain style={{'color': '#b2b2b2'}}>共{meta.total}个订单，没有更多啦...</Divider> 
                )}
              </div>
            </Scroll>
          ) : (
            <div className="no-data">暂时没有数据,试试刷新页面～</div>
          )}
        </div>
      ) : (
        <div className="loading-wrapper">
          <Spin tip="订单正在加载中..." />
        </div>
      )}

      
    </div>
  );
}


export default React.memo (OrderList);