import React, { useState, useEffect } from "react";
import { parse } from "query-string";
import { useDispatch } from 'react-redux'
import { useLocation } from "react-router-dom";
import { Modal,  Input, message } from 'antd';
import { changeMerchantNo, changeBanner } from '../../../store/home/action';
import {setStorage, getStorage } from '../../../utils/common';
import { hasJoinActivity, joinActivity} from '../../../api';
import "./style.scss";


export default (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [code, setCode] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const jdHotelPreferentialStatus = getStorage('jdHotelPreferentialStatus') || false;
  const [isChange, setIsChange] = useState(jdHotelPreferentialStatus);

  let { search } = useLocation();
  const dispatch = useDispatch();
  let merchantNo = 'cdFT2HRXBBT4';
  if(search) {
    search = parse(search);
    if(search.merchantNo) {
      merchantNo = search.merchantNo;
    }
  }

  useEffect(() => {
    if(merchantNo) {
      dispatch(changeMerchantNo(merchantNo));
      dispatch(changeBanner(merchantNo));
      setStorage('merchantNo', merchantNo);
    }
  
  }, [merchantNo, dispatch]);

  useEffect(() => {
    const token = getStorage('token');
    if(token && isChange) {
      signUp();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  function getCode() {
    setModalStatus(true);
  }

  function handleOk() {
    if(code.length !== 8) {
      message.error('请输入正确的兑换码');
      return;
    }
    message.success('兑换成功，请查询并预订“专享价”酒店，享免费双人早餐', 2);
    setIsChange(true);
    setStorage('jdHotelPreferentialStatus', true);
    setModalStatus(false);
  }
  // 报名活动
  function signUp() {
    hasJoinActivity(48).then(({data}) => {
      // 如果参与过活动
      if(data.status) {
        setIsSignUp(data.status);
      } else {
        joinActivity(48).then((res) => {
          message.success('活动报名成功，预订酒店吧～', 2);
        }).catch((err) => {});
      }
    }).catch((err) => {});
  }

  function index() {
    props.history.replace('/');
  }

  return (
    <div className="jd-silver-card-container">
      <img className="img img1" src="https://staticfile.badazhou.com/20221102/2ae866b65b89ea4680df508d9fb0ce50.jpeg" alt="" />

      {isChange ? (
        <div className="wanhao">
          {isSignUp ? (
            <> 
              <img className="img img-btn" onClick={index} src="https://staticfile.badazhou.com/20221115/6bee92ae82ed1ecafc60320b0ce5a82f.png" alt="" />
              <div className="ta-center success-color fs-14" style={{marginTop: '5px'}}>您已经报名成功～</div>
            </>
          ) : (
            <img className="img img-btn" onClick={signUp} src="https://staticfile.badazhou.com/20221115/2cd119759aa5821ff474c6bb815748ba.png" alt="" />
          )}
        </div>
       
      ) : (
        <img className="img img2" onClick={getCode} src="https://staticfile.badazhou.com/20221102/69a0b7370967250934ee4638106ba8c1.jpeg" alt="" />
      )}
      
      <img className="img img3" src="https://staticfile.badazhou.com/20221102/fad5db0f7b5e2fa0d5dad90b5e7c8e9f.jpeg" alt="" />
      <img className="img img4" src="https://staticfile.badazhou.com/20221102/6831ec4c9e4610ea398c2c382e6922e6.jpeg" alt="" />

      {modalStatus && (
        <Modal 
        title="兑换码" 
        centered="true" 
        visible={modalStatus} 
        onOk={handleOk} 
        onCancel={() => setModalStatus(false)}
        okText="立即兑换" 
        cancelText="取消">
          <Input style={{border: '1px solid #e5e5e5'}} onChange={(e) => setCode(e.target.value)} placeholder="请输入8位兑换码" />
        </Modal>
      )}
    </div>
  );
}