import * as actionTypes from './constants';
import { getCheckDateTxt } from '../../utils/hotel';
import { config } from '../../api';

export const getCity = (value) => {
  return {
    type: actionTypes.GET_CITY,
    city: value
  }
};

export const changeSelBrand = (value) => {
  return {
    type: actionTypes.CHANGE_BRAND,
    value
  }
};

export const changeSelMember = (value) => {
  return {
    type: actionTypes.CHANGE_MEMBER,
    value
  }
};

export const changeMerchantNo = (value) => {
  return {
    type: actionTypes.CHANGE_MERCHANTNO,
    value
  }
};

export const changeBanner = (merchantNo) => {
  return {
    type: actionTypes.CHANGE_BANNER,
    value: actionTypes.merchantList[merchantNo] || actionTypes.defaultBanner
  }
};



export const changeTabBar = (value) => {
  return {
    type: actionTypes.CHANGE_TABS,
    value
  }
};

export const getCheckDate = (value) => {
  const checkDateTxt = getCheckDateTxt(value);
  const checkDate = {...value, ...checkDateTxt};
  return {
    type: actionTypes.GET_CHECKDATE,
    checkDate
  }
};

// 异步的 action
export const getBrandList = () => {
  return async dispatch => {
    const result = await config(actionTypes.CONFIG_BRANDS);
    dispatch({
      type: actionTypes.GET_BRANDLIST,
      brands: result
    })
  }
}
