/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { wechatGenerateScheme } from "../../api";

function MdRedirect (props) {
  const schemeRef = useRef();

  const path = "/pages/trip/madrid/madrid";
  const randomNum = parseInt(Math.random()*100000000);
  const query = `keyword=madrid&randomNum=${randomNum}`;

  useEffect(() => {
    // 打开微信小程序
    wechatGenerateScheme({path, query}).then((res) => {
      schemeRef.current = res.data;
      window.location.href = schemeRef.current.scheme;
    }).catch((err) => {});
  }, [])
  
  return (
          <></>
    )
}

export default MdRedirect