import { lazy } from "react";

export const Calendar = lazy(() => import('./Calendar/DateManger'));
export const SingalCalendar = lazy(() => import('./SingalCalendar/DateManger'));
export const Contact = lazy(() => import('./Contact/index'));
export const PhotoSwiper = lazy(() => import('./PhotoSwiper/index'));
export const Popup = lazy(() => import('./Popup/index'));
export const RoomDetail = lazy(() => import('./RoomDetail/index'));

export {default as Scroll} from './Scroll/index';
export {default as TabBar} from './TabBar/index';