import React, { useState, useEffect, useRef } from "react";
import { Input, message } from 'antd';
import { wechatGenerateScheme, officialWechatJsConfig } from "../../../api";
import "./style.scss";


export default (props) => {
 
  const [code, setCode] = useState('');
  const [isChange, setIsChange] = useState(false);

  const path = "/pages/index/index";
  const randomNum = parseInt(Math.random()*100000000);
  const query = `merchantNo=cdFT2HRXBBT4&randomNum=${randomNum}`;
  const url = query ? `${path}?${query}` : path;

  const [isWeixin, setWeixin] = useState(false);
  const schemeRef = useRef();
  const ua = navigator.userAgent;

  // 初始化设置参数
  useEffect(() => {
    
    const result = ua.indexOf("MicroMessenger");
    if (result !== -1) {
      //在微信浏览器打开
      setWeixin(true);
      // 插入微信js
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
      document.head.appendChild(script);
      // 请求配置数据
      const url = window.location.href;
      const jsApis = 'updateAppMessageShareData'
      officialWechatJsConfig({url, jsApis}).then((res) => {
        const info = res.data;
        window.wx.config({
          debug: false,
          appId: info.appId,
          timestamp: info.timestamp,
          nonceStr: info.nonceStr,
          signature: info.signature,
          jsApiList: info.jsApiList,
          openTagList: [
            'wx-open-launch-weapp'
          ]
        });
      }).catch((err) => {});
    } else {
      // 外部浏览器打开的请求跳转吗
      wechatGenerateScheme({path, query}).then((res) => {
        schemeRef.current = res.data;
      }).catch((err) => {});
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function goToMiniApp() {
    window.location.href = schemeRef.current.scheme;
  }

  function handleOk() {
    let newCode = code.trim();
    if(newCode.length !== 8) {
      message.error('请输入正确的激活码');
      return;
    }

    message.success('激活成功', 2);
    setIsChange(true);
   
  }
  

  return (
    <div className="page-content jd-container">
      <img className="img img1" src="https://staticfile.badazhou.com/20230515/3307c9b7d5dbd911fbd3d1b713513e44.jpeg" alt="" />

      <div className="btn-box">

        {isChange ? (
          <>
            {isWeixin ? (
              <div style={{width: '4.34rem', height: '.88rem',margin: '.3rem auto'}}>
                <wx-open-launch-weapp
                  id="launch-btn" 
                  username="gh_8b405445c453" 
                  path={url}
                  style={{ width: '4.34rem', height: '.88rem' }}
                >
                  <script type="text/wxtag-template">
                    <img style={{ width: '100%', height: '100%' }} src="https://staticfile.badazhou.com/20230203/c7e3757dcc585caab55389a455118fde.png" alt="" />
                  </script>
                </wx-open-launch-weapp>
              </div>

            ) : (
              <img 
                className="btn" 
                onClick={goToMiniApp} 
                src="https://staticfile.badazhou.com/20230203/c7e3757dcc585caab55389a455118fde.png" alt=""
              />
            )}
          </>
        ) : (
          <div>
            <Input className="input" onChange={(e) => setCode(e.target.value)} placeholder="请输入8位激活码" />
            <img 
              className="btn" 
              onClick={handleOk} 
              src="https://staticfile.badazhou.com/20230131/2e08aac58aed7bcb71d7191cebaab870.png" alt=""
            />
          </div>
        )}

      </div>

      
      
      <img className="img img3" src="https://staticfile.badazhou.com/20230515/e3cec06aaabbc9f8a0652cb6f6cec96a.jpeg" alt="" />
      <img className="img img4" src="https://staticfile.badazhou.com/20230515/85e13bf07764d23a84cdc91c31f1f9b8.jpeg" alt="" />
      <img className="img img5" src="https://staticfile.badazhou.com/20230515/68e13fd7699109fe895f5c85553c12e4.jpeg" alt="" />

      {/* {modalStatus && (
        <Modal 
        title="活动激活码" 
        centered="true" 
        visible={modalStatus} 
        onOk={handleOk} 
        onCancel={() => setModalStatus(false)}
        okText="立即激活" 
        cancelText="取消">
          <Input style={{border: '1px solid #e5e5e5'}} onChange={(e) => setCode(e.target.value)} placeholder="请输入8位激活码" />
        </Modal>
      )} */}
     
    </div>
  );
}