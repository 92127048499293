import { Redirect } from "react-router-dom";
import Root from '../pages/Root';
import Index from '../pages/Index';
import HotelList from '../pages/Hotel/List';
import HotelDetail from '../pages/Hotel/Detail';
import HotelBenefitIntro from '../pages/Hotel/BenefitIntro';
import HotelBooking from '../pages/Hotel/Booking';
import HotelTerm from '../pages/Hotel/Term';
import HotelAdvantage from '../pages/Hotel/Advantage';
import HotelArea from '../pages/Hotel/AreaList';
import HotelSearch from '../pages/Hotel/Search';
import OrderList from '../pages/Member/OrderList';
import OrderDetail from '../pages/Member/OrderDetail';
import Certification from '../pages/Member/Certification';
import Login from '../pages/Login';
import RegisterAgreement from '../pages/Member/RegisterAgreement';
import PrivacyPolicy from '../pages/Member/PrivacyPolicy';
import GoToWxApp from '../pages/GoToWxApp';
import EnterMiniApp from '../pages/EnterMiniApp';
import About from '../pages/About';
import MyCenter from '../pages/MyCenter';
import CustomerService from '../pages/Hotel/CustomerService';
import HotelBaseInfo from '../pages/Hotel/BaseInfo';
import Test from '../pages/Test';
import JuneyaoAir from '../pages/Subject/JuneyaoAir';
import JuneyaoAir2 from '../pages/Subject/JuneyaoAir2';
import SilverCard from '../pages/Subject/SliverCard';
import JdSilverCard from '../pages/Subject/JdSilverCard';
import JdHyatt from '../pages/Subject/JdHyatt';
import LtSilverCard from '../pages/Subject/LtSilverCard';
import HotelPlatformTerm from '../pages/Hotel/PlatformTerm';
import JdQualityHotel from '../pages/Subject/JdQualityHotel';
import Honor from '../pages/Subject/Honor';
import NingBoBank from '../pages/Subject/NingBoBank';
import AddEnterpriseWeChat from '../pages/Subject/AddEnterpriseWeChat';
import JdGoldCard from "../pages/Subject/JdGoldCard";
import ImageShow from "../pages/Subject/ImageShow";
import Club8UserAgreement from "../pages/Club8/UserAgreement";
import PointRule from "../pages/Point/Rule";
import XhsRedirect from "../pages/XhsRedirect"
import SamRedirect from "../pages/SamRedirect";
import MdRedirect from "../pages/MdRedirect";
import FlyertRedirect from "../pages/FlyertRedirect"
import Alula from "../pages/Alula";

export const routes = [
  {
    path: "/",
    component: Root,
    routes: [
      {
        title: "八大洲旅游",
        path: "/",
        exact: true,
        component: Index
      },
      {
        title: "我的",
        path: "/myCenter",
        component: MyCenter
      },
      {
        title: "关于八大洲",
        path: "/about",
        component: About
      },
      {
        title: "登录",
        path: "/login",
        component: Login
      },
      {
        title: "用户协议",
        path: "/member/registerAgreement",
        component: RegisterAgreement
      },
      {
        title: "隐私协议",
        path: "/member/privacyPolicy",
        component: PrivacyPolicy
      },
      {
        title: "酒店列表",
        path: "/hotel/list",
        component: HotelList
      },
      {
        title: "搜索",
        path: "/hotel/search/:areaId",
        exact: true,
        component: HotelSearch
      },
      {
        title: "酒店详情",
        path: "/hotel/detail/:no",
        exact: true,
        component: HotelDetail
      },
      {
        title: "专享价礼遇说明",
        path: "/hotel/benefitIntro",
        exact: true,
        component: HotelBenefitIntro
      },
      {
        title: "酒店基本信息",
        path: "/hotel/baseInfo/:no",
        exact: true,
        component: HotelBaseInfo
      },
      {
        title: "常见问题",
        path: "/customerService",
        exact: true,
        component: CustomerService
      },
      {
        title: "酒店预订",
        path: "/hotel/booking",
        component: HotelBooking
      },
      {
        title: "八大洲用户预订条款",
        path: "/hotel/term",
        component: HotelTerm
      },
      {
        title: "酒店优势",
        path: "/hotel/advantage",
        component: HotelAdvantage
      },
      {
        title: "八大洲有房服务保障",
        path: "/hotel/platformTerm",
        component: HotelPlatformTerm
      },
      {
        title: "目的地",
        path: "/hotel/area",
        component: HotelArea
      },
      {
        title: "订单列表",
        exact: true,
        path: "/order/list",
        component: OrderList
      },
      {
        title: "订单详情",
        exact: true,
        path: "/order/:no/detail",
        component: OrderDetail
      },
      {
        title: "实名认证",
        path: "/certification",
        component: Certification
      },
      {
        title: "八大洲联手吉祥航空会员住酒店",
        path: "/subject/juneyaoair",
        exact: true,
        component: JuneyaoAir
      },
      {
        title: "八大洲联手吉祥航空会员住酒店",
        path: "/subject/juneyaoair2",
        exact: true,
        component: JuneyaoAir2
      },
      {
        title: "八大洲旅游银卡，享酒店品牌专属优待",
        path: "/subject/silverCard",
        exact: true,
        component: SilverCard
      },
      {
        title: "京东PLUS会员享八大洲旅游银卡",
        path: "/subject/jdSilverCard",
        exact: true,
        component: JdSilverCard
      },
      {
        title: "京东PLUS会员享八大洲旅游银卡",
        path: "/subject/jdHyatt",
        exact: true,
        component: JdHyatt
      },
      {
        title: "联通会员享八大洲旅游银卡",
        path: "/subject/ltSilverCard",
        exact: true,
        component: LtSilverCard
      },
      {
        title: "京东PLUS会员享全球高品质酒店礼遇",
        path: "/subject/jdQualityHotel",
        exact: true,
        component: JdQualityHotel
      },
      {
        title: "京东PLUS会员享90天会员金卡",
        path: "/subject/jdGoldCard",
        exact: true,
        component: JdGoldCard
      },
      {
        title: "八大洲旅游银卡会员",
        path: "/subject/honor",
        exact: true,
        component: Honor
      },
      {
        title: "八大洲旅游银卡会员",
        path: "/subject/ningBoBank",
        exact: true,
        component: NingBoBank
      },
      {
        title: "添加好友",
        path: "/subject/addEnterpriseWeChat",
        component: AddEnterpriseWeChat
      },
      {
        title: "测试",
        path: "/test",
        component: Test
      },
      {
        title: "前往微信小程序预订",
        path: "/goToWxApp",
        component: GoToWxApp
      },
      {
        title: "八大洲旅游小程序",
        path: "/enterMiniApp",
        component: EnterMiniApp
      },
      {
        title: "Club8会员章程",
        path: "/club8/userAgreement",
        component: Club8UserAgreement
      },
      {
        title: "积分累积规则",
        path: "/point/rule",
        component: PointRule
      },
      {
        title: "权益领取",
        path: "/acceptGifts",
        component: XhsRedirect
      },
      {
        title: "八大洲旅游",
        path: "/bdz",
        component: ImageShow
      },
      {
        title: "马德里专题跳转",
        path: "/madridRedirect",
        component: MdRedirect
      },
      {
        title: "八大洲权益领取",
        path: "/acceptBdzGifts",
        component: SamRedirect
      },
      {
        title: "埃尔奥拉",
        path: "/alula",
        component: Alula
      },
      {
        title: "夏日亲子时光",
        path: "/qz",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?path=%2Fpages%2Fsubject%2Fappoint%2Fappoint&query=keyword%3DtripTripsummerholiday"} />
        )
      },
      {
        title: "八大洲旅游x国泰航空",
        path: "/cpa",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?path=%2Fpages%2Fsubject%2FcpaAirline%2FcpaAirline&query=channel%3Dbdz03"} />
        )
      },
      {
        title: "【暑期】徕卡学院",
        path: "/lk",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?path=%2Fpages%2Ftrip%2Fdetail%2Fdetail&query=no%3DRNE827169"} />
        )
      },
      {
        title: "泰国酒店度假",
        path: "/thailand",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?path=%2Fpages%2Fsubject%2Fappoint%2Fappoint&query=keyword%3DthailandSubject"} />
        )
      },
      {
        title: "度假行程",
        path: "/t",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?path=%2Fpages%2Findex%2Findex&query=scene%3Dtrip"} />
        )
      },
      {
        title: "八大州旅游小程序",
        path: "/o",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?query=merchantNo%3DOFrtkxZLTMTT"} />
        )
      },
      {
        title: "八大州旅游小程序",
        path: "/h",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?query=merchantNo%3DHCCI5rc3CDN2"} />
        )
      },
      {
        title: "八大州旅游小程序",
        path: "/h",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?query=merchantNo%3DHCCI5rc3CDN2"} />
        )
      },
      {
        title: "八大州旅游小程序",
        path: "/uae",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?path=%2Fpages%2Ftrip%2Fdetail%2Fdetail&query=no%3DFXU689079"} />
        )
      },
      {
        title: "八大州旅游小程序",
        path: "/al",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?path=%2Fpages%2Ftrip%2Falula%2Falula"} />
        )
      },
      {
        title: "八大州旅游小程序",
        path: "/zzc",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?query=merchantNo%3DsKXlwQ9Q5lO3"} />
        )
      },
      {
        title: "八大州旅游小程序",
        path: "/z",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?query=merchantNo%3DzKLBAZjbDnIK"} />
        )
      },
      {
        title: "八大州旅游小程序",
        path: "/abu",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?path=%2Fpages%2Ftrip%2FabuDhabi%2FabuDhabi"} />
        )
      },
      {
        title: "八大州旅游小程序",
        path: "/ceb",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?path=%2Fpages%2Fsubject%2FceBank%2FceBank"} />
        )
      },
      {
        title: "八大州旅游小程序",
        path: "/mad",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?path=%2Fpages%2Ftrip%2Fdetail%2Fdetail%3Fno%3DTFX596177"} />
        )
      },
      {
        title: "八大州旅游小程序",
        path: "/ltmd",
        exact: true,
        render: () => (
          <Redirect to={"/enterMiniApp?path=%2Fpages%2Fmain%2FmemberDay%2FmemberDay&query=merchantNo%3DvUNad3fTJNkp"} />
        )
      },
      {
        title: "飞客会员领取八大洲银卡会籍",
        path: "/subject/flyert28",
        exact: true,
        component: FlyertRedirect
      }
    ]
  }
]
