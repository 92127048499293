import React, {Suspense} from "react";
import { Collapse } from 'antd';
import { Contact } from "../../../components";
import './style.scss';
const { Panel } = Collapse;


export default (props) => {
  const questionList = [
    {
      q: '1. 预订房型时，如何付款？',
      a: ['在预订页填写信用卡信息，八大洲将信用卡信息以加密方式直接提交至酒店，由酒店根据付款方式进行信用卡担保或扣款。由于不同酒店支持信用卡不同，通常情况下需Visa和 Master两张卡种，八大洲旅游不会记录您的任何信用卡信息。']
    },
    {
      q: '2. 促销中“订立享”和“确认后享”有什么区别？',
      a: ['【订立享】', '查询价格为折后价格，部分日期可能不可用，以查询后价格列表展示的价格标签为准。', '【确认后享】', '查询价格为原价，预订完成后，由我们向洒店申请成功后修改价格，实际支付价格以修改后的金额为准。']
    },
    {
      q: '3. 在预订房型时，价格体系分为三种：担保，押金，预付各代表什么意思？' ,
      a: ['【担保】', '预订时需信用卡信息担保，不会扣款，在免费取消期前取消不收取费用。入住时，前台现付。', '【押金】', '预订后，在一定时间内酒店一般会根据预订政策，扣取一部分或全额房费，如取消则会根据退改政策扣取取消费用。', '【预付】', '预订时酒店会扣取部分或全部房费，若需取消预订酒店将根据取消政策实际全额或部分金额扣款。']
    },
    {
      q: '4. 专享优待酒店是怎样执行的，是否有限制？',
      a: ['部分“专属优待”酒店可享100美金的酒店消费抵扣，一间房间连续入住期间，只能享受一次;若同一入住人连续入住，仅可享受一次优待。']
    },
    {
      q: '5. 如何查询房型详情及酒店详情？',
      a: ['【酒店详情】', '酒店名称后“详情/电话”点击查询。', '【房型详情】', '对应房型，点开价格，点击床型后即可查询房型详情及第三人费用']
    },
    {
      q: '6. 是否可以累积酒店会员积分及间夜？',
      a: ['实时预订酒店可在预订时候输入酒店会员号，预订成功后会直接关联至相应的酒店会员账户，正常享受酒店的积分及间夜（立鼎士酒店极少部分特殊价格，不可累积积分等）。']
    },
    {
      q: '7. 怎么取消或修改订单？',
      a: ['打开八大洲旅游，底部栏点击【我的】 - 【我的订单】 - 找到相应需要退改订单', '进入订单详情页，右上角点击【取消】按钮，请务必仔细阅读退改规定，查看是否有相应的取消费用，是否在可接受范围内。']
    }
  ]

  return (
    <div className="customer-service-wrapper">
      <div className="title">常见问题</div>
      <Collapse>
        {questionList.map((question, i1) => 
          <Panel header={question.q} key={i1}>
            {question.a.map((q, i2) => 
              <p key={i2}>{q}</p>
            )}
          </Panel>
        )}
      </Collapse>
      <div className="white-space"></div>
      <Suspense fallback={<div>Loading...</div>}>
        <Contact />
      </Suspense>
    </div>
  );
}