import { combineReducers } from 'redux';
import home from './home/reducer';
import hotelSearch from './hotelSearch/reducer';
import areaList from './areaList/reducer';
import hotelList from './hotelList/reducer';
import hotelDetail from './hotelDetail/reducer';
import hotelBooking from './hotelBooking/reducer';
import util from './util/reducer';
import orderList from './orderList/reducer';
import orderDetail from './orderDetail/reducer';
import subject from './subject/reducer';

export default combineReducers({
  home,
  hotelSearch,
  areaList,
  hotelList,
  hotelDetail,
  hotelBooking,
  orderList,
  orderDetail,
  util,
  subject
})