import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import './style.scss';

function TabBar(props) {

  const { list } = props;

  return (
    <div className="tab-bar">
        {list.map((tab, index) => 
          <div key={index} className="tab-view">
            {tab.replace ? (
              <Link to={tab.path} replace className={`tab ${tab.selected ? 'selected' : ''}`}>
                {tab.selected ? (
                  <>
                    <img className="icon" src={tab.selectedIcon} alt={tab.title} />
                    <span>{tab.title}</span>
                  </> 
                ) : (
                  <>
                    <img className="icon" src={tab.icon} alt={tab.title} />
                    <span>{tab.title}</span>
                  </> 
                )}
              </Link>
            ) : (
              <Link to={tab.path} className={`tab ${tab.selected ? 'selected' : ''}`}>
                {tab.selected ? (
                  <>
                    <img className="icon" src={tab.selectedIcon} alt={tab.title} />
                    <span>{tab.title}</span>
                  </> 
                ) : (
                  <>
                    <img className="icon" src={tab.icon} alt={tab.title} />
                    <span>{tab.title}</span>
                  </> 
                )}
              </Link>
            )}
          </div>
        )}
      </div>
  );
}

// 定义传入参数的类型
TabBar.propTypes = {
  list: PropTypes.array
}

export default TabBar;