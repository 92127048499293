import * as actionTypes from './constants';
import { gdsHotelRateDetail } from '../../api';

// 设置方案描述
export const changeRateDetail = (data) => ({
  type: actionTypes.CHANGE_RATE_DETAIL,
  value: data
});

// 设置方案描述
export const changeRateDescription = (data) => ({
  type: actionTypes.CHANGE_RATE_DESCRIPTION,
  value: data
});

// 获取方案描述
export function getRateDescription(hotelNo, RateNo) {
  return async dispatch => {
    try {
      const resData = await gdsHotelRateDetail(hotelNo, RateNo);
      dispatch(changeRateDescription(resData.data));
    } catch (error) {
      dispatch(changeRateDescription({}));
    }
  }
}