import * as actionTypes from './constants';

const defaultState = {
  brands: null,
  members: null
}

export default function hotelSearch(state = defaultState, action) {
  switch(action.type) {
    case actionTypes.CHANGE_BRANDS:
      return {
        ...state,
        brands: action.value
      };
    case actionTypes.CHANGE_MEMBERS:
      return {
        ...state,
        members: action.value
      };
    default:
      return state;
  }
}