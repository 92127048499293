import { message } from 'antd';

// 获取本地存储
export const getStorage = (name) => {
  return localStorage.getItem(name);
}
// 设置本地存储
export const setStorage = (name, val) => {
  localStorage.setItem(name, val);
}
// 移除本地缓存
export const removeStorage = (name) => {
  localStorage.removeItem(name);
}

// 获取设备的宽高
export const systemInfo = () => {
  const width = document.documentElement.clientWidth;
  const height = document.documentElement.clientHeight;
  return {width, height}
}

// 防抖函数
export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout (timer);
    }
    timer = setTimeout (() => {
      func.apply (this, args);
      clearTimeout (timer);
    }, delay);
  }
}

// 复制到粘贴板功能
export const setClipboardData = (str) => {
  // 创建input标签存放需要复制的文字
  const oInput = document.createElement('input');
  // 把文字放进input中，供复制
  oInput.value = str;
  document.body.appendChild(oInput);
  // 选中创建的input
  oInput.select();
  // 执行复制方法， 该方法返回bool类型的结果，告诉我们是否复制成功
  const copyResult = document.execCommand('copy');
  // 操作中完成后 从Dom中删除创建的input
  document.body.removeChild(oInput);
  // 根据返回的复制结果 给用户不同的提示
  if (copyResult) {
    message.success('复制成功');
  } else {
    message.error('复制失败');
  }
}

// 解析地理位置
export function parsePath(hotelPath) {
  const pathArr = hotelPath.split(',');
  const path = `${pathArr[0]},${pathArr[1]}`;
  return path;
}

// 判断目的地是否是国内
export function destIsChina(hotelPath) {
  const path = parsePath(hotelPath);
  const isChina = path === '1,7';
  return isChina;
}

// 将请求的数据 有的字段需要补全数据
export function objValue(name, value) {
  let allEnumList = getStorage('allEnumValues');
  if(allEnumList) {
    allEnumList = JSON.parse(allEnumList);
    return allEnumList[name].find(v => v.value === value);
  }
}
// 获取随机数
export function getRandomNumber(a, b) {
  return parseInt((a + Math.random() * (b-a + 1)));
}

// 引入微信 JS-SDK
export const loadWeChatSDK = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
    script.onload = () => {
      resolve(window.wx);
    };
    script.onerror = () => {
      reject(new Error('WeChat SDK load error'));
    };
    document.head.appendChild(script);
  });
};