import React from "react";
import './style.scss';


export default (props) => {

  // 用于微信小程序：专享价礼遇说明 
  // 小程序通过webview展示说明信息

  return (
    <div className="wrapper">
      <div className="mb-30">1. 如果预订多间房间时，消费抵扣额度不能合并使用，如想一起使用，请使用时分开挂帐至每个房间的消费金额；</div>
      <div className="mb-30">2. 消费抵扣额度使用范围，以酒店前台告知为准，部分酒店可能会使用固定的汇率将抵扣额度转换为当地货币，通常情况下不与酒店的其他优惠折扣同享；</div>
      <div className="mb-30">3. 连续入住期间或同住人交替预订，只能享受一次专享礼遇；</div>
      <div className="mb-30">4. 房型升级根据房态情况而定，普通房升级为普通房，行政房升级为行政房，套房升级为套房，某些房型可能不参与升级。一般情况下，只能升级一个等级。酒店有权根据入住时的房态决定是否升级，具体以酒店安排为准；</div>
      <div className="mb-30">5. 提前入住或延迟退房，需要在入住之前或离店之前与酒店确认具体时间，具体安排以酒店为准；</div>
      <div className="mb-30">6. 如专享礼遇项目与酒店集团会员礼遇中有重复项目，将享受内容更好的条目，具体入住时酒店的安排为准。</div>
      <div className="fs-12 c-66">注：本提醒为通用说明，并不代表当前专享价格中包含以上所有礼遇项目，具体包含专享内容以外部展示及促销说明信息为准。</div>
    </div>
  );
}