import React from "react";
import './style.scss';


export default (props) => {

  return (
    <div className="wrapper">
      <div>本协议是您与八大洲旅游小程序所有者（以下简称为：本公司小程序）之间就八大洲旅游小程序服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您点击，同意并继续按钮后，本协议即构成对双方有约束力的法律文件。</div>
      <div className="item">
        <div className="title">第1条  本公司小程序服务条款的确认和接纳</div>
        <div className="pdb-30">
          <span className="span">1.1  本公司小程序的各项服务的所有权和运作权归八大洲旅游所有。用户同意所有注册协议条款并完成注册程序，才能成为本公司小程序的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。</span>
        </div>
        <div className="pdb-30">
          <span className="span">1.2  用户点击同意本协议的，即视为用户确认自己具有享受本公司小程序服务、下单等相应的权利能力和行为能力，能够独立承担法律责任。</span>
        </div>
        <div className="pdb-30">
          <span className="span">1.3  如果您在18周岁以下，建议您在父母或监护人的监护参与下才能使用本公司小程序。</span>
        </div>
        <div className="pdb-30">
          <span className="span">1.4  八大洲旅游保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。</span>
        </div>
      </div>
      <div className="item">
        <div className="title">第2条  本公司小程序服务</div>

        <div className="pdb-30">
          <span className="span">2.1  八大洲旅游通过互联网依法为用户提供互联网信息等服务，用户在完全同意本协议及本公司小程序规定的情况下，方有权使用本公司小程序的相关服务。</span>
        </div>
        <div className="mb-20">
          <span className="span">2.2  用户必须自行准备如下设备和承担如下开支：</span>
        </div>
        <div className="mb-20">
        （1）上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；
        </div>
        <div>
        （2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。
        </div>
        
      </div>
      <div className="item">
        <div className="title">第3条  用户信息</div>

        <div className="pdb-30">
          <span className="span">3.1  用户应自行诚信向本公司小程序提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且八大洲旅游保留终止用户使用八大洲旅游各项服务的权利。</span>
        </div>
        <div className="pdb-30">
          <span className="span">3.2  用户在本公司小程序进行浏览、下单等行为时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，本公司小程序将予以严格保密，除非得到用户的授权或法律另有规定，本公司小程序不会向外界披露用户隐私信息。</span>
        </div>
        <div className="pdb-30">
          <span className="span">3.3  用户注册成功后，将产生用户名和密码等账户信息，您可以根据本公司小程序规定改变您的密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知本公司小程序并向公安机关报案。</span>
        </div>
        <div className="pdb-30">
          <span className="span">3.4  用户同意，八大洲旅游拥有通过邮件、短信电话等形式，向在本公司小程序注册、下单用户、入住人发送订单信息、促销活动等告知信息的权利。</span>
        </div>
        <div className="pdb-30">
          <span className="span">3.5  用户不得将在本公司小程序注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。</span>
        </div>
        <div className="pdb-30">
          <span className="span">3.6  用户同意，八大洲旅游有权使用用户的注册信息、用户名、密码等信息，登录进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。</span>
        </div>
      </div>
      <div className="item">
        <div className="title">第4条  用户依法言行义务</div>
        <div className="mb-20 bold">
        本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（1）</span>
          <span className="span">不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（2）</span>
          <span className="span">从中国大陆向境外传输资料信息时必须符合中国有关法规；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（3）</span>
          <span className="span">不得利用本公司小程序从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（4）</span>
          <span className="span">不得干扰本公司小程序的正常运转，不得侵入本公司小程序及国家计算机信息系统；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（5）</span>
          <span className="span">不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（6）</span>
          <span className="span">不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（7）</span>
          <span className="span">不得教唆他人从事本条所禁止的行为；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（8）</span>
          <span className="span">不不得利用在本公司小程序注册的账户进行牟利性经营活动；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（9）</span>
          <span className="span">不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；用户应不时关注并遵守本公司小程序不时公布或修改的各类合法规则规定。</span>
        </div>
        <div className="mb-20">本公司小程序保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。</div>
        <div>若用户未遵守以上规定的，本公司小程序有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。</div>
      </div>
      <div className="item">
        <div className="title">第5条  酒店信息</div>
        <div className="pdb-30">
        本公司小程序上的酒店价格、数量、是否有货等酒店信息随时都有可能发生变动，本公司小程序不作特别通知。由于小程序上酒店信息的数量庞大，虽然本公司小程序会尽最大努力保证您所浏览酒店信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本公司小程序网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；八大洲旅游欢迎纠错，并会视情况给予纠错者一定的奖励。
        </div>
        
      </div>

      <div className="item">
        <div className="title">第6条  订单</div>
        <div className="pdb-30">
          <span className="span">6.1  在您下订单时，请您仔细确认所购酒店的名称、价格、数量、房型、地址、电话、入住人等信息。入住人与用户本人不一致的，入住人的行为和意思表示视为用户的行为和意思表示，用户应对入住人的行为及意思表示的法律后果承担连带责任。</span>
        </div>
        <div className="pdb-30">
          <span className="span">6.2  除法律另有强制性规定外，双方约定如下：本公司小程序上销售方展示的酒店和价格等信息仅仅是交易信息的发布，您下单时须搜索您希望入住的酒店数量、价款及支付方式、入住人、联系方式、收货地址等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的交易诉求；销售方收到您的订单信息后，只有在销售方将您在订单中订购的酒店从仓库实际直接向您发出时（ 以酒店出库为标志），方视为您与销售方之间就实际直接向您发出的酒店建立了交易关系；如果您在一份订单里订购了多种酒店并且销售方只给您发出了部分酒店时，您与销售方之间仅就实际直接向您发出的酒店建立了交易关系；只有在销售方实际直接向您发出了订单中订购的其他酒店时，您和销售方之间就订单中该其他已实际直接向您发出的酒店才成立交易关系。您可以随时登录您在本公司小程序注册的账户，查询您的订单状态。</span>
        </div>
        <div className="pdb-30">
          <span className="span">6.3  由于市场变化及各种以合理商业努力难以控制的因素的影响，本公司小程序无法保证您提交的订单信息中希望入住的酒店都会有货；如您拟入住的酒店，发生无房，您有权取消订单。</span>
        </div>
      </div>

      <div className="item pdb-30 senven">
        <div className="title">第7条  配送</div>
        <div className="pdb-30">
          <span className="span">7.1  涉及到实体商品时，本公司将会把物品送到您所指定的收货地址。</span>
        </div>
        <div className="mb-20">
          <span className="span">7.2  因如下情况造成订单延迟或无法配送等，本公司不承担延迟配送的责任：</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（1）</span>
          <span className="span">用户提供的信息错误、地址不详细等原因导致的；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（2）</span>
          <span className="span">物品送达后无人签收，导致无法配送或延迟配送的；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（3）</span>
          <span className="span">情况变更因素导致的；</span>
        </div>
        <div className="flexRow">
          <span className="span-num">（4）</span>
          <span className="span">不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。</span>
        </div>
      </div>

      <div className="item">
        <div className="title">第8条  责任限制及不承诺担保</div>
        <div className="pdb-30">
          <span className="span">如因不可抗力或其它因素，造成本公司小程序无法控制的原因使本公司小程序销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，八大洲旅游会合理地尽力协助处理善后事宜。</span>
        </div>
      </div>

      <div className="item">
        <div className="title">第9条  协议更新及用户关注义务</div>
        <div className="pdb-30">
          <span className="span">根据国家法律法规变化及小程序运营需要，八大洲旅游有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本公司小程序上即生效，并代替原来的协议。用户可随时登录查阅最新协议； 用户有义务不时关注并阅读最新版的协议及小程序公告。如用户不同意更新后的协议，可以且应立即停止接受八大洲旅游小程序依据本协议提供的服务；如用户继续使用本小程序提供的服务的，即视为同意更新后的协议。八大洲旅游建议您在使用本公司小程序之前阅读本协议及本公司小程序的公告。 如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</span>
        </div>
      </div>

      <div className="item">
        <div className="title">第10条  法律管辖和适用</div>
        <div className="pdb-30">
          <span className="span">本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。 如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。</span>
        </div>
      </div>

      <div className="item">
        <div className="title">第11条  其他</div>
        <div className="pdb-30">
          <span className="span">11.1  八大洲旅游小程序所有者是指在政府部门依法许可或备案的八大洲旅游小程序经营主体。</span>
        </div>
        <div className="pdb-30">
          <span className="span">11.2  八大洲旅游尊重用户和消费者的合法权利，本协议及本小程序上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本公司小程序欢迎用户和社会各界提出意见和建议，八大洲旅游将虚心接受并适时修改本协议及本公司小程序上的各类规则。</span>
        </div>
        <div className="pdb-30">
          <span className="span">11.3  本协议内容中以黑体、加粗、下划线、斜体等方式显著标识的条款，请用户着重阅读。</span>
        </div>
        <div className="pdb-30">
          <span className="span">11.4  您点击本协议下方的；同意并继续；按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。</span>
        </div>
      </div>

    </div>
  );
}