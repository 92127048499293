export const GET_CITY = 'GET_CITY';
export const GET_CHECKDATE = 'GET_CHECKDATE';
export const GET_BRANDLIST = 'GET_BRANDLIST';
export const CONFIG_BRANDS = 'brandImageList';
export const CHANGE_TABS = 'CHANGE_TABS';
export const CHANGE_BRAND = 'CHANGE_BRAND';
export const CHANGE_MEMBER = 'CHANGE_MEMBER';
export const CHANGE_MERCHANTNO = 'CHANGE_MERCHANTNO';
export const CHANGE_BANNER = 'CHANGE_BANNER';

export const defaultBanner = {
  link: null, 
  imgUrl: 'https://staticfile.badazhou.com/20221102/3c26c088b170bdbada2839cd2a9bb4b7.jpeg-750xauto'
};

export const merchantList = {
  "cdFT2HRXBBT4":{
    link: '/subject/jdSilverCard',
    imgUrl: 'https://staticfile.badazhou.com/20221115/8fc6de5e772e88ea2531b5b61ea37161.jpeg-750xauto'
  },
  "NBRnWxfKK5KB":{
    link: '/subject/jdHyatt',
    imgUrl: 'https://staticfile.badazhou.com/20221102/b2b1b82a76dc8fb2ba883796a9545b28.jpeg-750xauto'
  },
  "mVwVKvpKaG7E": {
    link: '/subject/ltSilverCard',
    imgUrl: 'https://staticfile.badazhou.com/20221102/264e59c1894a873de42d9f5091b30679.jpeg-750xauto'
  },
  "B2J6BVlgWOWo": {
    link: '/subject/silverCard',
    imgUrl: 'https://staticfile.badazhou.com/20221102/42c317da87f97c625c0e6cee9b363e6d.jpeg-750xauto'
  }
}
