
export const showRoomNum = 4;
const mealTag = "含早餐";
const exclusivityTag = "专享价/会员价";

// 初始化 方案匹配房型
export function matchByRoom(rateList, roomList){
  let rooms = [];
  // 1.先根据请求的酒店房型数据去重
  if (roomList.length > 0) {
    rooms = roomList.map((roomItem) => {
      const item = {};
      item.roomDetail = roomItem;
      item.roomList = [];
      return item;
    });
  }
  const ratePlanByRoomList = sortByRoom(rateList, rooms);
  return  ratePlanByRoomList;
}

// 房型和方案匹配，并且按照价格升序方式展示
export function sortByRoom(rateList, rooms) {
  // 将价格方案和房型做匹配
  rateList.forEach((rateItem, index) => {
    const ratePlan = rateItem;
    ratePlan.tags = [];
    // 给gds 酒店 ratePlan统一增加一个说明
    // cnyTotal 向下取整
    if (!ratePlan.type) {
      ratePlan.pointInfo = '享酒店 SNP';
      ratePlan.cnyTotal = Math.floor(ratePlan.cnyTotal);
    }
    // 给方案贴上标签
    if (ratePlan.meal && ratePlan.meal.cn !== '无早') {
      ratePlan.tags.push(mealTag)
    }
    // 如果方案有说明 info 变成数组
    if(ratePlan.info) {
      if (ratePlan.info.type) {
        if(ratePlan.info.type.value === 1 || ratePlan.info.type.value === 2) {
          ratePlan.tags.push(exclusivityTag);
        }
      }
      if(ratePlan.info.contentCn){
        ratePlan.info.contentCn = ratePlan.info.contentCn.split("\n");
      }
    }
    ratePlan.hide = true;

    // 如果有roomId，根据Id做匹配去重
    if (ratePlan.roomId) {
      const roomIndex = rooms.findIndex(room => ratePlan.roomId === room.roomDetail.id);
      if (roomIndex !== -1) {
        rooms[roomIndex].roomList.push(ratePlan);
      }
    } else {
      // 如果没有sysRoomId，根据方案的room做匹配去重
      const roomIndex = rooms.findIndex(room => {
        return (room.roomDetail.room === ratePlan.room);
      });
      if (roomIndex !== -1) {
        // 如果找到相同的房型名称
        rooms[roomIndex].roomList.push(ratePlan);
      } else {
        // 如果没有找到相同房型，创建一个房型
        const newRoom = {
          roomDetail: { room: ratePlan.room },
          roomList: [],
        };
        // 如果有ratePlan.roomShort 这个属性，gds有，道旅没有，所以额外判断了
        if (ratePlan.roomShort) {
          newRoom.roomDetail.roomShort = ratePlan.roomShort;
          newRoom.roomDetail.roomExtra = ratePlan.room.replace(ratePlan.roomShort, '');
        }
        newRoom.roomList.push(ratePlan);
        rooms.push(newRoom);
      }
    }
  });

  // 计算出每个房型下的起价和是否含有专享价
  rooms.forEach((v) => {
    const room = v;
    // 获取不是隐藏的价格 的所有价格
    const ratePlanPriceList = room.roomList.map(ratePlan => ratePlan.cnyTotal);
    room.roomDetail.minTotalAmount = Math.min(...ratePlanPriceList);
    room.roomDetail.hasBenefit = !!room.roomList.find(ratePlan => (ratePlan.info && ratePlan.info?.type?.value === 1));
    // 默认房型下方案 先不展示
    room.hidePriceList = true;
  });

  // 房型价格升序排序
  rooms = rooms.sort((r1, r2) => r1.roomDetail.minTotalAmount - r2.roomDetail.minTotalAmount);

  // 房型内部价格升序排序
  rooms.forEach((v) => {
    const room = v;
    room.roomList = room.roomList.sort((r1, r2) => {
      return r1.cnyTotal - r2.cnyTotal;
    });
  });

  return rooms;
}


/**
 *
 * 收起所有房型的方案，然后在展示对应点击的房型方案
 * 如果合并在一起操作：收起和展示，会导致地位不准
 * 所以对应的策略是分开执行，先收起，然后在展示相关后定位
 *
 */

// 展示相关房型方案
export function showRatePlanByRoomList(index, ratePlanByRoomList) {
  const newData = ratePlanByRoomList;
  newData.forEach((v, i) => {
    const room = v;
    room.hidePriceList = i === index ? !room.hidePriceList : true;
  });
  return newData;
}


// 展示对应的房型详情信息
export function showRelateRoomDetail(roomIndex, ratePlanByRoomList) {
  const data = {};
  // 展示房型信息的时候，所有的价格方案展示全部收起来
  ratePlanByRoomList.forEach((v) => {
    const item = v;
    item.hidePriceList = true;
  });

  data.ratePlanByRoomList = ratePlanByRoomList;
  data.imageCurrentIndex = 0;
  data.roomIndex = roomIndex;
  data.showRoomDetail = true;

  return data;
}

