import { useEffect }from 'react';
import { useLocation } from "react-router-dom";

export const useScrollToTop = () => {
  // 获取当前页面的path
  const { pathname } = useLocation();
  useEffect(() => {
    // 监听path，若发生变化执行置顶操作
    document.getElementById('container').scrollTop = 0;
  }, [pathname]);
  return null;
};
