import React, { forwardRef, useState,useEffect, useRef, useImperativeHandle, useMemo } from "react";
import PropTypes from "prop-types";
import BScroll from "better-scroll";
import styled from 'styled-components';
import { debounce } from '../../utils/common';

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Loading = styled.div`
  text-align: center;
  padding:.6rem 0 .3rem 0;
  background: #f4f4f4;
`

const Scroll = forwardRef((props, ref) => {
  const [bScroll, setBScroll] = useState();

  const scrollContaninerRef = useRef();

  const { direction, click, refresh,  bounceTop, bounceBottom, scrollTop } = props;

  const { pullUp, pullDown, onScroll, pullUpLoading } = props;

  let pullUpDebounce = useMemo (() => {
    return debounce (pullUp, 300)
  }, [pullUp]);
    // 千万注意，这里不能省略依赖，
    // 不然拿到的始终是第一次 pullUp 函数的引用，相应的闭包作用域变量都是第一次的，产生闭包陷阱。下同。

    let pullDownDebounce = useMemo (() => {
      return debounce (pullDown, 300)
    }, [pullDown]);

    useEffect(() => {
      const scroll = new BScroll(scrollContaninerRef.current, {
        scrollX: direction === "horizental",
        scrollY: direction === "vertical",
        mouseWheel: true,
        probeType: 3,
        bounce:{
          top: bounceTop,
          bottom: bounceBottom
        },
        click: click
      });
      setBScroll(scroll);
      return () => {
        setBScroll(null);
      }
      // eslint-disable-next-line
    }, []);

  useEffect(() => {
    if(!bScroll || !onScroll) return;
    bScroll.on('scroll', (scroll) => {
      onScroll(scroll);
    });
    bScroll.scrollTo(0, scrollTop);
    return () => {
      bScroll.off('scroll');
    }
  }, [onScroll, bScroll, scrollTop]);

  // useEffect(() => {
  //   if(!bScroll || !onScroll) return;
  //   bScroll.scrollTo(0, scrollTop);
  //   onScroll();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(!bScroll || !pullUp) return;
    bScroll.on('scrollEnd', () => {
      //判断是否滑动到了底部
      if(bScroll.y <= bScroll.maxScrollY + 240){
        pullUpDebounce();
      }
    });
    return () => {
      bScroll.off('scrollEnd');
    }
  }, [pullUpDebounce, pullUp, bScroll]);

  useEffect(() => {
    if(!bScroll || !pullDown) return;
    bScroll.on('touchEnd', (pos) => {
      //判断用户的下拉动作
      if(pos.y > 50) {
        pullDownDebounce();
      }
    });
    return () => {
      bScroll.off('touchEnd');
    }
  }, [pullDownDebounce, pullDown, bScroll]);


  useEffect(() => {
    if(refresh && bScroll){
      bScroll.refresh();
    }
  });

  useImperativeHandle(ref, () => ({
    refresh() {
      if(bScroll) {
        bScroll.refresh();
        bScroll.scrollTo(0, 0);
      }
    },
    getBScroll() {
      if(bScroll) {
        return bScroll;
      }
    }
  }));

  // function pointScrollTop(element) {
  //   if(bScroll) {
  //     bScroll.scrollToElement(element, 0, 0)
  //   }
  // }

  return (
    <ScrollContainer ref={scrollContaninerRef}>
      {props.children}
      {pullUpLoading && (
        <Loading className="desc-v3-color">加载更多数据...</Loading>
      )}
    </ScrollContainer>
  );
})

Scroll.defaultProps = {
  direction: "vertical",
  click: true,
  refresh: true,
  onScroll:null,
  scrollTop: 0,
  pullUpLoading: false,
  pullDownLoading: false,
  pullUp: null,
  pullDown: null,
  bounceTop: true,
  bounceBottom: true
};

Scroll.propTypes = {
  direction: PropTypes.oneOf(['vertical', 'horizental']),
  refresh: PropTypes.bool,
  scrollTop: PropTypes.number,
  onScroll: PropTypes.func,
  pullUp: PropTypes.func,
  pullDown: PropTypes.func,
  pullUpLoading: PropTypes.bool,
  pullDownLoading: PropTypes.bool,
  bounceTop: PropTypes.bool,//是否支持向上吸顶
  bounceBottom: PropTypes.bool//是否支持向上吸顶
};

export default Scroll;