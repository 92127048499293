import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { getBrands, getMembers } from '../../../store/hotelSearch/action';
import { changeSelBrand, changeSelMember } from '../../../store/home/action';
import { gdsHotelSearch } from '../../../api';
import './style.scss';

export default (props) => {
  const dispatch = useDispatch();
  const { areaId } = useParams();
  const [keywordStatus, setKeywordStatus] = useState(false);
  const [result, setResult] = useState(null);
  // 初始化获取首页数据
  const { selBrand, selMember } = useSelector(state => state.home);
  const { brands, members } = useSelector(state => state.hotelSearch);

  useEffect(() => {
    dispatch(getBrands(areaId));
    dispatch(getMembers(areaId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 关键字搜索
  function search(e) {
    const keywords = e.target.value;
    if(keywords) {
      setKeywordStatus(true);
      let endResult = [];
      gdsHotelSearch({ areaId, keywords }).then((res) => {
        endResult = res.data;
        setResult(endResult);
      }).catch((err) => {
        setResult(endResult);
      });
    } else {
      setKeywordStatus(false);
    }
  }

  function selTag(type, item){
    if(type === 'member') {
      if(selBrand) {
        dispatch(changeSelBrand(null));
      }
      dispatch(changeSelMember(item));
    }
    if(type === 'brand') {
      if(selMember) {
        dispatch(changeSelMember(null));
      }
      dispatch(changeSelBrand(item));
    }
    props.history.goBack();
  }


  return (
    <div className="search-wrapper">
      <div className="searchBarBox">
        <Input 
          size="large" 
          placeholder="酒店名（中文/英文/拼音）" 
          prefix={<SearchOutlined />}
          onChange={search}
        />
      </div>
      {!keywordStatus ? (
        <>
        {/* 酒店会员 */}
        {(members && members.length > 0) && (
          <div className="brand-box">
            <div className="flex-row v-center h-between item-tilte">
              <div>酒店会员名</div>
              {/* <div className="show-all-list link-span">+ 展开更多</div> */}
            </div>
            <div className="brand-list flex-row">
              {members.map((item, index) => 
                <div key={index}>
                  {selMember ? (
                    <div className={`brand ${selMember.name === item.name ? 'active' : ''}`} onClick={() => selTag('member', item)}>
                      <div className="name nameCn">{item.name}</div>
                    </div>
                  ) : (
                    <div className="brand" onClick={() => selTag('member', item)}>
                      <div className="name nameCn">{item.name}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
          

          {/* 酒店品牌 */}
          {(brands && brands.length > 0) && (
            <div className="brand-box">
              <div className="flex-row v-center h-between item-tilte">
                <div>酒店品牌</div>
                {/* <div className="show-all-list link-span">+ 展开更多</div> */}
              </div>

              <div className="tip" style={{padding: '.1rem .3rem'}}>品牌是按照英文字母 a-z的顺序展示</div>

              <div className="brand-list flex-row">
                {brands.map((item, index) => 
                 <div key={index} >
                  {selBrand ? (
                    <div className={`brand ${selBrand.name === item.name ? 'active' : ''}`} onClick={() => selTag('brand', item)}>
                      <div className="name nameCn">{item.nameCn}</div>
                        {item.nameCn !== item.name && <div className="name nameEn">{item.name}</div>} 
                      </div>
                  ) : (
                    <div className="brand" onClick={() => selTag('brand', item)}>
                      <div className="name nameCn">{item.nameCn}</div>
                        {item.nameCn !== item.name && <div className="name nameEn">{item.name}</div>} 
                      </div>
                    )}
                 </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="keywordRelated flex-column">
          {result && (
            <>
              {(result.length > 0) ? (
                <div>
                  {result.map((obj, index) => 
                    <Link className="flex-row v-center result desc-color" key={index} to={`/hotel/detail/${obj.no}`}>
                      <span className="iconfont icon-hotel"></span>
                      <span>{obj.nameCn || obj.name}</span>
                    </Link>
                  )}
                </div>
              ) : (
                <div className="search-none" >
                  <img className="search-icon" src="https://staticfile.badazhou.com/20201106/4df6dc8d8ad9d481e0b4510938defdce.png" alt="" />
                  <div className="search-txt">无搜索到结果，换个词试试吧～</div>
                </div>
              ) }
            </>
          )}
        </div>
      )}
    </div>
  );
}