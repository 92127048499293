import React from "react";
import "./style.scss";


export default (props) => {
  
  return (
    <div className="container enterprise-weChat-container">
      <div className="content">
        <img className="img" src="https://staticfile.badazhou.com/20220628/e3ef4a92eb177a01af2dfb83c25fc5ef.jpeg" alt="" />
        {/* <div className="detail">
          <div>您好，来自八大洲旅游的问候！</div>
          <div>您原先的专属度假顾问：黄倩（Rose Huang)</div>
          <div>近期因个人原因已离开八大洲旅游</div>
          <div>后续您若有度假相关事宜，将由Nancy竭诚为您服务❤️</div>
        </div> */}
        <div className="info">
          <div className="text">1. 截屏二维码到相册</div>
          <div className="text">2. 打开【微信】扫一扫，选择相册图片，添加好友</div>
        </div>
      </div>
    </div>
  );
}