import React, { useEffect, useRef, lazy,  Suspense, useState } from "react";
import { message, Spin, Popconfirm, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getOrderDetail } from "../../../store/orderDetail/action";
import { ORDER_STATUS } from "../../../store/orderDetail/constants";
import { setClipboardData } from "../../../utils/common";
import { customerServiceLink } from "../../../utils/constants";
import { RoomDetail, Popup } from "../../../components";
import { cancelGdsOrder } from "../../../api";
import './style.scss';
const RateDetail = lazy(() => import('./RateDetail'));


function  OrderDetail(props) {
  const { no } = useParams();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const orderDetail = useSelector(state => state.orderDetail.detail);
  const roomRef = useRef();
  const rateRef = useRef();
  
  const isCancel = useSelector(state => state.orderDetail.isCancel);

  useEffect(() => {
    dispatch(getOrderDetail(no));
  }, [dispatch, no]);

  const showPopconfirm = () => {
    setVisible(true);
  };
  // 确认取消
  const handleOk = () => {
    setConfirmLoading(true);
    cancelGdsOrder(no).then((res) => {
      // 取消成功 刷新数据
      setVisible(false);
      dispatch(getOrderDetail(no));
    }).catch((err) => {
      // 取消失败
      setVisible(false);
      message.error(err.message, 5);
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };


  return (
    <div className="order-wrapper">
      {orderDetail ? (
       <>
        {orderDetail.no ? (
            <div>
              <div className={`box orderStatus status${orderDetail.status.value}`}>
                <div className="flex-row v-center h-between">
                  <div className="flex-row v-center">
                    <div className="icon-box">
                      <span className={`icon-status iconfont icon-${ORDER_STATUS[orderDetail.status.value]}`}></span>
                    </div>
                    <div>
                      <span className="statusDetail">{orderDetail.status.name}</span>
                      {(orderDetail.status.code === 'CANCEL') && (
                        <div className="msg">取消时间：{orderDetail.cancelTime}</div>
                      )}
                      {orderDetail.status.code === 'FAILURE' && (
                        <div className="msg">原因：{orderDetail.errorMessage}</div>
                      )}
                    </div>
                  </div>

                 {isCancel ? (
                  <div>
                    <button className="mini-btn btn-disabled">正在取消</button>
                  </div>
                 ) :(
                  <div>
                    {!orderDetail.isOverCheckout && orderDetail.status.value === 1 && (
                      // <button className="mini-btn btn-default" onClick={() => }>取消订单</button>
                      <Popconfirm
                        title="请再次确认取消政策，若超过取消期限的取消，将会产生费用并且影响到账户的使用，若不清楚取消规则，请联系在线客服确认。"
                        visible={visible}
                        cancelText="不用了"
                        okText="确认取消"
                        onConfirm={handleOk}
                        okButtonProps={{ loading: confirmLoading }}
                        onCancel={handleCancel}
                      >
                        <Button onClick={showPopconfirm}>
                          取消订单
                        </Button>
                      </Popconfirm>
                    )}
                  </div>
                 )}
               </div>
             </div>
         
              <div className="orderTip tip-primary">
                <div className="orderTip-info flex-row v-center">
                  若您对订单有任何疑问，请点击<a href={customerServiceLink} className="bold">在线客服</a>进行咨询。
                </div>
                {orderDetail.status.code === 'SUCCESS' && (
                  <div className="orderTip-more">
                    如遇不可抗力或其他原因，不能按时入住酒店，请及时联系我们客服，我们客服将协助你与酒店进行沟通。若末联系我方客服，造成未入住损失，我方将关闭你的帐户。
                  </div>
                )}
              </div>

              {orderDetail.cancellationRule && (
                <div className="itemBox cancelBox">
                  <div className="itemBoxContent">
                    <div className="flex-row v-center">
                        <span className="iconfont icon-xinxi"></span>
                        <span className="stress">取消政策</span>
                    </div>

                    {orderDetail.cancellationRule.cn ? (
                      <div className={orderDetail.cancellationRule.cn === '不可取消' ? 'danger-color item cancelInfoShort' : 'success-color item cancelInfoShort'}>
                        {orderDetail.cancellationRule.cn}
                      </div>
                      ) : (
                      <div className="item">
                        <span>{orderDetail.cancellationRule.en}</span>
                      </div>
                    )}

                    </div>
                  </div>
                )}
         
              <div className="order-detail">
               <div className="hotel-box">
                {orderDetail.confirmationNo && (
                  <div className="flex-row v-center h-between confirmationNo">
                    <div className="flex-row">
                      <span className="tag">确认号</span>
                      <span className="confirmNo success-color">{orderDetail.confirmationNo}</span>
                    </div>
                    {/* <div className="flex-row v-center info-arrow">
                      <span className="text-link">查看确认书</span>
                      <span className="arrow iconfont icon-xiangyoujiantou"></span>
                    </div> */}
                  </div>
                )}
                 
                <div className="flex-row v-center">
                  <span className="tag">订单总额</span>
                  <div className="view">
                    {orderDetail.currencyCode !== 'CNY' ? (
                      <>
                        <span className="code">{orderDetail.currencyCode} </span>
                        <span className="totalNum">{orderDetail.total}</span>
                        <span>（约￥{orderDetail.price}）</span>
                      </>
                    ) : (
                      <>
                        <span className="totalNum">￥ {orderDetail.total}</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="fapiao flex-row view">
                  <span className="tag">发票信息</span>
                  <div>入住完成后，酒店前台索取发票</div>
                </div>
              </div>
            </div>
         
              <div className="hotel-detail">
                <div className="flex-row">
                  <img className="hotel-img" src={`${orderDetail.hotel.mainImage}-300x300`} alt="" />
                  <div className="hotel-info">
                    <div onClick={() => props.history.replace(`/hotel/detail/${orderDetail.hotel.no}`)}>
                      <div className="flex-row h-between hotel-name">
                        {orderDetail.hotel.nameCn ? (
                          <div className="name">{orderDetail.hotel.nameCn}</div>
                        ) :(
                          <div className="name">{orderDetail.hotel.name}</div>
                        )}
                        <span className="arrow iconfont icon-xiangyoujiantou"></span>
                      </div>
            
                      <div className="info flex-row v-center">
                        <span className="iconfont icon-dizhi"></span>
                        {orderDetail.hotel.addressCn ? (
                          <span>{orderDetail.hotel.addressCn}</span>
                        ) :(
                          <span>{orderDetail.hotel.address}</span>
                        )}
                      </div>
                    </div>
                    
                    {orderDetail.hotel.phone && (
                      <div className="info flex-row v-center">
                        <div className="flex-row">
                          <a href={`tel:${orderDetail.hotel.phone}`}>
                            <span className="iconfont icon-tel"></span>
                            <span className="text-link">联系酒店</span>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {orderDetail.hotelRoom ? (
                  <div>
                    <div className="room flex-row h-between" >
                      <div className="room-name">{orderDetail.hotelRoom.name ? orderDetail.hotelRoom.name : orderDetail.hotelRoom.nameEn}·{orderDetail.dateTxt.days}晚</div>
                      {orderDetail.hotelRoom && (
                        <div className="flex-row v-center info-arrow" onClick={() => {
                          roomRef.current.show();
                        }}>
                          <span className="text-link">房型详情</span>
                          <span className="arrow iconfont icon-xiangyoujiantou"></span>
                        </div>
                      )}
                    </div>
                    <div className="room-attr flex-row">
                      {orderDetail.hotelRoom.size && <span className="attr">{orderDetail.hotelRoom.size}㎡</span>}
                      {orderDetail.hotelRoom.extraBed && <span className="attr">{orderDetail.hotelRoom.extraBed}</span>}
                      {orderDetail.hotelRoom.floor && <span className="attr">{orderDetail.hotelRoom.floor}</span>}
                      {orderDetail.hotelRoom.view && <span className="attr">{orderDetail.hotelRoom.view}</span>}
                    </div>
                  </div>
                ) : (
                  <div className="room flex-row h-between">
                    <div className="room-name">{orderDetail.room}·{orderDetail.dateTxt.days}晚</div>
                  </div>
                )}
                
          
                <div className="plan-title flex-row v-center h-between">
                  <div className="flex-row v-center">
                    {orderDetail.info?.type?.value === 1 && (
                      <div className="benefitsRate flex-row v-center">
                        <span className="iconfont icon-huangguan"></span>
                        <span className="zx-txt">专享价</span>
                      </div>
                    )}
                    {orderDetail.info?.type?.value === 2 && (
                      <div className="benefitsRate flex-row v-center member">
                        <span className="iconfont icon-dengji"></span>
                        <span className="zx-txt">酒店会员价</span>
                      </div>
                    )}
                    {orderDetail.meal?.cn &&  (
                      <span className={orderDetail.meal.cn !== '无早' ? 'success-color' : ''}>{orderDetail.meal.cn}</span>
                    )}
                    {orderDetail.bed?.cn && (<span className="bedCn">{orderDetail.bed.cn}</span>)}
                  </div>
                  <div className="flex-row v-center info-arrow" onClick={() => {
                    rateRef.current.show();
                  }}>
                    <span className="text-link">方案详情</span>
                    <span className="arrow fs-12 iconfont icon-xiangyoujiantou"></span>
                  </div>
                </div>
          
                <div className="flex-row date-info v-center">
                  <div className="flex-row v-center">
                    <div>
                      <span className="dateNum">{orderDetail.dateTxt.checkinTxt}</span>
                      <span className="dateDay">({orderDetail.dateTxt.checkinDay}入住)</span>
                    </div>
                    <div className="line"></div>
                    <div>
                      <span className="dateNum">{orderDetail.dateTxt.checkoutTxt}</span>
                      <span className="dateDay">({orderDetail.dateTxt.checkoutDay}离店)</span>
                    </div>
                  </div>
                </div>
          
                <div className="flex-row v-center">
                  <span className="tag">入住人</span>
                  {(orderDetail.person && orderDetail.person.length > 0) && (
                    <div className="personList">
                      {orderDetail.person.map((item, i) => 
                        <div key={i}>
                          {item.gender.code === 'FEMALE' && <span className="iconfont icon-woman" />}
                          {item.gender.code === 'MALE' && <span className="iconfont icon-man" />}
                          <span>{item.lastName}, {item.firstName}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="view flex-row v-center h-between">
                  <div className="flex-row v-center">
                    <span className="tag">订单编号</span>
                    <span>{orderDetail.no}</span>
                  </div>
                  <span className="text-link fs-12" onClick={() => setClipboardData(orderDetail.no)}>复制</span>
                </div>
                {orderDetail.bookingRemark && (
                  <div className="flex-row">
                    <span className="tag">订单备注</span>
                    <span>{orderDetail.bookingRemark}</span>
                  </div>
                )}
                {orderDetail.replies.length > 0 && (
                  <div className="tip-primary replies">
                    <div className="bold">官方回复：</div>
                    {orderDetail.replies.map((item, i) => 
                      <div className="reply" key={i}>
                        {item.message}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="jifen-detail">
                入住完成60天后，将获得八大洲Club8积分，微信小程序搜索“八大洲旅游”查看积分及兑换。
              </div>
              {orderDetail.hotelRoom && (
                <Suspense fallback={<div>Loading...</div>}>
                   <Popup ref={roomRef}>
                    <RoomDetail room={orderDetail.hotelRoom}  />
                  </Popup>
                </Suspense>
              )}
              <Suspense fallback={<div>Loading...</div>}>
                <Popup ref={rateRef}>
                  <RateDetail orderDetail={orderDetail}  />
                </Popup>
              </Suspense>
            </div>
          ) : (
            <div>数据加载失败</div>
          )}
       </>
      ) : (
        <div className="loading-wrapper">
          <Spin tip="详情正在加载中..." />
        </div>
      )}

    </div>
  );
}

export default React.memo (OrderDetail);