import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

export default (props) => {
  return (
    <div className="lt-silver-card-container">
      <img className="img img1" src="https://staticfile.badazhou.com/20210823/c1c2d7b4978afe5d5d22a22a67542d79.jpeg" alt="" />
      <img className="img img2" src="https://staticfile.badazhou.com/20210817/38e1088b60ddcfe803636d5118cf0580.jpeg" alt="" />
      
      <Link to={`/`}>
        <img className="img img3" src="https://staticfile.badazhou.com/20210817/32dd82037ed859f8021491e9291eed12.jpeg" alt="" />
      </Link>
      
      <img className="img img4" src="https://staticfile.badazhou.com/20210817/4e1f1f37b64762ebe65bc8d9b6bf769a.jpeg" alt="" />
      <img className="img img5" src="https://staticfile.badazhou.com/20210817/234efb2e9245b0cd80194f9d3542c039.jpeg" alt="" />
      <img className="img img6" src="https://staticfile.badazhou.com/20210817/eec59fbb0aa0fe89efd84cfe36c35f94.jpeg" alt="" />
    </div>
  );
}