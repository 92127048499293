import React, { useState, useEffect, useRef } from "react";
import { wechatGenerateScheme, officialWechatJsConfig } from "../../../api";
import "./style.scss";

export default (props) => {
  const schemeRef = useRef();
  const [isWeixin, setWeixin] = useState(false);

  const ua = navigator.userAgent;

  useEffect(() => {
    const path = '/pages/index/index';
    const randomNum = parseInt(Math.random()*100000000);
    const query = `merchantNo=Bz7wSaxa4AWE&randomNum=${randomNum}`;
    
    const result = ua.indexOf("MicroMessenger");
    if (result !== -1) {
      //在微信浏览器打开
      setWeixin(true);
      // 插入微信js
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
      document.head.appendChild(script);
      // 请求配置数据
      const url = window.location.href;
      const jsApis = 'updateAppMessageShareData'
      officialWechatJsConfig({url, jsApis}).then((res) => {
        const info = res.data;
        window.wx.config({
          debug: false,
          appId: info.appId,
          timestamp: info.timestamp,
          nonceStr: info.nonceStr,
          signature: info.signature,
          jsApiList: info.jsApiList,
          openTagList: [
            'wx-open-launch-weapp'
          ]
        });
      }).catch((err) => {});
    } else {
      // 外部浏览器打开的请求跳转吗
      wechatGenerateScheme({path, query}).then((res) => {
        schemeRef.current = res.data;
      }).catch((err) => {});
    }
  }, [ua]);

  // function goToMiniApp() {
  //   window.location.href = schemeRef.current.scheme;
  // }


  return (
    <div className="jixiang-subject-wrapper">
      {isWeixin ? '' : ''}
      <img className="img img1" src="https://staticfile.badazhou.com/20230330/c7e0a36f6f1b921825efeba916b35dae.jpeg?1016" alt="" />
      <img className="img img2" src="https://staticfile.badazhou.com/20230404/f901b04379bcaf527aecea85a2edbdc1.jpeg" alt="" />
      {/* <img className="img img2" src="https://staticfile.badazhou.com/20230330/4e93b6343493fd8e220797ff919209d5.jpeg" alt="" />
      <div className="box">
      {isWeixin ? (
            <div style={{width: '4.3rem', height: '.77rem',margin: '0 auto'}}>
              <wx-open-launch-weapp
                id="launch-btn" 
                username="gh_8b405445c453" 
                path="/pages/index/index?merchantNo=Bz7wSaxa4AWE"
                style={{ width: '4.3rem', height: '.77rem' }}
              >
                <script type="text/wxtag-template">
                  <img style={{ width: '100%', height: '100%' }} src="https://staticfile.badazhou.com/20210518/39b75a270d4e63d375109d131d1c3334.png" alt="" />
                </script>
              </wx-open-launch-weapp>
            </div>
          ) : (
            <div className="button-box" onClick={goToMiniApp}>
              <img src="https://staticfile.badazhou.com/20210518/39b75a270d4e63d375109d131d1c3334.png" alt="" />
            </div>
          )}
      </div> */}
      <img className="img img4" src="https://staticfile.badazhou.com/20230330/c630cdca76a8726b9d9a98c851b52f83.jpeg" alt="" />
    </div>
  );
}