import { HOT_CITY } from './constants';

const defaultState = {
  hotCityList: null
}

export default function areaList (state = defaultState, action) {
  switch (action.type) {
    case HOT_CITY:
      return {...state, hotCityList: action.hotCityList}
    default:
      return state;
  }
}
