import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from "./utils/renderRoutes";
import { routes } from './routes/index.js';
import store from "./store/index";

import './App.scss';

function App (props) {
  

  return (
    <Provider store={store}>
      <BrowserRouter>
        { renderRoutes (routes, {title: ''}) }
      </BrowserRouter>
    </Provider>
  )
}

export default App;
