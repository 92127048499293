import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Button } from "antd";
import { parse } from "query-string";
import { loadWeChatSDK } from '../../utils/common';
import { officialWechatJsConfig } from "../../api";

export default (props) => {
  const [wx, setWx] = useState(null);
  const { search } = useLocation();

  // 初始化设置参数
  useEffect(() => {
    const parseUrl = parse(search);
    console.log(parseUrl);

    loadWeChatSDK()
      .then((wx) => {
        console.log(wx);
        setWx(wx);
        // 在这里可以进行微信 JS-SDK 的配置
        const url = window.location.href;
        officialWechatJsConfig({url}).then((res) => {
          const info = res.data;
          wx.config({
            debug: false,
            appId: info.appId,
            timestamp: info.timestamp,
            nonceStr: info.nonceStr,
            signature: info.signature,
            jsApiList: ['miniProgram.navigateTo']
          });
        }).catch((err) => {
          console.log(err);
        });
          
        })
      .catch((error) => {
        console.error(error);
      });

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function openGuoTai() {
    if (wx) {
      console.log(wx);
      wx.miniProgram.navigateTo({
        url: '/pages/test/t2/t2'
      });
    } else {
      console.error('WeChat SDK not loaded');
    }
  }

  return (
    <div>
      <div>
        <div>注册会员1500积分</div>
        <div><Button type="primary" onClick={() => openGuoTai()}>立即注册</Button></div>
      </div>
      <div></div>
      <div></div>
    </div>
  );
}



