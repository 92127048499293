import { wechatGenerateScheme } from "../../api";
import React, { useEffect, useRef } from "react";

function SamRedirect() {
  const schemeRef = useRef();
  const path = "/pages/main/login/login";

  const merchantNo = new URLSearchParams(new URL(window.location.href).search).get('merchantNo');
  console.log(merchantNo);
  const randomNum = parseInt(Math.random()*100000000);
  const query = merchantNo ? `merchantNo=${merchantNo}&randomNum=${randomNum}` : `randomNum=${randomNum}`;

  useEffect(() => {
    // 外部浏览器打开的请求跳转吗
    wechatGenerateScheme({path, query}).then((res) => {
      schemeRef.current = res.data;

      goToMiniApp()
    }).catch((err) => {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  function goToMiniApp() {
    window.location.href = schemeRef.current.scheme;
  }

  return <></>
}

export default SamRedirect