import React from "react";
import { hotelDefaultImage } from '../../../utils/constants';


export default (props) => {
  const {
    list , 
    userInfo, 
    baseInfo, 
    showRoomList,
    showRateInfo,
    booking
   } = props;

  return (
    <div className="room-list">
      {list.map((room, index) => 
        <div className="room-box" key={index}  onClick={() => {
          showRoomList(index)
        }} >
          {room.roomList.length > 0 && (
            <Room 
              baseInfo = {baseInfo}
              userInfo = {userInfo} 
              item = {room} 
              roomIndex = {index} 
              showRateInfo = {showRateInfo}
              booking = {booking}
            />
          )}
        </div>
      )}
    </div>
  );
}

function Room({item, roomIndex, userInfo, baseInfo, showRateInfo, booking}) {
  
  return(
    <div className={item.hidePriceList ? 'room-info' : 'room-info room-info-active'}>
      <div className="flex-row v-center">
        {item.roomDetail.name ? (
          // 有翻译
          <div className="room-type flex-row v-center">
            <div className="room-img">
              {item.roomDetail.images.length > 0 ? (
                <img src={`${item.roomDetail.images[0].url}-225x225`} alt="" />
              ) : (
                <img src={hotelDefaultImage} alt="" />
              )}
            </div>
            <div className="mactch-room">
              <div className="name-cn bold">{item.roomDetail.name}</div>
              <div className="hotel-attr flex-row">
                {item.roomDetail.size && (<span>{item.roomDetail.size}㎡</span>)}
                {item.roomDetail.bed && <span>{item.roomDetail.bed}</span>}
              </div>
              {item.roomDetail.div && (
                <div className="hotel-attr">
                  <span>{item.roomDetail.div}</span>
                </div>
              )}
            </div>
          </div>
        ) : (
          // 没有翻译
          <div className="room-type flex-row v-center">
            <div className="orderNum">{roomIndex+1}</div>
            <div className="orderNumBg"></div>
            {item.roomDetail.roomShort ? (
              <div className="parseRoom bold">{item.roomDetail.roomShort}</div>
            ) : (
              <div className="parseRoom bold">{item.roomDetail.room}</div>
            )}
            {item.roomDetail.roomExtra && (
              <div className="room-extra">{item.roomDetail.roomExtra}</div>
            )}
          </div>
        )} 
        <div className="flex-column price-wrapper">
          {item.hideStatus ? (
            <div className="flex-row base-line">
              <div className="zhuanshu-price">专享价</div>
            </div>
          ) : (
            <>
              <div className="flex-row base-line global-price">
                <span>￥</span>
                <span className="num">{item.roomDetail.minTotalAmount}</span>
                <span className="base">起</span>
                <span className={item.hidePriceList ? 'arrow iconfont icon-xiangxiajiantou' : 'arrow iconfont icon-xiangshangjiantou'}></span>
              </div>
              {item.roomDetail.hasBenefit && (
                <div className="rateCodePrice">
                  <span className="neihan">内含专享价</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="rate-list" hidden={item.hidePriceList}>
        {item.roomList.map((rate, rateIndex) => 
          <div className="rate-plan" key={rateIndex}>
            <div className="flex-row v-center h-between rate-item">
              {/* 橙卡会员可以看到专享价格图标 并指定品牌 不可以展示专享详情 */}
              <div className={(userInfo?.level?.value === 1 && rate?.info?.type?.value === 1 && baseInfo.isTemporaryBrand) ? "roomPrice disable" :  "roomPrice"} onClick={() => {
                if(!(userInfo.level.value === 1 && rate.info?.type?.value === 1 && baseInfo.isTemporaryBrand)) {
                  showRateInfo(item.roomDetail, rate);
                }
              }}>
                <div className="plan">
                  <div className="flex-row v-center">
                    {rate.meal?.cn && (<div className={rate.meal?.cn !== '无早' ? 'bold success-color' : 'bold roomAttrItem'}>{rate.meal.cn}</div>)}
                    {rate.bed?.cn && (<span className="bedCn bold">{rate.bed?.cn}</span>)}
                    <span className="arrow iconfont icon-xiangyoujiantou"></span>
                  </div>
                  <div className={rate.cancelInfoShort?.cn === '不可取消' ? 'cancelInfoShortTran danger-color' : 'cancelInfoShortTran success-color'}>{rate.cancelInfoShort?.cn}</div>
                  {rate.tips && (<div className="fs-11 c-warn">{rate.tips}</div>)}
                  <div className="planTxt">{rate.rate}</div>
                </div>

                {rate.info?.type?.value === 2 ? (
                  /* 酒店会员价 */
                  <div className="planPrice global-price">
                    <div className="price">
                      <span>￥</span>
                      <span className="num">{rate.cnyTotal}</span>
                    </div>
                    <div>
                      <span className="hotel-member-rate">酒店会员价</span>
                    </div>
                  </div>
                ) : (
                  <>
                     {rate.info?.type?.value === 1 ? (
                        /* 专享价 */
                        <div className="planPrice global-price">
                          <div className="price">
                            <span>￥</span>
                            <span className="num">{rate.cnyTotal}</span>
                          </div>
                          <div className="benefitsRate flex-row v-center" >
                            <span className="iconfont icon-huangguan"></span>
                            <span className="zx-txt">专享价</span>
                          </div>
                        </div>
                     ) : (
                      <div className="planPrice global-price">
                        <div className="price">
                          <span>￥</span>
                          <span className="num">{rate.cnyTotal}</span>
                        </div>
                      </div>
                     )}
                  </>
                )}
              </div>

              <div className="booking-box">
                {userInfo.level.value === 1 && rate.info?.type?.value === 1 ? (
                  <div className="disable-booking">
                    <div className="highlight">银/金专享</div>
                    <div className="kefu-txt">找客服预订</div>
                  </div>
                ) : (
                  <div onClick={() => booking(1, {roomDetail: item.roomDetail, rate })}>
                    {rate.guaranteeType?.cn === '在线付' ? (
                      <div className="bookingBtn-wrap">
                        {rate.inventory <= 4 && (<div className="kucun danger-color">仅剩{rate.inventory}间</div>)}
                        <div className="bookingBtn online-pay flex-column">
                          <span className="bookingTxt">在线付</span>
                          <span className="guaranteeTypeTxt">预订</span>
                        </div>
                      </div>
                    ) : (
                      <div className="bookingBtn flex-column">
                        <span className="bookingTxt">信用卡</span>
                        <span className={rate.guaranteeType.cn === '担保' ? 'guaranteeTypeTxt success-color' : 'guaranteeTypeTxt warn-color'}>{rate.guaranteeType.cn}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div> 
    </div>       
  )
}