import * as actionType from './constants';
import { orderDetail } from '../../api';
import { getCheckDateTxt } from '../../utils/hotel';


export const changeOrderDetail = (data) => (
  {
    type: actionType.CHANGE_ORDER_DETAIL, 
    value: data
  }
);

export const changeCancelStatus = (data) => (
  {
    type: actionType.CHANGE_CANCEL_STATUS, 
    value: data
  }
);

export function getOrderDetail(no) {
  return async (dispatch) => {
    try {
      const { data } = await orderDetail(no);
      // 处理日期格式化
      data.dateTxt = getCheckDateTxt({checkin: data.checkinDate, checkout: data.checkoutDate});
      if(data.info && data.info.contentCn) {
        data.info.contentCn = data.info.contentCn.split('\n');
      }
      dispatch(changeOrderDetail(data));
    } catch (error) {
      dispatch(changeOrderDetail({}));
    }
  }
}