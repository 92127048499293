import React,{ useState, useEffect } from 'react';
import { Input, Row, Col } from 'antd';
import { Link } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getHotCity } from '../../../store/areaList/action';
import { getCity } from '../../../store/home/action';
import { setCityStorage } from '../../../utils/hotel';
import { gdsHotelSearch } from '../../../api';
import './style.scss';

function AraeList() {
  let areaList;
  const history = useHistory();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [keywordStatus, setKeywordStatus] = useState(false);
  const [result, setResult] = useState(null);


  let hotCityListData = useSelector(state => state.areaList.hotCityList);
  if (hotCityListData) {
    areaList = JSON.parse(hotCityListData.data);
  }


  useEffect(() => {
    // 如果store 没有热门城市数据 就请求数据
    if(!hotCityListData) {
      dispatch(getHotCity());
    }
  }, [dispatch, hotCityListData]);
  // tab标签切换
  function tabsChange(tab, index) {
    setCurrent(index)
  }
  // 选择城市
  function selCity(city) {
    dispatch(getCity(city));
    setCityStorage(city);
    history.goBack();
  }

  // 关键字搜索
  function search(e) {
    const keywords = e.target.value;
    if(keywords) {
      setKeywordStatus(true);
      let endResult = [];
      gdsHotelSearch({ keywords }).then((res) => {
        endResult = res.data;
        setResult(endResult);
      }).catch((err) => {
        setResult(endResult);
      });
    } else {
      setKeywordStatus(false);
    }
  }


  return (
    <>
      {areaList && (
        <div className='area-list-wrapper'>
          <Input 
          className="search"
          size="large" 
          placeholder="城市名/酒店名（中文/英文/拼音）" 
          onChange={search}
          prefix={<SearchOutlined />} />

          {!keywordStatus ? (
            <Tabs
              onChange={tabsChange}
              centered
              defaultActiveKey={current}
              items={areaList.map((area, areaIndex) => {
                return {
                  label: (<span>{area.typeName}</span>),
                  key: areaIndex,
                  children: (
                    <Row gutter={[8, 8]} justify="space-between">
                          {area.list.map((city) => (
                              <Col className="gutter-row" span={6} key={city.id}>
                                 <div className="city" onClick={() => selCity(city)}>{city.nameCn}</div>
                              </Col>
                          ))}
                    </Row>
                  )
                }
              })}
              >
            </Tabs>
          ) : (
            <div className="keywordRelated flex-column">
            {result && (
              <>
                {(result.length > 0) ? (
                  <div>
                    {result.map((obj, index) => 
                      <div key={index}>
                        {obj.type === 'area' && (
                          <div className="flex-row v-center result desc-color"  onClick={() => selCity(obj)}>
                            <span className="iconfont icon-dizhi"></span>
                            <span>{obj.countryCityNameCn}</span>
                          </div>
                        )}
                        {obj.type === 'hotel' && (
                          <Link className="flex-row v-center result desc-color"  to={`/hotel/detail/${obj.no}`}>
                            <span className="iconfont icon-hotel"></span>
                            <span>{obj.nameCn || obj.name}</span>
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="search-none" >
                    <img className="search-icon" src="https://staticfile.badazhou.com/20201106/4df6dc8d8ad9d481e0b4510938defdce.png" alt="" />
                    <div className="search-txt">无搜索到结果，换个词试试吧～</div>
                  </div>
                ) }
              </>
            )}
          </div>
          )}
         
          
        </div>
      )}
    </>
  )
}

export default React.memo (AraeList);