import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, message } from 'antd';
import { getUserInfo } from "../../../store/util/action";
import { customerServiceLink } from "../../../utils/constants";
import { sysAuthByPhoneIdCardName } from "../../../api";
import './style.scss';


function  Certification(props) {
  let isSubmit = false;
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.util.userInfo);
  const [submitStatus, setIsSubmitStatus] = useState(false);

  // 初始化设置数据
  useEffect(() => {
    // 如果没有方案描述 请求信息
    if(!userInfo) {
      dispatch(getUserInfo());
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function submit(formData) {
    const surNameCn = formData.surNameCn.replace(/\s+/g, '');
    const givenNameCn = formData.givenNameCn.replace(/\s+/g, '');
    const surName = formData.surName.replace(/\s+/g, '');
    const givenName = formData.givenName.replace(/\s+/g, '');
    const idCard = formData.idCard.replace(/\s+/g, '');
    //匹配中文的正则
    let cnPattern = /[\u4E00-\u9FA5\uF900-\uFA2D]/;
    //匹配英文的正则
    let enPattern = new RegExp("^[a-zA-Z]+$");

    // 判断中文姓名是否为空
    if(!surNameCn){
      message.error('请填写 中文姓');
      return false;
    }
    if(!givenNameCn){
      message.error('请填写 中文名');
      return false;
    }

    // 判断中文姓名是否合法
    if(!cnPattern.test(surNameCn)) {
      message.error('请填写 合法的中文姓');
      return false;
    }

    if(!cnPattern.test(givenNameCn)) {
      message.error('请填写 合法的中文名');
      return false;
    }

    // 判断英文姓名是否为空
    if(!surName){
      message.error('请填写 拼音姓');
      return false;
    }

    if(!givenName){
      message.error('请填写 拼音名');
      return false;
    }
    
    // 判断英文姓名是否合法
    if(!enPattern.test(surName)) {
      message.error('请填写 合法的拼音姓');
      return;
    }

    // 判断英文姓名是否合法
    if(!enPattern.test(givenName)) {
      message.error('请填写 合法的拼音名');
      return;
    }

    //判断身份证号码长度
    if(idCard.length !== 18){
      message.error('请输入18位身份证号');
      return;
    }

    const postData = {
      surName,
      givenName,
      surNameCn,
      givenNameCn,
      idCard
    };

    if(!isSubmit) {
      isSubmit = true;
      setIsSubmitStatus(isSubmit);
      // 提交数据
      sysAuthByPhoneIdCardName(postData).then((res) => {
        isSubmit = false;
        setIsSubmitStatus(isSubmit);
        // 认证成功
        message.success('认证成功');
        history.goBack();
      }).catch((error) => {
        isSubmit = false;
        setIsSubmitStatus(isSubmit);
        message.error(error?.response?.data?.message);
      });
    }
  }


  return (
    <div className="certification-wrapper">
      {userInfo && (
        <>
          {userInfo.areaCode === '86' ? (
            <div className="section">
              {userInfo.areaCode === '86' && (<div className="tip-stress">注意：每人三次认证机会，请如实填写。</div>)}
              <div className="content">
                <Form
                  onFinish={submit}
                  autoComplete="off"
                  form={form}
                  size="large"
                >
                  <div className="flex-row item">
                    <span className="label">中文姓</span>
                    <Form.Item
                      className="input"
                      name="surNameCn"
                    >
                      <Input placeholder="例 韩" />
                    </Form.Item>
                  </div>

                  <div className="flex-row item">
                    <span className="label">中文名</span>
                    <Form.Item
                      className="input"
                      name="givenNameCn"
                    >
                      <Input placeholder="例 韩" />
                    </Form.Item>
                  </div>

                  <div className="flex-row item">
                    <span className="label">拼音姓</span>
                    <Form.Item
                      className="input"
                      name="surName"
                    >
                      <Input placeholder="例 han" />
                    </Form.Item>
                  </div>

                  <div className="flex-row item">
                    <span className="label">拼音名</span>
                    <Form.Item
                      className="input"
                      name="givenName"
                    >
                      <Input placeholder="例 meimei" />
                    </Form.Item>
                  </div>

                  <div className="flex-row item">
                    <span className="label">身份证号</span>
                    <Form.Item
                      className="input"
                      name="idCard"
                    >
                      <Input placeholder="请输入身份证号" />
                    </Form.Item>
                  </div>

                  <div className="white-space"></div>

                  {!submitStatus ? (
                    <Form.Item>
                      <Button type="primary" htmlType="submit" block>
                        确认
                      </Button>
                    </Form.Item>
                  ) : (
                    <Button type="primary" disabled block>
                      提交中
                    </Button>
                  )}

                </Form>
      
                  
                <a href={customerServiceLink} className="kefu">
                  <span className="icon iconfont icon-icon052"></span>
                  <span>在线客服</span>
                </a>
    
                <div className="shuoming-wrapper">
                  <div>非大陆用户，请联系客服，发送【<span className="primary-color">认证</span>】关键字</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="section" style={{paddingTop: '100px', paddingBottom: '100px'}}>
              <div className="otherCountry-wrapper">
                <div className="otherCountry">
                  <a href={customerServiceLink} className="kefu">
                    <span className="icon iconfont icon-icon052"></span>
                    <span>在线客服</span>
                  </a>
                  <div className="other-info">检测到你使用的是非中国大陆手机号</div>
                  <div className="other-info">请联系上方在线客服，发送【<span className="primary-color">认证</span>】关键字</div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default React.memo (Certification);