import { 
  GET_ORDER_LIST, 
  GET_META,  
  CHANGE_PULLUP_LOADING,
  CHANGE_ALL_LOADING,
  CHANGE_PAGE_COUNT
} from './constants';

const defaultState = {
  orders: null, 
  meta: null, 
  pullLoading: false,
  allLoaded: false,
  page: 1
}

export default function hotelList(state = defaultState, action) {
  switch (action.type) {
    case CHANGE_ALL_LOADING:
      return {
        ...state,
        allLoaded: action.data
      };
    case CHANGE_PULLUP_LOADING:
      return {
        ...state,
        pullupLoading: action.data
      }
    case CHANGE_PAGE_COUNT:
      return {
        ...state,
        page: action.data
      };
    case GET_ORDER_LIST:
      return {
        ...state,
        orders: [...action.data]
      };
    case GET_META:
      return {
        ...state,
        meta: action.data
      };
    default:
      return state;
  }
}
