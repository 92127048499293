import "./style.scss";

const Club8 = () => {
  return (
    <div className="club8-user-agreement-wrapper">
      <div className="p">“Club8”是八大州国际旅行社（上海）有限公司（以下统称”八大洲旅游”）的会员俱乐部，旨在为常顾客提供优质服务。</div>
      <div className="item">
        <div className="h2-uni">第一章 会员</div>
        <div className="flex-row pdb-30">
          <span >一.&nbsp;</span>
          <span>年满两周岁并持有效证件的人士，经自愿或监护人确认知情、同意、认可并遵守本章程规定，提供真实、完整的个人资料后，方可成为会员。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >二.&nbsp;</span>
          <span>本着提高会员优质服务和保护会员俱乐部的出发点，会员须实名认证后方可预订和享受会员优待。中国大陆会员手机号码须与身份证号码相匹配，中国香港、中国澳门、中国台湾提供回乡证等合法证件，外籍人士须提供护照证件。每个会员仅可注册一个会籍。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >三.&nbsp;</span>
          <span>Club8会员分橙、银、金、精英四个等级。其中橙卡为入门级别，可免费注册；银卡由支付年费或受邀成为；金卡、精英卡均需支付年费，位列重要会员级别。仅为会员提供电子会员卡。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >四.&nbsp;</span>
          <span>Club8为不同等级的会员提供不同的会员权益和优待。会员权益及优待每年固定回顾或更新一次，八大洲旅游保留依据实际情况临时调整会员礼遇和优待的权利。</span>
        </div>
        <div className="flex-row">
          <span >五.&nbsp;</span>
          <span>会员享有以下权利：</span>
        </div>
        <div className="list pdb-30">
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">自主查询与预订；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">享受Club8提供的酒店、邮轮等旅行产品礼遇和优待；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">免费或自愿有偿享受不同等级的会员礼遇和优待；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">获得积分和可积分兑换；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">参加Club8的相关会员活动；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">对Club8提出改进意见或建议；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">对Club8工作人员监督、批评、投诉等。</span>
          </div>
        </div>
        <div className="flex-row pdb-30">
          <span >六.&nbsp;</span>
          <span>会籍、账户、礼遇、优待及任何级别会员的专属产品等，会员不得以任何形式出借、转让、出售、继承或其他任何形式供他人使用。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >七.&nbsp;</span>
          <span>会员资格可随时自行注销。一旦注销，会籍内的所有信息、未享受礼遇和优待、未使用的积分等即时作废。为防止误操作，Club8仅提供人工会籍注销。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >八.&nbsp;</span>
          <span>会籍、礼遇和优待、账户等会员信息，会员不得以任何形式出借、转让、出售、继承或其他任何形式供他人使用，另有规定的情况除外。</span>
        </div>
        <div className="flex-row">
          <span >九.&nbsp;</span>
          <span>会员须妥善保管个人密码，并在安全的通讯环境下登录操作。因会员自身原因导致的密码泄露、积分被盗、违规操作被终止会籍等损失，均由会员自行承担。</span>
        </div>
      </div>
      <div className="item">
        <div className="h2-uni">第二章 会员服务</div>
        <div className="flex-row pdb-30">
          <span >一.&nbsp;</span>
          <span>Club8为不同等级的会员提供不同的会员礼遇和优待。会员优待每年固定回顾更新一次，Club8保留依据实际情况临时调整会员礼遇和优待的权利。会籍购买为自愿行为，可通过积分或付费自愿购买，完成支付后将不接受退改。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >二.&nbsp;</span>
          <span>Club8通常每年1月公布未来一年的会员礼遇和优待。会员实际享受的礼遇，以购买当年的规则为准，会籍时长为从购买当日起计算的一年。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >三.&nbsp;</span>
          <span>付费银卡会员比受邀银卡会员享有更多礼遇和优待，请参照每年的会员礼遇和优待对照表。</span>
        </div>
        {/* <!-- <div className="flex-row pdb-30">
          <span >四.&nbsp;</span>
          <navigator hover-className="none" url="/pages/club8/detail/detail">
            会员礼遇和优待对照表，<span className="c-link">参见细则>></span>
          </navigator>
        </div> --> */}
      </div>
      <div className="item">
        <div className="h2-uni">第三章 积分与兑换</div>
        <div className="flex-row pdb-30">
          <span >一.&nbsp;</span>
          <span>Club8会员积分为八大洲旅游赠予性质，并非八大洲旅游提供的旅行产品的必要组成部分。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >二.&nbsp;</span>
          <span>积分可通过注册、预订、参加活动和合作机构等途径获得。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >三.&nbsp;</span>
          <span>预订获得的基础积分依产品类型、预订时间、促销活动等其他不同条件而异，如：预订酒店、度假村套餐、度假线路等产品支付相同金额的费用，但获得的基础积分不同，须以实际预订时查询的为准。在不同时间、不同条件下预订而赠予的不同积分，会员不得要求变更或补偿。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >四.&nbsp;</span>
          <span className="span-link">橙卡积分一年有效，银卡积分两年有效，金卡积分三年有效，精英卡积分有效期待发布。若会员等级变动，以实际升级或降级后的有效期为准。</span>
        </div>
        <div className="flex-row">
          <span >五.&nbsp;</span>
          <div>
            积分兑换的渠道为“积分商城”
          </div>
        </div>
      </div>
      <div className="item">
        <div className="h2-uni">第四章 会籍终止</div>
        <div className="flex-row pdb-30">
          <span >一.&nbsp;</span>
          <span>会员主动注销会籍视为会籍终止。</span>
        </div>
        <div className="flex-row">
          <span >二.&nbsp;</span>
          <span>若出现以下情形之一，会员资格将被终止：</span>
        </div>
        <div className="list pdb-30">
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">违反中华人民共和国法律、法规，严重违背社会公德；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">严重损害其他会员权益；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">转让或出售会籍、账户、礼遇与优待和会员专属产品以获利；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">损害Club8系统、会员积分、会员信息、产品信息等安全的行为；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">有积分盗取、积分欺诈等行为；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">严重违反Club8章程、严重损害Club8利益；</span>
          </div>
          <div className="li flex-row">
            <span className="dot"></span>
            <span className="span">其它被认定为符合会籍终止的情况。</span>
          </div>
        </div>
        <div className="flex-row pdb-30">
          <span >三.&nbsp;</span>
          <span>主动会籍终止，会籍内的所有信息、未享受礼遇和优待、未使用的积分等全部即时作废。</span>
        </div>
        <div className="flex-row">
          <span >四.&nbsp;</span>
          <span className="span-link">被动会籍终止，付费会员可获得扣除已享受的直接权益的等值金额后剩余部分金额的返还。会员未享受的间接礼遇和优待、未使用的积分全部即时作废。</span>
        </div>
      </div>
      {/* <!-- 第五章 --> */}
      <div className="item">
        <div className="h2-uni">第五章 隐私保护</div>
        <div className="flex-row pdb-30">
          <span >一.&nbsp;</span>
          <span>八大洲旅游和Club8出于更好保障会员权益的唯一目的在会员注册和服务过程中接收和处理会员个人信息。我们十分尊重并以严格保护会员个人信息，并保证个人信息不被用作他途。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >二.&nbsp;</span>
          <span>会员信息经实名认证后将由技术部门加密保管，不向包括内部员工在内的任何第三方公开。</span>
        </div>
      </div>
      {/* <!-- 第六章 --> */}
      <div className="item">
        <div className="h2-uni">第六章 其他</div>
        <div className="flex-row pdb-30">
          <span >一.&nbsp;</span>
          <span>八大洲旅游和Club8有权部分或全部更改此会员章程，以及单方面终止Club8的运营。对规则条款更改和会员俱乐部的终止对会员礼遇与优待的影响，八大洲旅游不作任何承诺与保证。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >二.&nbsp;</span>
          <span>本章程受中华人民共和国法律管辖，八大洲旅游在法律范围内对本章程最终解释。</span>
        </div>
        <div className="flex-row pdb-30">
          <span >三.&nbsp;</span>
          <span>对本章程的解释、履行时产生的争议，以友好协商解决为基准；若协商不成，可通过法律渠道解决。</span>
        </div>
      </div>
    </div>
  )
}

export default Club8