import * as actionTypes from './constants';
import { config } from '../../api';

// 获取热门城市
export const getHotCity = () => {
  return async dispatch => {
    const result = await config(actionTypes.CONFIG_HOT_CITY);
    dispatch({
      type: actionTypes.HOT_CITY,
      hotCityList: result
    })
  }
}
