import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { parse } from "query-string";
import { homeTabBar } from "../../utils/constants";
import { TabBar } from '../../components';
import Hotel from '../Hotel/Home/index';
import { changeMerchantNo, changeBanner } from '../../store/home/action';
import { setStorage } from "../../utils/common";


function Index(props) {
  let { search } = useLocation();
  const dispatch = useDispatch();
  let merchantNo = '';
  let from = '';
  if(search) {
    search = parse(search);
    from = search.from ? search.from : '';
    merchantNo = search.merchantNo || '';
  }


  useEffect(() => {
    if(merchantNo) {
      dispatch(changeMerchantNo(merchantNo));
      dispatch(changeBanner(merchantNo));
      setStorage('merchantNo', merchantNo);
    }
  }, [merchantNo, dispatch]);

 
  return (
    <>
      <Hotel from={from} />
      {/* 如果是来自小程序嵌套，就不能在显示底部拦 */}
      {!from && <TabBar list={homeTabBar} />}
    </>
  );
}

export default React.memo (Index);