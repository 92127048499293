import React, { useState, useEffect, useRef } from 'react';
import { message, Button, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { phoneCode, loginByPhone } from '../../api';
import { setStorage, getStorage} from '../../utils/common';
import { changeLoginStatus } from "../../store/util/action";
import './style.scss';

const Login = (props)=> {
  let isSubmit = false;
  const history = useHistory();
  let [areaCode, setAreaCode] = useState('86');
  let [count, setCount] = useState(59);
  let [countStatus, setCountStatus] = useState(false);
  const phoneRef = useRef(null);
  const codeRef = useRef(null);
  const timerID = useRef();
  const dispatch = useDispatch();
  // 初始化手机号获取焦点
  useEffect(()=> {
    phoneRef.current.focus();
  }, []);

  useEffect(()=>{
    if(countStatus) {
      timerID.current = setInterval(() => {
        setCount(count => count - 1); 
      }, 1000);
  
      if(count === 0) {
        clearInterval(timerID.current);
        setCountStatus(false);
        setCount(59); 
      }
    }

    return () => clearInterval(timerID.current);
  }, [countStatus, count]);

  
  const onSubmit = () => {
    const phone = phoneRef.current.value.trim();
    const code = codeRef.current.value.trim();
    // 验证手机号
    if(!phone) {
      message.error('请输入手机号', 1);
      phoneRef.current.focus();
      return false;
    } else if (areaCode === '86' && phone.length !== 11) {
      message.error('手机号不合法', 1);
      phoneRef.current.focus();
      return false;
    }
     // 验证验证码
    if(!code) {
      message.error('请输入验证码', 1);
      codeRef.current.focus();
      return false;
    } else if (code.length !== 6) {
      message.error('验证码不合法', 1);
      codeRef.current.focus();
      return false;
    }

    if(!isSubmit) {
      isSubmit = true;
      const merchantNo = getStorage('merchantNo') || "";
      loginByPhone({ areaCode, phone, code, merchantNo }).then((res) => {
        isSubmit = false;
        // 登录成功
        const token = res.data;
        setStorage('token', token);
        dispatch(changeLoginStatus(true));
        // 跳转到前一页
        if(history.length > 2) {
          history.goBack();
        } else {
          history.replace('/')
        }
      }).catch((err) => {});
    }
  }
 
  // 获取验证吗
  const getCode = () => {
    const phone = phoneRef.current.value.trim();
    // 验证手机号
    if(!phone) {
      message.error('请输入手机号');
      phoneRef.current.focus();
      return false;
    } else if (areaCode === '86' && phone.length !== 11) {
      message.error('手机号不合法');
      phoneRef.current.focus();
      return false;
    }
    phoneCode({phone, areaCode});
    setCountStatus(true);
  }

  return (
    <div className='login-wrapper'>
      <img src="https://staticfile.badazhou.com/20211221/708762244a189152ce65ed4f3f19bfdd.jpeg" alt="" className="logo"/>
      <div className="form-content">
        <div className="item phone">
          <label>手机号</label>
          <Select defaultValue="86" style={{ width: 80 }} bordered={false} onChange={(code) => setAreaCode(code)}>
            <Select.Option value="86">86</Select.Option>
            <Select.Option value="852">852</Select.Option>
            <Select.Option value="853">853</Select.Option>
            <Select.Option value="886">886</Select.Option>
          </Select>
          <input name="phone" ref={phoneRef} autoComplete="off" />
        </div>
        <div className="item h-between">
          <div className="code-box flex-row v-center">
            <label>验证码</label>
            <input name="code" ref={codeRef} maxLength="6" autoComplete="off" />
          </div>
          {!countStatus ? (
            <span className="code-btn" onClick={getCode}>免费获取</span>
          ) : (
            <span className="code-btn desc-light">{count}秒后重发</span>
          )}
        </div>
      </div>
    
      <Button type="primary" block size="large" onClick={onSubmit}>确认</Button>
    </div>
    
  );
}

export default Login;