import * as actionTypes from './constants';

const defaultState = {
  detail: null,
  isCancel: false
}

export default function orderDetail(state = defaultState, action) {
  switch(action.type) {
    case actionTypes.CHANGE_ORDER_DETAIL:
      return {
        ...state,
        detail: action.value
      };
    case actionTypes.CHANGE_CANCEL_STATUS:
      return {
        ...state,
        isCancel: action.value
      };
    default:
      return state;
  }
}
