import queryString from 'query-string';
import { get } from './request';

// 获取订单列表
export function orders(query) {
  let url ='/www/order/index';
  url = queryString.stringifyUrl({url, query});
  return get(url);
}

// 获取订单详情
export function orderDetail(no) {
  return get(`www/orders/${no}/gdsHotelOrderShow`);
}

// 取消订单
export function cancelGdsOrder(no) {
  return get(`www/order/${no}/gdsHotel/cancel`);
}
