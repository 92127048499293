import * as actionTypes from './constants';

const defaultState = {
  rateDetail: null,
  rateDescription: null
}
export default function hotelBooking(state = defaultState, action) {
  switch(action.type) {
    case actionTypes.CHANGE_RATE_DETAIL:
      return {
        ...state,
        rateDetail: action.value
      };
    case actionTypes.CHANGE_RATE_DESCRIPTION:
      return {
        ...state,
        rateDescription: action.value
      };
  default:
    return state;
  }
}