/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { wechatGenerateScheme } from "../../api";
import './style.scss';

const merchantNo = '3oYkvp6t2Hmy'

function XhsRedirect (props) {
  const [isShowLogin, setIsShowLogin] = useState(false)
  const schemeRef = useRef();

  const path = "/pages/index/index";
  const randomNum = parseInt(Math.random()*100000000);
  const query = `merchantNo=${merchantNo}&randomNum=${randomNum}`;

  useEffect(() => {
    // 打开微信小程序
    wechatGenerateScheme({path, query}).then((res) => {
      schemeRef.current = res.data;
    }).catch((err) => {});
  }, [])

  const showLoginBox = () => {
    setIsShowLogin(true)
  }

  const goToWxApp = () => {
    window.location.href = schemeRef.current.scheme;
  }
  
  return (
          <>
            <div className={'xhs-container ' + isShowLogin ? 'popup' : ''}>
              <img className="img1" src="https://staticfile.badazhou.com/20230925/50ac02a3c6ac6656f4caf2f15e83e20e.jpeg" alt='' />
              <img className="img2" src="https://staticfile.badazhou.com/20230925/a36075012ab390af9cea5800906841dc.jpeg" alt='' />
              <div className="lingqu-box" >
                {
                  !isShowLogin ? <div className='get-box get-box-status-0' onClick={showLoginBox}>领取权益</div> : 
                  <div className='get-box get-box-status-1' onClick={goToWxApp}>权益领取成功，前往八大洲小程序</div>
                }
              </div>
              <img className="img4" src="https://staticfile.badazhou.com/20230925/c8ec7fb2b226de0de7ff8dea84d24aa1.jpeg" alt='' />
              <img className="img5" src="https://staticfile.badazhou.com/20230925/486a37845b8f93634382fb17baa79e7e.jpeg" alt='' />
              <img className="img6" src="https://staticfile.badazhou.com/20230925/f72b1341dd3ed779e0f9700dc6ea39be.jpeg" alt='' />
            </div>
          </>
    )
}

export default React.memo (XhsRedirect);