import * as actionTypes from './constants';
import { hotelInfo, ratePlan } from '../../api';
import { matchByRoom, sortByRoom} from '../../utils/hotelDetailRateByRoom';

// 改变页面加载状态
export const changeLoading = (data) => ({
  type: actionTypes.CHANGE_LOADING,
  value: data
});

// 改变方案加载完成的状态
export const changeLoadingRates = (data) => ({
  type: actionTypes.CHANGE_LOADING_RATES,
  value: data
});

// 改变方案请求下一页 code
export const changeCode = (data) => ({
  type: actionTypes.CHANGE_CODE,
  value: data
});

// 改变房型方案数据
export const changeRatePlanByRoomList = (data) => ({
  type: actionTypes.CHANGE_RATEPLAN_BY_ROOMS,
  value: data
});

// 获取酒店基本信息
export function getHotelBaseInfo(no) {
  return async dispatch => {
    try {
      dispatch(changeLoading(true));
      const resData = await hotelInfo(no);
      const hotelBaseInfo = resData.data;
      // 设置标题
      document.title = hotelBaseInfo.nameCn || hotelBaseInfo.name;
      // 判断该酒店 是否是需要 临时屏蔽品牌，针对普通会员 不展示专享价
      hotelBaseInfo.isChina = false;
      if(hotelBaseInfo.region && hotelBaseInfo.region.isoCode2 === 'CN'){
        hotelBaseInfo.isChina = true;
      }
      hotelBaseInfo.isTemporaryBrand = false;
      dispatch(changeLoading(false));
      dispatch({
        type: actionTypes.CHANGE_HOTEL_BASEINFO,
        value: hotelBaseInfo
      })
    } catch (error) {
      // 获取基本信息失败
      dispatch(changeLoading(false));
      dispatch({
        type: actionTypes.CHANGE_HOTEL_BASEINFO,
        value: {}
      });
      
    }
  }
}


// 第一次 或 日期发生改变 获取价格方案
export function getRatePlan(no, query, roomList) {
  return async dispatch => {
    // 请求方案 loading
    dispatch({
      type: actionTypes.CHANGE_LOADING_RATES,
      value: true
    });
    try {
      const resData = await ratePlan(no, query);
      // 价格加载匹配完成
      dispatch({
        type: actionTypes.CHANGE_LOADING_RATES,
        value: false
      });
      if(resData.data.length > 0) {
        // 初始化 匹配房型
        let ratePlanByRoomList = matchByRoom(resData.data, roomList);
        // 改变数据
        dispatch({
          type: actionTypes.CHANGE_RATEPLAN_BY_ROOMS,
          value: ratePlanByRoomList
        });
        // 如果有下一个请求 获取更多方案
        if(resData.meta.nextResultReferenceCode) {
          dispatch(changeCode(resData.meta.nextResultReferenceCode));
        }
      }
    } catch (error) {
      dispatch({
        type: actionTypes.CHANGE_LOADING_RATES,
        value: false
      });
      dispatch({
        type: actionTypes.CHANGE_RATEPLAN_BY_ROOMS,
        value: []
      });
    }
  }
}

// 获取更多价格方案
export function getMoreRatePlan(no, query, ratePlanByRoomList) {
  return async dispatch => {
    try {
      const resData = await ratePlan(no, query);
      if(resData.data.length > 0) {
        const newRatePlanByRoomList = sortByRoom(resData.data, ratePlanByRoomList);
        // 改变数据
        dispatch({
          type: actionTypes.CHANGE_RATEPLAN_BY_ROOMS,
          value: newRatePlanByRoomList
        });
        // 如果有下一个请求 获取更多方案
        if(resData.meta.nextResultReferenceCode) {
          dispatch(changeCode(resData.meta.nextResultReferenceCode));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}

