import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getHotelBaseInfo } from '../../../store/hotelDetail/action';
import './style.scss';


export default (props) => {
  const no = useParams().no;
  const dispatch = useDispatch();
  const { baseInfo } = useSelector(state => state.hotelDetail);

  useEffect(() => {
    if(!baseInfo) {
      dispatch(getHotelBaseInfo(no));
    }
    // if(!hotelDescription){
    //   dispatch(getHotelDescription(no));
    // }
  }, [no]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className="hotel-base-wrapper">
      {baseInfo && (
        <>
          <div className="module">
            <div className="name">
              {baseInfo.nameCn && (<div className="cn">{baseInfo.nameCn}</div>)}
              <div className={baseInfo.nameCn ? 'en' : 'cn'}>{baseInfo.name}</div>
            </div>
            
            <div className="content base">
              {baseInfo.checkinTime && (
                <div className="flex-row v-center view">
                  <span className="tag">时间：</span>入住 {baseInfo.checkinTime} -- 离店 {baseInfo.checkoutTime}
                </div>
              )}

              {baseInfo.phone && (
                <a href={`tel:${baseInfo.phone}`} className="flex-row v-center h-between view">
                  <div className="flex-row phoneBox">
                    <span className="tag">电话：</span>
                    <span className="phone">{baseInfo.phone}</span>
                  </div>
                  <span className="arrow iconfont icon-xiangyoujiantou"></span>
                </a>
              )}
              
              {baseInfo.address && (
                <div className="flex-row addr-box v-center view">
                  <div className="flex-row address">
                    <span className="tag">地址</span>
                    {baseInfo.addressCn ? (
                      <div className="addr-span">{baseInfo.addressCn}</div>
                    ) : (
                      <div className="addr-span">{baseInfo.address}</div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <!-- 特别备注 --> */}
          {baseInfo.specialRemark && (
            <div className="module">
              <div className="title">特别备注</div>
              <div className="content fami-content">
                <p>
                  {baseInfo.specialRemark}
                </p>
              </div>
            </div>
          )}
        

          {/* <!-- 第三人费用 --> */}
          {baseInfo.childPolicy && (
            <div className="module">
              <div className="title">第三人费用</div>
              <div className="content fami-content">
                <div className="p_content"  dangerouslySetInnerHTML={{__html:baseInfo.childPolicy}} />
              </div>
            </div>
          )}
          

          {/* <!-- 宠物政策 --> */}
          {baseInfo.petPolicy && (
            <div className="module">
              <div className="title">宠物政策</div>
              <div className="content fami-content">
                <div className="p_content"  dangerouslySetInnerHTML={{__html:baseInfo.petPolicy}} />
              </div>
            </div>
          )}
          

          {/* <!-- 联通房信息 --> */}
          {baseInfo.connectingRoomsDescription && (
            <div className="module">
              <div className="title">联通房或相邻房间</div>
              <div className="content fami-content">
                <div className="p_content"  dangerouslySetInnerHTML={{__html:baseInfo.connectingRoomsDescription}} />
              </div>
            </div>
          )}
          

          {/* <!-- 其他信息 --> */}
          {baseInfo.otherDescription && (
            <div className="module">
              <div className="title">其他信息</div>
              <div className="content fami-content">
                <div className="p_content"  dangerouslySetInnerHTML={{__html:baseInfo.otherDescription}} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}