import React from "react";


export default (props) => {
  const { order, orderDetail } = props;


  return (
    <div className={`order ${order.type.code}`} onClick={() => orderDetail(order.no, order.type.code)}>
      <div className="content">
        <div className="flex-row h-between v-center space">
          <span className="no">订单号：{order.no}</span>
          <span className={`status status-${order.info?.status?.code}`}>{order.info?.status?.name}</span>
        </div>

          <div className="title">
            {order.type.code !== 'GDS_HOTEL' && <span className="type">{order.type.name}</span>}
            <span className="name">{order.name}</span>
          </div>

          <div className="flex-row h-between v-center" style={{marginTop:'.2rem'}}>
            <div className="check-date">
              {order.info.isAppointmentRoom && (<span className="quan">房券</span>)}
              {(order.info.checkinDate && order.info.checkoutDate) && ( <span>入住：{order.info.checkinDate} 至 {order.info.checkoutDate}</span>)}
              {(order.info.checkinDate && !order.info.checkoutDate) && (<span>入住：{order.info.checkinDate}</span>)}
              {(!order.info.checkinDate && !order.info.checkoutDate) && (<span>暂未选择出行日期～</span>)}
            </div>
            <span className="arrow iconfont icon-xiangyoujiantou"></span>
          </div>

          <div className="flex-row h-between price-box">
            <div className="flex-row">
              <div className="price-default">
                <span>总价 ￥</span>
                <span className="num">{order.price}</span>
              </div>
              {order.totalDiscountFee > 0 && (
                 <div className="price-default">
                  <span>，优惠 -￥</span>
                  <span className="num">{order.totalDiscountFee}</span>
                </div>
              )}
            </div>
            {order.unpaidPrice > 0 && (
              <div className="price">
                <span>待付款 ￥</span>
                <span className="num">{order.unpaidPrice}</span>
              </div>
            )}
          </div>

        <div className="operate flex-row v-center h-between">
          <div className="booking-date">预订日期：{order.createTime}</div>
          {/* {order.canDelete && (<div className="btn">删除</div>)} */}
        </div>
      </div>
    </div>
  );
}