/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { renderRoutes } from "../../utils/renderRoutes";
import { useScrollToTop } from "../../utils/useScrollToTop";
import { allEnumValues } from "../../api";
import { getLoginStatus } from "../../store/util/action";

function Root (props) {
  const dispatch = useDispatch();
  const { route } = props;
  // 路由发生变化 滚动条置顶
  useScrollToTop();

  useEffect(() => {
    allEnumValues().then(({data}) => {
      localStorage.setItem('allEnumValues', JSON.stringify(data));
    }).catch((err) => {});
  }, []);

  // 获取登录状态
  useEffect(() => {
    dispatch(getLoginStatus());
  }, [dispatch]);

  
  return (
    <div className="container" id="container">
      {renderRoutes(route.routes, {title: ''})}
    </div>
  )
}

export default React.memo (Root);