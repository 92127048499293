import { Modal } from 'antd';
// 展示入住人说明信息
export function showPersoninfo() {
  Modal.info({
    title: '入住人信息',
    content: (
      <div>
        <p>1. 入住人姓名请与护照或身份证等证件中拼音/英文保持一致，姓名不区分大小写，酒店可能会核实入住人信息，如不一致，酒店有权拒绝入住</p>
        <p>2. 携带额外成人或儿童的费用，以酒店家庭政策或第三人费用为准</p>
      </div>
    ),
    onOk() {},
  });
}

// 展示信用卡说明信息
export function showCardInfo(guaranteeType) {
  let guaranteeTypeDesc = '';
  const guarantee = guaranteeType.cn ? guaranteeType.cn : '担保';
  switch (guarantee) {
  case '担保':
    guaranteeTypeDesc = '酒店预订时需信用卡担保此次预订，一般不会扣费，在免费取消日前取消不收取费用';
    break;
  case '押金':
    guaranteeTypeDesc = '酒店预订时一般会扣信用卡一晚的费用，如果订完后取消，酒店将不会退还此费用';
    break;
  case '预付':
    guaranteeTypeDesc = '酒店预订时需要扣取部分或全部费用，如果订完后取消，酒店将根据取消政策扣款';
    break;
  default:
    guaranteeTypeDesc = '酒店预订时需信用卡担保此次预订，一般不会扣费，在免费取消日前取消不收取费用';
    break;
  }
  Modal.info({
    title: guarantee,
    content: (
      <div>{guaranteeTypeDesc}</div>
    ),
    onOk() {},
  });
}

// 信用卡的月份选择
export const cardExpMonth = [
  {
    value: 1,
    text: '01'
  },
  {
    value: 2,
    text: '02'
  },
  {
    value: 3,
    text: '03'
  },
  {
    value: 4,
    text: '04'
  },
  {
    value: 5,
    text: '05'
  },
  {
    value: 6,
    text: '06'
  },
  {
    value: 7,
    text: '07'
  },
  {
    value: 8,
    text: '08'
  },
  {
    value: 9,
    text: '09'
  },
  {
    value: 10,
    text: '10'
  },
  {
    value: 11,
    text: '11'
  },
  {
    value: 12,
    text: '12'
  }
];

// 获取信用卡的年份
export function getYear(currentDate, num) {
  const selectCardYear = [];
  const startDate = parseInt(currentDate.slice(0,4));
  for (let date = startDate; date <= startDate + num; date++) {
    const item = {value: date, text: parseInt(`${date}`.slice(-2)) };
    selectCardYear.push(item);
  }
  return selectCardYear;
}
