

const defaultState = {
  jdHotelPreferentialStatus: false
}

export default function hotelList(state = defaultState, action) {
  switch (action.type) {
    case 'CHANGE_JD_HOTEL_PREFERENTIAL_STATUS':
      return {
        ...state,
        jdHotelPreferentialStatus: action.data
      };
    default:
      return state;
  }
}
