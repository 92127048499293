import React  from "react";
import "../JuneyaoAir/style.scss";

export default (props) => {
 


  return (
    <div className="jixiang-subject-wrapper">
      
      <img className="img img1" src="https://staticfile.badazhou.com/20230330/c7e0a36f6f1b921825efeba916b35dae.jpeg?1016" alt="" />
      <img className="img img2" style={{height: '6.15rem'}} src="https://staticfile.badazhou.com/20230614/d60db57b53ab8653375b6d48df57a217.jpeg" alt="" />
     
      <img className="img img4" src="https://staticfile.badazhou.com/20230330/c630cdca76a8726b9d9a98c851b52f83.jpeg" alt="" />
    </div>
  );
}