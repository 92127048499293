import { 
  GET_HOTELS, 
  GET_HOTEL_PRICES, 
  GET_META, 
  CHANGE_LOADING,
  CHANGE_PULLUP_LOADING,
  CHANGE_ALL_LOADING,
  CHANGE_PAGE_COUNT,
  CHANGE_FORWARD_AREA_ID,
  CHANGE_SCROLLY
} from './constants';

const defaultState = {
  hotels: null, 
  hotelPrices: null,
  meta: null, 
  pullLoading: false,
  allLoaded: false,
  page: 1,
  loading: true,
  forwardAreaId: null,
  scrollY: 0
}

export default function hotelList(state = defaultState, action) {
  switch (action.type) {
    case CHANGE_ALL_LOADING:
      return {
        ...state,
        allLoaded: action.data
      };
    case CHANGE_LOADING:
      return {
        ...state,
        loading: action.data
      };
    case CHANGE_PULLUP_LOADING:
      return {
        ...state,
        pullupLoading: action.data
      }
    case CHANGE_PAGE_COUNT:
      return {
        ...state,
        page: action.data
      };
    case GET_HOTELS:
      return {
        ...state,
        hotels: [...action.data]
      };
    case GET_META:
      return {
        ...state,
        meta: action.data
      };
    case GET_HOTEL_PRICES:
      return {
        ...state,
        hotelPrices: {...action.data}
      };
    case CHANGE_FORWARD_AREA_ID:
      return {
        ...state,
        forwardAreaId: action.data
      };
    case CHANGE_SCROLLY:
      return {
        ...state,
        scrollY: action.data
      };
    default:
      return state;
  }
}
