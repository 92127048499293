import React, { useEffect, Suspense } from "react";
import { Button, Alert } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { parse } from "query-string";
import { changeLoginStatus, getUserInfo, getLoginStatus } from "../../store/util/action";
import { tabBarMyCenter } from "../../utils/constants";
import { removeStorage } from "../../utils/common";
import { Contact, TabBar } from "../../components";
import './style.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;


function MyCenter (props) {
  const dispatch = useDispatch();
  let { search } = useLocation();
  const loginStatus = useSelector(state => state.util.loginStatus);
  const userInfo = useSelector(state => state.util.userInfo);
  let from = '';
  if(search) {
    search = parse(search);
    from = search.from ? search.from : '';
  }
  

  // 获取登录状态
  useEffect(() => {
    dispatch(getLoginStatus());
  }, [dispatch]);

  // 获取个人信息
  useEffect(() => {
    if(loginStatus) {
      if(!userInfo) {
        dispatch(getUserInfo());
      }
    }
  }, [loginStatus]); // eslint-disable-line react-hooks/exhaustive-deps


  // 退出登录 二次确认
  function confirmExit() {
    confirm({
      title: '确定退出登录？',
      icon: <ExclamationCircleOutlined />,
      okText: "确定",
      cancelText: "取消",
      okType: "default",
      onOk() {
         // 删除token
        removeStorage('token');
        dispatch(changeLoginStatus(false));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }



  return (
    <>
      <div className="my-center-wrapper">
        <Alert
          banner
          showIcon={false}
          message="首单享4倍八大洲积分，可兑话费、航空积分、房券等。微信小程序“八大洲旅游预订”查询并使用"
          closable
        />
        {loginStatus ? (
          <div className="member-wrapper">
          {userInfo && (
            <div className={`member-box  flex-row h-between level${userInfo.level.value}`}>
              {userInfo.fullNameCn ? (
                <div className="user-info flex-column">
                  <div className="flex-row">
                    <div className="name">{userInfo.fullNameCn}</div>
                    <div className="card-name">{userInfo.level.description}</div>
                  </div>
                  <div className="phone">****{userInfo.phone.substr(userInfo.phone.length - 4)}</div>
                </div>
              ) : (
                <div className="user-info flex-column">
                  <div className="flex-row">
                    <div className="name">****{userInfo.phone.substr(userInfo.phone.length - 4)}</div>
                    <div className="card-name">{userInfo.level.description}</div>
                  </div>
                  <Link to="/certification" className="certification">实名认证</Link>
                </div>
              )}
              <div className="exit" onClick={confirmExit}>退出登录</div>
            </div>
          )}
          </div>
        ) : (
          <div className="login-box">
            <Link to="/login">
              <Button type="primary" size="large">注册/登录</Button>
            </Link>
          </div>
        )}

        <div className="nav-link flex-column">
          <Link className="nav flex-row h-between" to="/order/list">
            <div className="flex-row v-center">
                <span className="iconfont icon-dingdan"></span>
                <span>我的订单</span>
              </div>
            <span className="arrow iconfont icon-xiangyoujiantou"></span>
          </Link>
          <Link className="nav flex-row h-between" to="/about">
            <div className="flex-row v-center">
                <span className="iconfont icon-xinxi"></span>
                <span>关于八大洲</span>
              </div>
            <span className="arrow iconfont icon-xiangyoujiantou"></span>
          </Link>
          {/* <a className="nav flex-row h-between" href={`tel:${enterprisePhone}`}>
            <div className="flex-row v-center">
                <span className="iconfont icon-tel"></span>
                <span>电话咨询</span>
              </div>
            <span className="arrow iconfont icon-xiangyoujiantou"></span>
          </a> */}
        </div>
        <div style={{marginTop : '15px'}}>
          <Suspense fallback={<div>Loading...</div>}>
            <Contact />
          </Suspense>
        </div>
      </div>

      {!from && <TabBar list={tabBarMyCenter} />}
      
    </>
  );
}

export default React.memo (MyCenter);