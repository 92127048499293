import React from "react";
import './style.scss';

export default (props) => {

  return (
    <div className="wrapper">
      <div className="h1">八大洲旅游有房服务保障</div>
    
      <div className="module">
        <div className="h2">到店前满房或者不能安排入住</div>
        <div className="content">
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案1</div>
              <div className="info">八大洲优先为客户安排原酒店入住，升级房间</div>
            </div>
            <div className="item flexRow border-dot">
              <div className="item-title">通知时间</div>
              <div className="info">预订日距离入住日大于等于7天，且通知时距离入住日小于等于5天时</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">八大洲承担差价，不超过原单首晚房费的100%</div>
            </div>
            <div className="item flexRow border-dot">
              <div className="item-title">通知时间</div>
              <div className="info">其他情况</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">八大洲承担差价，不超过原单首晚房费的30%</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案2</div>
              <div className="info">如原酒店不能安排，八大洲将推荐周边同等级酒店，保障客户的入住</div>
            </div>
            <div className="item flexRow border-dot">
              <div className="item-title">通知时间</div>
              <div className="info">预订日距离入住日大于等于7天，且通知时距离入住日小于等于5天时</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">八大洲承担差价，不超过原单首晚房费的100%</div>
            </div>
            <div className="item flexRow border-dot">
              <div className="item-title">通知时间</div>
              <div className="info">其他情况</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">八大洲承担差价，不超过原单首晚房费的30%</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案3</div>
              <div className="info">在双方已有沟通的情况下，客户如不接受八大洲推荐的酒店方案，可自行选择其他酒店</div>
            </div>
            <div className="item flexRow">
              <div className="item-title border-dot">通知时间</div>
              <div className="info">预订日距离入住日大于等于7天，且通知时距离入住日小于等于5天时</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">八大洲承担差价，不超过原单首晚房费的100%</div>
            </div>
            <div className="item flexRow">
              <div className="item-title border-dot">通知时间</div>
              <div className="info">其他情况</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">提供新酒店有效入住凭证（发票+水单），八大洲承担差价，不超过原单首晚房费的30%</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案4</div>
              <div className="info">推荐备选酒店降级的</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">八大洲退回原酒店与现酒店的差价；原酒店和现酒店的差价以八大洲网站为准</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案5</div>
              <div className="info">如客户都不接受以上协调方案，八大洲将为您免费取消订单，并做出如下的赔偿标准</div>
            </div>
            <div className="item flexRow border-dot">
              <div className="item-title">通知时间</div>
              <div className="info">预订日距离入住日大于等于7天，且通知时距离入住日小于等于5天时</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">提供新酒店有效入住凭证（发票+水单），八大洲承担差价，不超过原单首晚房费的100%</div>
            </div>
            <div className="item flexRow border-dot">
              <div className="item-title">通知时间</div>
              <div className="info">其他情况</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">提供新酒店有效入住凭证（发票+水单），八大洲承担差价，不超过原单首晚房费的30%</div>
            </div>
          </div>
        </div>
      </div>

      <div className="module">
        <div className="h2">到店后满房或者不能安排入住</div>
        <div className="content">
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案1</div>
              <div className="info">八大洲优先跟酒店协商，安排客户原酒店入住</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">八大洲承担差价，不超过原单首晚100%房费</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案2</div>
              <div className="info">如原酒店不能入住，八大洲将推荐周边同级别，或较高级别的酒店，保障客户的入住</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">由此产生的交通费用（需提供凭证）和推荐酒店的差价，八大洲承担差价，不超过原单首晚100%房费</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案3</div>
              <div className="info">如在同区域内，不能入住同等级或更高级别酒店，而客户同意入住较低级别酒店</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">八大洲会退回原酒店与现酒店的差价；原酒店和现酒店的差价以八大洲网站为准</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案4</div>
              <div className="info">在双方已有沟通的情况下，客户如不接受八大洲推荐的酒店方案，可自行选择其他酒店</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">提供新酒店有效入住凭证（发票+水单），八大洲承担差价，不超过原单首晚100%房费</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案5</div>
              <div className="info">如客户不接受以上协调方案</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">提供有效凭证（发票+水单），八大洲承担差价，不超过原单首晚100%房费</div>
            </div>
          </div>
        </div>
      </div>

      <div className="module">
        <div className="h2">当天房（预订日期与入住日期为同一天）</div>
        <div className="content">
          <div className="detail">当天房预订规则：办理入住时间与预订时间，不能少于2小时；酒店满房</div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案1</div>
              <div className="info">八大洲优先跟酒店协商，安排客户原酒店入住</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">八大洲承担差价，不超过原单首晚房费的30%</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案2</div>
              <div className="info">如原酒店不能入住，八大洲将推荐周边同级别，或较高级别的酒店，保障客户的入住</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">由此产生的交通费用（需提供凭证）和推荐酒店的差价，由八大洲承担，全部赔付费用不超过原单首晚房费的30%</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案3</div>
              <div className="info">如在同区域内，不能入住同等级或更高级别酒店，而客户同意入住较低级别酒店</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">差额房费，八大洲会退还至客户账号</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案4</div>
              <div className="info">在双方已有沟通的情况下，客户如不接受八大洲推荐的酒店方案，可自行选择其他酒店</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">提供新酒店有效入住凭证（发票+水单），八大洲承担差价，不超过原单首晚房费的30%</div>
            </div>
          </div>
          <div className="plan">
            <div className="plan-title-item flexRow">
              <div className="plan-title">方案5</div>
              <div className="info">如客户不接受以上协调方案</div>
            </div>
            <div className="item flexRow">
              <div className="item-title">赔偿标准</div>
              <div className="info">提供有效凭证（发票+水单），八大洲承担差价，不超过原单首晚30%房费</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="module">
        <div className="h2">注意事项</div>
        <div className="content">
          <div className="list">
            <div className="li">1. 八大洲所有赔偿只针对实际经济损失，且赔付金额不超过“有房保障”的赔偿标准</div>
            <div className="li">2. 赔付统一在离店后受理；所有凭证需在客人离店后1个月内提交，到期仍无提交则视为放弃保障权利</div>
            <div className="li">3. 以上所有赔付和协调方案，都是双方有事先沟通的情况下生效</div>
            <div className="li">4. 处理赔偿事务中，请保留相关凭证，八大洲需凭证，才能赔付。尤其是改订的账单+支付凭证</div>
            <div className="li">5. 协议中所述，“到店前”是指客户在订单入住日所预订酒店开始办理入住时间之前；“到店后”是指客户在订单入住日所预订酒店办理入住时间段之内。酒店办理入住时间以八大洲平台显示为准；“距离入住日大于等于7天”、“距离入住日小于等于5天”均以自然日间间隔计算，不以24小时一天为计算标准</div>
            <div className="li">6. 协议中所述，“差价”是实际入住的订单价格与原订单价格对比所产生的差额</div>
            <div className="li">7. 当天房的预订日期与入住日期，按当地时间为准</div>
            <div className="attention-content">
              <div className="bold danger-color">不予赔付的几种情况：</div>
              <div className="attention flexRow">
                <text className="iconfont icon-lingxing"></text>
                <text className="info">客户遇到订单问题未第一时间与我司联系，自行安排行程的，视为您自动放弃了“有房保障”赔付的权利，将不予赔付，且客户所产生的一切费用概不负责</text>
              </div>
              <div className="attention flexRow">
                <text className="iconfont icon-lingxing"></text>
                <text className="info">八大洲向客户提供不低于原房型、原价格的、同间夜数的酒店房间（同一酒店或者附近酒店），且双方协商一致，客户办理入住的</text>
              </div>
              <div className="attention flexRow">
                <text className="iconfont icon-lingxing"></text>
                <text className="info">包括但不限于恶意购买行为的订单</text>
              </div>
              <div className="attention flexRow">
                <text className="iconfont icon-lingxing"></text>
                <text className="info">客户没有提供真实有效入住凭证(发票+水单)，且与原订单入住城市、入住人、入住日期等不相同的</text>
              </div>
              <div className="attention flexRow">
                <text className="iconfont icon-lingxing"></text>
                <text className="info">非八大洲原因造成的订单无法入住，如酒店设置故障，遭遇不可抗力（如自然灾害、突发事件、政府征用等）等</text>
              </div>
              <div className="attention flexRow">
                <text className="iconfont icon-lingxing"></text>
                <text className="info">不遵守八大洲系统酒店分销政策（如禁止在携程、去哪儿、去啊、艺龙、美团等OTA平台售卖），八大洲有权利直接取消订单，扣除全部房款，不予赔付并保留按照酒店规定采取进一步措施的可能</text>
              </div>
              <div className="attention flexRow">
                <text className="iconfont icon-lingxing"></text>
                <text className="info">八大洲已经核实并知会客户入住人为重复预订</text>
              </div>
              <div className="attention flexRow">
                <text className="iconfont icon-lingxing"></text>
                <text className="info">请于页面展示酒店最晚入住时间前办理入住，无规定最晚入住时间的，请于首晚22点前办理入住。如无法按规定时间入住，请提前告知，否则酒店有可能按照no show取消订单，八大洲将不作退款及赔偿</text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}