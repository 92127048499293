import React, { useState, useEffect, useRef } from "react";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import { wechatGenerateScheme, officialWechatJsConfig } from "../../api";
import "./style.scss";

export default (props) => {
  const [params, setParams] = useState(null);
  const [isWeixin, setWeixin] = useState(false);
  const schemeRef = useRef();
  const { search } = useLocation();
  const ua = navigator.userAgent;
 
  // 初始化设置参数
  useEffect(() => {
    const parseUrl = parse(search);
    const path = parseUrl.path ? decodeURIComponent(parseUrl.path) : "/pages/index/index";
    let query = parseUrl.query ? decodeURIComponent(parseUrl.query) : "";
    const img = parseUrl.img ? decodeURIComponent(parseUrl.img) : "https://staticfile.badazhou.com/20211202/026c663a20af1f54e410defbfb092ce8.jpeg";
    const url = query ? `${path}?${query}` : path;
    const params = {path, query, img, url};

    const result = ua.indexOf("MicroMessenger");
    if (result !== -1) {
      //在微信浏览器打开
      setWeixin(true);
      // 插入微信js
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
      document.head.appendChild(script);
      // 请求配置数据
      const url = window.location.href;
      const jsApis = 'updateAppMessageShareData'
      officialWechatJsConfig({url, jsApis}).then((res) => {
        const info = res.data;
        window.wx.config({
          debug: false,
          appId: info.appId,
          timestamp: info.timestamp,
          nonceStr: info.nonceStr,
          signature: info.signature,
          jsApiList: info.jsApiList,
          openTagList: [
            'wx-open-launch-weapp'
          ]
        });
      }).catch((err) => {});
    } else {
      // 外部浏览器打开的请求跳转吗
      const randomNum = parseInt(Math.random()*100000000);
      query = `${query}&randomNum=${randomNum}`;
      wechatGenerateScheme({path, query}).then((res) => {
        schemeRef.current = res.data;
      }).catch((err) => {});
    }

    setParams({...params});

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function goToMiniApp() {
    window.location.href = schemeRef.current.scheme;
  }
  
  return (
    <div className={isWeixin ? 'goto-wxapp-wrapper weixin' : 'goto-wxapp-wrapper h5'}>
      {params && (
        <>
          <div className="img-wrapper">
            <img src={params.img} alt="" className="img" />
          </div>
          
          {isWeixin ? (
            <div style={{width: '6.9rem', height: '.8rem'}}>
              {/* <div>{params.url}</div> */}
              <wx-open-launch-weapp
                id="launch-btn" 
                username="gh_8b405445c453" 
                path={params.url}
                style={{ width: '6.9rem', height: '.8rem' }}
              >
                <script type="text/wxtag-template">
                  <img style={{ width: '100%', height: '100%' }} src="https://staticfile.badazhou.com/20210416/6050db2e9cde806128355a69450e087d.jpeg" alt="" />
                </script>
              </wx-open-launch-weapp>
            </div>

          ) : (
            <div className="button-wrapper">
              <Button type="primary" size="large" block onClick={goToMiniApp}>立即前往</Button>
            </div>
          )}
        
          {/* <div className="skip-info">* 如果没有跳转成功，请打开：</div>
          <div className="skip-info">【 微信 】- 【 <span className="iconfont icon-sousuo"></span>搜索：八大洲旅游预订 】</div> */}
        </>
      )}
    </div>
  );
}
