import "./style.scss";

const PointRule = () => {
  return (
    <div class="point-rule-wrapper">
      <div class="item">
        <div class="title">如果获得积分</div>
        <div class="span">
          1. 预订实时酒店即可获得积分，每消费1元人民币 = 1积分，境外酒店以预订时显示的积分为准；
        </div>
        <div class="span">
          2. 积分到账：住宿完成后的60天内到账；
        </div>
        <div class="span">
          3. 参与八大洲其他活动可获得积分。
        </div>
      </div>
      <div class="item">
        <div class="title">积分如何兑换</div>
        <div class="span">在“我的”-“我的积分”-“积分商城”兑换相应的商品。</div>
        <div class="tip">注：商品兑换成功后，将不接受退改。</div>
      </div>
      {/* <!-- <div class="item">
        <div class="title">延住积分</div>
        <div class="span">
          仅限通过我们向酒店发起的延住订单。
        </div>
      </div> --> */}
      <div class="item">
        <div class="title">积分变更</div>
        <div class="span">
          若通过我们变更房型，积分将根据房费差价相应增减。
        </div>
      </div>
      <div class="item">
        <div class="title">积分取消</div>
        <div class="span">
          未入住或未经同意的违规取消等，积分将被扣除。
        </div>
      </div>
      <div class="item">
        <div class="title">积分有效期</div>
        <div class="span">
          到账后2年内有效。
        </div>
      </div>
    </div>
  )
}

export default PointRule