import React, { useEffect, useRef, useState } from 'react';
import { wechatGenerateScheme } from "../../api";
import './style.scss';

const merchantNo = 'vUNad3fTJNkp'

const FlyertRedirect = () => {
  const [isShowLogin, setIsShowLogin] = useState(false)
  const schemeRef = useRef();

  const path = "/pages/index/index";
  const randomNum = parseInt(Math.random()*100000000);
  const query = `merchantNo=${merchantNo}&randomNum=${randomNum}`;

  useEffect(() => {
    // 打开微信小程序
    wechatGenerateScheme({path, query}).then((res) => {
      schemeRef.current = res.data;
    }).catch((err) => {});
    // eslint-disable-next-line
  }, [])

  const showLoginBox = () => {
    setIsShowLogin(true)
  }

  const goToWxApp = () => {
    window.location.href = schemeRef.current.scheme;
  }

  return <>
      <div className={'xhs-container ' + isShowLogin ? 'popup' : ''}>
          <img className="img1" src="https://staticfile.badazhou.com/20240628/fd83a19b522f666b8cb98b8e1bab0ac9.jpeg" alt='' />
          <div className="lingqu-box" >
            {
              !isShowLogin ? <div className='get-box get-box-status-0' onClick={showLoginBox}>领取权益</div> : 
                <div className='get-box get-box-status-1' onClick={goToWxApp}>权益领取成功，前往八大洲小程序</div>
            }
          </div>
          <img className="img4" src="https://staticfile.badazhou.com/20240628/d913dac0b2096dcbdbdc5be45109df2e.jpeg" alt='' />
          <img className="img5" src="https://staticfile.badazhou.com/20240628/142536a0eee443b9908d1cf1f854ff89.jpeg" alt='' />
          <img className="img6" src="https://staticfile.badazhou.com/20240628/6cae5e23ffcd145bfd1167092d1d0f7f.jpeg" alt='' />
        </div>
    </>
}

export default FlyertRedirect