export const CHANGE_ORDER_DETAIL = 'CHANGE_ORDER_DETAIL';
export const CHANGE_CANCEL_STATUS = 'CHANGE_CANCEL_STATUS';
export const ORDER_STATUS = {
  '1': 'dingdanchenggong', 
  '0': 'dingdandengdai',
  '2': 'dingdandengdai',
  '-1': 'dingdanshibai',
  '-2': 'dingdanquxiao',
  '-3': 'dingdanquxiao',
  '4': 'dingdanquxiao'
}
