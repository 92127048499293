import React, { useState, useEffect } from "react";
import { parse } from "query-string";
import { useDispatch } from 'react-redux'
import { useLocation } from "react-router-dom";
import { Modal,  Input, message } from 'antd';
import { changeMerchantNo, changeBanner } from '../../../store/home/action';
import { setStorage } from "../../../utils/common";
import "./style.scss";


export default (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [code, setCode] = useState('');

  let { search } = useLocation();
  const dispatch = useDispatch();
  let merchantNo = '';
  if(search) {
    search = parse(search);
    merchantNo = search.merchantNo || '';
  }

  useEffect(() => {
    if(merchantNo) {
      dispatch(changeMerchantNo(merchantNo));
      dispatch(changeBanner(merchantNo));
      setStorage('merchantNo', merchantNo);
    }
  }, [merchantNo, dispatch]);
  
  function getCode() {
    setModalStatus(true);
  }

  function handleOk() {
    if(code.length !== 8) {
      message.error('请输入正确的兑换码');
      return;
    }
    message.success('兑换成功，请查询并预订“专享价”酒店，享免费双人早餐', 3);
    props.history.replace('/')
  }

  return (
    <div className="jd-hyatt-container">
      <img className="img img1" src="https://staticfile.badazhou.com/20211221/50010b79b53410961a3e3dafd56ecfcb.jpeg" alt="" />
      <img className="img img2" onClick={getCode} src="https://staticfile.badazhou.com/20211221/6555c98cc6c13e4bc46feb5c50032030.jpeg" alt="" />
      
      <img className="img img3" src="https://staticfile.badazhou.com/20211221/39c131e47fe1cc670acc55b9f77e99c7.jpeg" alt="" />

      {modalStatus && (
        <Modal 
        title="兑换码" 
        centered="true" 
        visible={modalStatus} 
        onOk={handleOk} 
        onCancel={() => setModalStatus(false)}
        okText="立即兑换" 
        cancelText="取消">
          <Input style={{border: '1px solid #e5e5e5'}} onChange={(e) => setCode(e.target.value)} placeholder="请输入8位兑换码" />
        </Modal>
      )}
    </div>
  );
}