import { 
  GET_ORDER_LIST,  
  GET_META,
  CHANGE_PAGE_COUNT,
  CHANGE_PULLUP_LOADING,
  CHANGE_ALL_LOADING
 } from './constants';
import { orders } from '../../api';

// 分页 
export const changePageCount = (data) => ({
  type: CHANGE_PAGE_COUNT,
  data
});
// 改变全部加载完成的状态
export const changeAllLoading = (data) => ({
  type: CHANGE_ALL_LOADING,
  data
});

// 获取订单列表
export const getOrderList = (query) => {
  return async (dispatch) => {
    try {
      const listData = await orders(query);
      // 设置酒店数据
      dispatch({
        type: GET_ORDER_LIST,
        data: listData.data
      });
      // 设置meta 数据
      dispatch({
        type: GET_META,
        data: listData.meta
      });
    } catch (error) {
      // 页面加载loading 隐藏
      dispatch({
        type: GET_ORDER_LIST,
        data: []
      });
    }
  }
};

// 加载更多 订单列表
export const refreshMoreOrderList = (query, data) => {
  return async (dispatch) => {
    try {
      // 上拉loading 显示
      dispatch({
        type: CHANGE_PULLUP_LOADING,
        data: true
      });
      // 请求更多数据
      const listData = await orders(query);
      const newData = data.concat(listData.data);
     
      // 更新酒店列表数据
      dispatch({
        type: GET_ORDER_LIST,
        data: [...newData]
      });

       // 数据请求成功 上拉loading 隐藏
       dispatch({
        type: CHANGE_PULLUP_LOADING,
        data: false
      });

    } catch (error) {
      // 更新酒店列表数据
      dispatch({
        type: GET_ORDER_LIST,
        data
      });
      
      dispatch({
        type: CHANGE_PULLUP_LOADING,
        data: false
      });
    }
  }
};
