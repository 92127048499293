import * as actionTypes from './constants';

const defaultState = {
  loading: true,
  userInfo: null,
  baseInfo: null,
  hotelDescription: null,
  ratePlan: null,
  loadingRates: true,
  rateDescription: null,
  ratePlanByRoomList: [],
  resultReferenceCode: ""
}

export default function hotelDetail(state = defaultState, action) {
  switch(action.type) {
    case actionTypes.CHANGE_LOADING:
      return {
        ...state,
        loading: action.value
      };
    case actionTypes.CHANGE_USER_INFO:
      return {
        ...state,
        userInfo: action.value
      };
    case actionTypes.CHANGE_HOTEL_BASEINFO:
      return {
        ...state,
        baseInfo: action.value
      };
    case actionTypes.CHANGE_LOADING_RATES:
      return {
        ...state,
        loadingRates: action.value
      };
    case actionTypes.CHANGE_RATEPLAN_BY_ROOMS:
      return {
        ...state,
        ratePlanByRoomList: action.value
      };
    case actionTypes.CHANGE_CODE:
      return {
        ...state,
        resultReferenceCode: action.value
      };
    case actionTypes.CHANGE_RATE_DESCRIPTION:
      return {
        ...state,
        rateDescription: action.value
      };
    case actionTypes.CHANGE_HOTEL_DESCRIPTION:
      return {
        ...state,
        hotelDescription: action.value
      };
    default:
      return state;
  }
}
