import React from "react";
import './style.scss';

export default (props) => {

  // 用于微信小程序：酒店优势
  // 小程序通过webview展示酒店优势
  
  return (
    <div className="wrapper advantage-wrapper">
      <img src="https://staticfile.badazhou.com/20201106/8b461bc4ae4f73442de363a80eb5deed.jpg" className="mt-30 advantage1 mb-30" alt="" />
      <img src="https://staticfile.badazhou.com/20201106/46a0056e4e263b785b5cf3447afac176.jpg" className="mt-30 advantage1 mb-30" alt="" />
      <img src="https://staticfile.badazhou.com/20201106/1594cc39ab20daceee6f8b173ae58f61.jpg" className="mt-30 advantage3" alt="" />
    </div>
  );
}