import * as actionTypes from './constants';
import {gdsHotelBrands, gdsHotelMembers} from '../../api';

export const getBrands = (areaId) => {
  return async dispatch => {
    const result = await gdsHotelBrands(areaId);
    dispatch({
      type: actionTypes.CHANGE_BRANDS,
      value: result.data
    })
  }
}

export const getMembers = (areaId) => {
  return async dispatch => {
    const result = await gdsHotelMembers(areaId);
    dispatch({
      type: actionTypes.CHANGE_MEMBERS,
      value: result.data
    })
  }
}