import dayjs from 'dayjs';
import { setStorage, getStorage } from './common';
import { CITY_KEY, CHECK_DATE_KEY } from './constants';

export function setCityStorage (value) {
  setStorage(CITY_KEY, JSON.stringify(value))
};

export function setCheckDateStorage (value) {
  setStorage(CHECK_DATE_KEY, JSON.stringify(value));
};

export function getCityStorage () {
  return JSON.parse(getStorage(CITY_KEY));
};

export function getCheckDateStorage () {
  return JSON.parse(getStorage(CHECK_DATE_KEY));
};

/**
 * 获取处理后的checkin和checkout和days
 */
export function getCheckDateTxt(checkDate) {
  const checkinTxt = `${checkDate.checkin.substr(5).replace('-', '月')}日`;
  const checkoutTxt = `${checkDate.checkout.substr(5).replace('-', '月')}日`;
  const week = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];

  const days = dayjs(checkDate.checkout).diff(dayjs(checkDate.checkin), 'day');
  const checkinDay = dayjs(checkDate.checkin).day();
  const checkoutDay = dayjs(checkDate.checkout).day();
  const date = {
    checkinTxt,
    checkoutTxt,
    checkinDay: week[checkinDay],
    checkoutDay: week[checkoutDay],
    days,
  };
  return date;
}

