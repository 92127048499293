import React, { useEffect, useRef, useState, Suspense } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Divider } from 'antd';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { URL, loadingImage, hotelDefaultImage } from '../../../utils/constants';
import { getCheckDate, changeSelBrand, changeSelMember } from '../../../store/home/action';
import { 
  getHotelList, 
  getHotelPrices,
  refreshMoreHotelList, 
  changePageCount, 
  changeAllLoading,
  changeScrollY
 } from '../../../store/hotelList/action';
import { setCheckDateStorage } from '../../../utils/hotel';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { Calendar, Scroll } from '../../../components';
import { PERPAGE } from '../../../store/hotelList/constants';
import './style.scss';

let total = 1;

function HotelList(props) {
  const dispatch = useDispatch();
  const { selBrand, selMember, city, checkDate} = useSelector(state => state.home);
  const checkinDate = checkDate.checkin;
  const checkoutDate = checkDate.checkout;
  const areaId = city.id;
  let { loading, hotels, hotelPrices, meta, pullupLoading, allLoaded, page, scrollY } = useSelector(state => state.hotelList);
 
  const calendarRef = useRef();
  const scrollRef = useRef();
  //日历组件状态
  const [calendarState, setCalendarState] = useState(false);

  // 初始化 请求数据
  useEffect(()=> {
    if(scrollY !== 0){
      dispatch(changeScrollY(0));
    }
    if(page !== 1) {
      dispatch(changePageCount(1));
    }
    if(allLoaded) {
      dispatch(changeAllLoading(false));
    }
    dispatch(getHotelList({ areaId, checkinDate, checkoutDate, selBrand, selMember }));
  }, [areaId, selBrand, selMember]); // eslint-disable-line react-hooks/exhaustive-deps


  // scroll 上拉加载更多酒店数据
  function handlePullUp() {
    if(meta) {
      total =  Math.ceil(meta.total / PERPAGE);
      if (!pullupLoading && page < total) {
        dispatch(refreshMoreHotelList({ 
          areaId, 
          page: page +1,
          checkinDate, 
          checkoutDate
        }, hotels, hotelPrices));
        dispatch(changePageCount(page +1));
      }
      if (page === total) {
        dispatch(changeAllLoading(true));
      }
    }
  }

  // 获取选择的日期
  function getChangeDate(date) {
    calendarRef.current.hide();
    dispatch(getCheckDate(date));
    setCheckDateStorage(date);
    // 日期发生变化，重新请求价格
    const checkinDate = date.checkin;
    const checkoutDate = date.checkout;
    dispatch(getHotelPrices(hotels, checkinDate, checkoutDate));
  }

  // 切换城市
  function changeCity() {
    // 在切换城市前 记录下当前用户选择的城市
    props.history.push(URL.hotelAreaList);
  }

  // 进去酒店详情页
  function detail(no) {
    // 在切换城市前 记录下当前用户选择的城市
    // dispatch(changeforwardAreaId(areaId));
    // 记录下当前点击的酒店 方便返回时在指定酒店位置
    // const scroll = scrollRef.current.getBScroll();
    // dispatch(changeScrollY(scroll.y));
    // 跳转到酒店详情
    props.history.push(`${URL.hotelDetail}/${no}`);
  }

  return (
    <div className="hotel-list-wrapper">
      <div className="search-module">
        <div className="search-box">
          <div className="area tag" onClick={changeCity} >
            {city.nameCn}
          </div>
          <div className="date-box tag" onClick={() => {
            setCalendarState(true);
            calendarRef.current.show();
          }}>
            <div className="date-txt">
              <div className="date">
                <span className="desc-light">住</span>
                <span className="primary-color">{checkDate.checkinTxt}</span>
              </div>
              <div className="date">
                <span className="desc-light">离</span>
                <span className="primary-color">{checkDate.checkoutTxt}</span>
              </div>
            </div>
          </div>
          <div className="keyword flex-row h-between v-center">

            <Link className="desc-v3-color" to={`/hotel/search/${city.id}`}>
              <SearchOutlined />
              {(selBrand || selMember) ? (
                <>
                  {selBrand && <span>{selBrand.nameCn}</span>}
                  {selMember && <span>{selMember.name}</span>}
                </>
              ) : <span>搜索酒店</span>}
            </Link>
            
            {(selBrand || selMember) ? (
                <CloseCircleOutlined 
                style={{color: '#b2b2b2'}} 
                onClick={() => {
                  if(selMember) {
                    dispatch(changeSelMember(null));
                  }
                  if(selBrand) {
                    dispatch(changeSelBrand(null));
                  }
                  dispatch(getHotelList({ areaId, checkinDate, checkoutDate }));
                  dispatch(changeScrollY(0));
                }}
              />
            ) : (
              <span className='arrow iconfont icon-xiangyoujiantou'></span>
            )}
          </div>
        </div>
      </div>

      <div className="loading-wrapper">
          <Spin style={{ width: "200px", transform: "translateX(-50%)" }} tip="酒店正在加载中..." spinning={loading}>
            <div className="hotel-scroll-wrapper">
              {(hotels && hotels.length > 0) ? (
                <Scroll 
                  ref={scrollRef}
                  onScroll={forceCheck}
                  pullUpLoading={pullupLoading} 
                  pullUp={handlePullUp}
                  scrollTop={scrollY}
                >
                  <div className="hotel-list" id="list">
                    {hotels.map(item => 
                      <HotelCell key={item.id} detail={detail} item={item} hotelPrices={hotelPrices} />
                    )}
                    {allLoaded && (
                      <Divider plain style={{'color': '#b2b2b2'}}>共{meta.total}家酒店，没有更多啦...</Divider> 
                    )}
                  </div>
                </Scroll>
              ) : (
                <Divider plain style={{'color': '#b2b2b2'}}>暂时没有数据,试试刷新页面～</Divider> 
              )}
            </div>
          </Spin>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Calendar calendarState={calendarState} ref={calendarRef} checkDate={checkDate} onChange={getChangeDate} />
      </Suspense>
    </div>
  );
}

function HotelCell({item, hotelPrices, detail}) {

  return (
    <div className='item' id={item.no} onClick={() => detail(item.no)}>
      <div className="img-box">
        {item.mainImage ? (
          <LazyLoad>
            <img className="main-img" alt="" src={`${item.mainImage}-300x300`} />
          </LazyLoad>
        ) : (
          <LazyLoad>
            <img className="main-img" alt="" src={`${hotelDefaultImage}-300x300`} />
          </LazyLoad>
        )}
      </div>

      <div className="content">
        <div className="name nameCn">
          {item.gdsHotel.isExistBenefits === 1 && (
            <div className="benefitsRate">
              <span className="iconfont icon-huangguan"></span>
            </div>
          )}
          {item.nameCn ? item.nameCn : item.name}
        </div>
        
        <div className="addr flex-row v-center">
          <span className="iconfont icon-dizhi"></span>
          <span className="text-ellipsis">{item.addressCn ? item.addressCn : item.address}</span>
        </div>

    
        <div className="price-box flex-row h-between" >
          <div className="flex-row v-center member">
            {item.member && (
              <>
                {item.member.image && <img className="member-image" src={item.member.image} alt="" />}
                <div className="member-name">
                  <div className="name">{item.member.name}</div>
                  <div className="bar"></div>
                </div>
              </>
            )}
          </div>
          {(hotelPrices && hotelPrices[item.no]) ? (
            <div>
              {hotelPrices[item.no] !== 'sellOut' ? (
                <div className="global-price">
                  <span>￥</span>
                  <span className="num">{hotelPrices[item.no]}</span>
                  <span className="desc-v3-color">起</span>
                </div>
              ) : (
                <div className="no-price">
                  <img src="https://staticfile.badazhou.com/20220715/aecf94dcafb47a980184915fecb22194.png" alt="" />
                </div>
              )}
            </div>
          ) : (
            <div className="flex-row">
              <img className="loading-price" src={loadingImage} alt="" />
              <span className="desc-v3-color fs-12">价格加载中</span>
            </div>
          )}
        
        </div>
      </div>
    </div>
  )
}

export default React.memo (HotelList);