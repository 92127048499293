import queryString from 'query-string';
import { get, post } from './request';
import { objValue } from '../utils/common';

// 单个配置项
export function config(name) {
  return get(`/common/config/${name}`);
}

// 获取系统时间
export function systemTime() {
  return get('/www/system/time');
}

// 登陆状态
export function loginStatus() {
  return get('/www/login/isLogin');
}

// 获取短信验证码
export function phoneCode(query) {
  let url ='/www/login/verificationCode';
  url = queryString.stringifyUrl({url, query});
  return get(url);
}

// 手机号和验证码登录
export function loginByPhone(query) {
  let url ="/www/login/loginByPhone";
  url = queryString.stringifyUrl({url, query});
  return get(url);
}

// 获取全部枚举值
export function allEnumValues() {
  return get('/common/allEnumValues');
}

// 获取用户信息
export function myInfo() {
  return new Promise(((resolve, reject) => {
    get('/www/vip/info').then(({data}) => {
      data.gender = objValue('gender', data.gender);
      data.riskLevel = objValue('vip.riskLevel', data.riskLevel);
      data.level = objValue('vip.level', data.level);
      data.type = objValue('vip.type', data.type);
      resolve({data});
    }).catch((err) => {
      reject(err);
    });
  }));
}
// 获取跳转到微信小程序 跳转码
export function wechatGenerateScheme(data) {
  return post('/www/wechatMiniProgram/generateScheme', data);
}
// 获取微信内部配置 跳转小程序
export function officialWechatJsConfig(query) {
  let url ="/www/officialWechat/jsConfig";
  url = queryString.stringifyUrl({url, query});
  return get(url);
}

// 会员认证
export function sysAuthByPhoneIdCardName(data) {
  return post('/www/sysAuthByPhoneIdCardName', data);
}
// 加入活动
export function joinActivity(activityId) {
  return get(`/www/activity/join?activityId=${activityId}`);
}
// 判断是否参于活动
export function hasJoinActivity(activityId) {
  return get(`/www/activity/hasJoin?activityId=${activityId}`);
}