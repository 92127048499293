import { 
  GET_HOTELS, 
  GET_HOTEL_PRICES, 
  GET_META, 
  CHANGE_LOADING,
  CHANGE_PAGE_COUNT,
  CHANGE_PULLUP_LOADING,
  CHANGE_ALL_LOADING,
  PERPAGE,
  CHANGE_FORWARD_AREA_ID,
  CHANGE_SCROLLY
 } from './constants';
import { hotels, gdsListPrice } from '../../api';

// 记录用户选择的目的地 用于和下一次选择 对比
export const changeforwardAreaId = (data) => ({
  type: CHANGE_FORWARD_AREA_ID,
  data
});

// 记录用户离开时的ScrollY位置 方便返回直接到指定位置
export const changeScrollY = (data) => ({
  type: CHANGE_SCROLLY,
  data
});

// 改变分页count
export const changePageCount = (data) => ({
  type: CHANGE_PAGE_COUNT,
  data
});

// 改变全部加载完成的状态
export const changeAllLoading = (data) => ({
  type: CHANGE_ALL_LOADING,
  data
});

export const resetHotelListData = () => {
  return dispatch => {
    dispatch({
      type: CHANGE_LOADING,
      data: true
    });
    dispatch({
      type: GET_HOTELS,
      data: []
    });
  }
}

// 第一次加载 获取酒店列表
export const getHotelList = (params) => {
  const { areaId, checkinDate, checkoutDate, selBrand, selMember } = params;
  return async (dispatch) => {
    try {
      // 设置酒店数据
      dispatch({
        type: CHANGE_LOADING,
        data: true
      });
      const query = {areaId, page: 1, perPage: PERPAGE};
      if(selBrand) {
        query.brandId = selBrand.id;
      }
      if(selMember) {
        query.memberId = selMember.id;
      }
      const hotelListData = await hotels(query);
      const hotelList = hotelListData.data;
      // 设置酒店数据
      dispatch({
        type: GET_HOTELS,
        data: hotelList
      });
      // 设置meta 数据
      dispatch({
        type: GET_META,
        data: hotelListData.meta
      });
      dispatch({
        type: CHANGE_LOADING,
        data: false
      });
      // 获取酒店的价格
      if(hotelList.length > 0) {
        const noList = [];
        hotelList.forEach(v => {
          noList.push(v.no);
        });
        const nos = noList.join(',');
        const resPriceData = await gdsListPrice({nos, checkinDate, checkoutDate});
        if(resPriceData.data && resPriceData.data.length > 0) {
          const hotelPrices = {};
          noList.forEach(no => {
            const result = resPriceData.data.find(v => v.no === no);
            if(result){
              hotelPrices[no] = result.minAmount
            } else {
              hotelPrices[no] = "sellOut"
            }
          });
          dispatch({
            type: GET_HOTEL_PRICES,
            data: hotelPrices
          })
        }
      }
    } catch (error) {
      dispatch({
        type: GET_HOTELS,
        data: []
      });
      dispatch({
        type: GET_HOTEL_PRICES,
        data: {}
      })
    }
  }
};

// 加载更多 获取酒店列表
export const refreshMoreHotelList = (params, hotelData, priceData) => {
  const { areaId, page, checkinDate, checkoutDate,  selBrand, selMember } = params;
  return async (dispatch) => {
    // 上拉loading 显示
    dispatch({
      type: CHANGE_PULLUP_LOADING,
      data: true
    });
    // 请求更多数据
    const query = {areaId, page, perPage: PERPAGE};
    if(selBrand) {
      query.brandId = selBrand.id;
    }
    if(selMember) {
      query.memberId = selMember.id;
    }

    const hotelListData = await hotels(query);
    const hotelList = hotelListData.data;
    
    // 更新酒店列表数据
    dispatch({
      type: GET_HOTELS,
      data: [...hotelData, ...hotelList]
    });
    

    // 获取酒店的价格
    if(hotelList.length > 0) {
      const noList = [];
      hotelList.forEach(v => {
        noList.push(v.no);
      });
      const nos = noList.join(',');
      const resPriceData = await gdsListPrice({nos, checkinDate, checkoutDate});
      
      if(resPriceData.data && resPriceData.data.length > 0) {
        const hotelPrices = {};
        noList.forEach(no => {
          const result = resPriceData.data.find(v => v.no === no);
          if(result){
            hotelPrices[no] = result.minAmount
          } else {
            hotelPrices[no] = "sellOut"
          }
        });
        // 合并价格
        dispatch({
          type: GET_HOTEL_PRICES,
          data: {...priceData, ...hotelPrices}
        });
       
      }
    }

    // 数据请求成功 上拉loading 隐藏
    dispatch({
      type: CHANGE_PULLUP_LOADING,
      data: false
    });
  }
};

// 日期发生变化 重新加载价格
export function getHotelPrices(hotels, checkinDate, checkoutDate) {
  const hotelList = hotels;
  return async (dispatch) => {
    dispatch({
      type: GET_HOTEL_PRICES,
      data: {}
    })
    if(hotelList.length > 0) {
      const noList = [];
      hotelList.forEach(v => {
        noList.push(v.no);
      });
      const nos = noList.join(',');
      const resPriceData = await gdsListPrice({nos, checkinDate, checkoutDate});
      if(resPriceData.data && resPriceData.data.length > 0) {
        const hotelPrices = {};
        noList.forEach(no => {
          const result = resPriceData.data.find(v => v.no === no);
          if(result){
            hotelPrices[no] = result.minAmount
          } else {
            hotelPrices[no] = "sellOut"
          }
        });
        dispatch({
          type: GET_HOTEL_PRICES,
          data: hotelPrices
        })
      }
    }
  }
}


