import React from "react";
import { Button } from "antd";
import './style.scss';


function About (props) {
  

  return (
    <div className="about-wrapper">
      <div className="content">

        <img className="img1" src="https://staticfile.badazhou.com/20201106/e355d5c758e0036a9839e60409732966.jpg" alt="" />
        <img className="img2" src="https://staticfile.badazhou.com/20210309/e3972d07b0a780197d912ce4108d2348.jpg" alt="" />
        <img className="img3" src="https://staticfile.badazhou.com/20201106/351992b66efca42b5e7d1036f2a19ff9.jpg" alt="" />

        <Button type="primary" block size="large" className="btn flex-row v-center" onClick={() => props.history.replace('/index', {})}>
          <span className="iconfont icon-shouye"></span>
          <span>前往首页</span>
        </Button>

      </div>
    </div>
  );
}

export default React.memo (About);