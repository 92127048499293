import React from "react";
import './style.scss';


export default (props) => {

  return (
    <div className="wrapper">
      <div className="pdb-30">【八大洲旅游】（以下简称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</div>
      <div className="pdb-30"><strong>请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私协议》。</strong></div>
      <div className="item">
        <div className="title">一、我们如何收集和使用您的个人信息</div>
        <div className="pdb-30">个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</div>
        <div className="mb-20">我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：</div>
        <div className="pdb-30">
          <span className="sub-title">1、注册成为用户</span>
        </div>
        <div className="pdb-30">完成创建账号，您需提供以下信息：您的手机号、姓名。</div>
        <div className="pdb-30">在注册过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：手机号、姓名、性别。在您注销账号时，我们将停止使用并删除上述信息。上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会单独征得您的授权同意。</div>

        <div className="pdb-30">
          <span className="sub-title">2、注册享受更多便利服务</span>
        </div>
        <div className="pdb-30">为享受更多的服务便利，您可以选择性提供以下信息：您的手机号、姓名、生日、证件号码、酒店集团会员号码。在注册过程中，如果您提供了以上额外信息，将有助于我们给您提供更好的服务和体验：您的手机号、姓名、生日、证件号码、酒店集团会员号码，在您注销账号时，我们将停止使用并删除上述信息。 上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会单独征得您的授权同意。</div>
      </div>
      <div className="item">
        <div className="title">二、关于如何共享、转让、公开披露您的个人信息</div>

        <div className="pdb-30">
          <span className="sub-title">（一）共享 </span>
        </div>
        <div className="pdb-30">我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：</div>
        <div className="mb-20 flexRow">
          <span className="span-num">（1）</span>
          <span className="span">在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（2）</span>
          <span className="span">我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</span>
        </div>

        <div className="pdb-30">
          <span className="sub-title">（二）转让 </span>
        </div>
        <div className="pdb-30">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</div>
        <div className="mb-20 flexRow">
          <span className="span-num">（1）</span>
          <span className="span">在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息； </span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（2）</span>
          <span className="span">在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span>
        </div>

        <div className="pdb-30">
          <span className="sub-title">（三）公开披露</span>
        </div>
        <div className="pdb-30">我们仅会在以下情况下，公开披露您的个人信息：</div>
        <div className="mb-20 flexRow">
          <span className="span-num">（1）</span>
          <span className="span">获得您明确同意后； </span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（2）</span>
          <span className="span">基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</span>
        </div>

      </div>
      <div className="item">
        <div className="title">三、我们如何保护您的个人信息</div>
        <div className="pdb-30">
          <span className="sub-title">（一） </span>
          <span className="span">我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受 SSL 加密保护；我们同时对我们小程序提供安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">（二） </span>
          <span className="span">我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">（三）</span>
          <span className="span">互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</span>
        </div>
        <div className="pdb-30">
          <span className="sub-title">（四）</span>
          <span className="span">在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</span>
        </div>
        <div>同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</div>
      </div>
      <div className="item">
        <div className="title">四、您的权利</div>
        <div className="pdb-30 bold">
        按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
        </div>
        <div className="mb-20">
          <strong className="span-num">（一）访问您的个人信息</strong>
          <div className="span">您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：账户信息——如果您希望知道您的账户中的个人资料信息您可以通过小程序联系在线客服操作。</div>
        </div>
        <div className="mb-20">
          <strong className="span-num">（二）更正您的个人信息</strong>
          <div className="span">当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过通过小程序联系在线客服操作。</div>
        </div>
        <div className="mb-20">
          <strong className="span-num">（三）约束信息系统自动决策</strong>
          <div className="span">在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。</div>
        </div>
        <div className="mb-20">
          <strong className="span-num">（四）响应您的上述请求</strong>
          <div className="span">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</div>
        </div>
        <div className="pdb-30">我们将在5个工作日内做出答复。如您不满意，还可以通过以下途径投诉：</div>
        <div className="pdb-30">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</div>
        <div className="pdb-30 bold">
          在以下情形中，按照法律法规要求，我们将无法响应您的请求：
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（1）</span>
          <span className="span">与国家安全、国防安全有关的；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（2）</span>
          <span className="span">与公共安全、公共卫生、重大公共利益有关的；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（3）</span>
          <span className="span">与犯罪侦查、起诉和审判等有关的；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（4）</span>
          <span className="span">有充分证据表明您存在主观恶意或滥用权利的；</span>
        </div>
        <div className="mb-20 flexRow">
          <span className="span-num">（5）</span>
          <span className="span">响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</span>
        </div>

      </div>
      <div className="item">
        <div className="title">五、本隐私权政策如何更新</div>
        <div className="pdb-30">
          <span className="span">我们可能适时会对本隐私权政策进行调整或变更，本隐私权政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您在隐私权政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的隐私权政策并受其约束。</span>
        </div>
      </div>
      <div className="item pdb-30 senven">
          <div className="title">六、如何联系我们</div>
          <div className="pdb-30 bold">
          如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：
          </div>
          <div className="mb-20 flexRow">
            <span className="span-num">电话：</span>
            <span className="span">4000882020</span>
          </div>

          <div className="pdb-30 bold">
          我们设立了个人信息保护专职部门（或个人信息保护专员），您可以通过以下方式与其联系：
          </div>
          <div className="mb-20 flexRow">
            <span className="span-num">电话：</span>
            <span className="span">021-32566966</span>
          </div>

          <div>一般情况下，我们将在5个工作日内回复。</div>
          
        </div>
    </div>
  );
}