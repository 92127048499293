import React, { useEffect, useState, useRef, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { Form, Input, Button, Radio, Row, Col, message } from 'antd';
import { getRateDescription } from "../../../store/hotelBooking/action";
import { getSystemDate } from "../../../store/util/action";
import {showPersoninfo, showCardInfo, getYear, cardExpMonth} from "./generator";
import { Contact, Popup } from "../../../components";
import { gdsHotelBooking } from "../../../api"; 
import './style.scss';

const PriceDetail = lazy(() => import('./PriceDetail'));
const SelectCardDate = lazy(() => import('./SelectCardDate'));


function HotelBooking(props) {
  let isSubmit = false;
  const dispatch = useDispatch();
  const rateDetail = useSelector(state => state.hotelBooking.rateDetail);
  const rateDescription = useSelector(state => state.hotelBooking.rateDescription);
  const userInfo = useSelector(state => state.util.userInfo);
  const systemDate = useSelector(state => state.util.systemDate);
  const [form] = Form.useForm();
  const [submitStatus, setSubmitStatus] = useState(false);
  const [card, setCard] = useState({
    type: "", 
    expYear: null, 
    expMonth: null, 
    number: null, 
    cardMonthIndex: null,
    cardYearIndex: null,
    unsupportedCard: false 
  });
  const [isSupportCard, setIsSupportCard] = useState(false);
  const priceDetailRef = useRef();
  const selectCardDateRef = useRef();
  const cardExpDateRef = useRef();
  cardExpDateRef.current = {};
  

  if(systemDate) {
    // 如果有系统时间，设置信用卡的有效期日期
    cardExpDateRef.current.selectCardMonth = cardExpMonth;
    cardExpDateRef.current.selectCardYear = getYear(systemDate.date, 11);
  }


  // 初始化设置数据
  useEffect(() => {
    // 如果没有实名认证 前往实名认证
    // if(userInfo.areaCode === '86' && !userInfo.fullNameCn) {
    //   props.history.push('/certification');
    // } else {
      // 如果没有方案描述 请求信息
      if(!rateDescription) {
        dispatch(getRateDescription(rateDetail.hotelNo, rateDetail.rate.no));
      }
     
      // 获取系统时间
      if(!systemDate) {
        dispatch(getSystemDate());
      }
      const formInitValue = {
        gender: userInfo?.gender?.value || 1,
        firstName: userInfo?.givenName || '',
        lastName: userInfo?.surName || ''
      }
      form.setFieldsValue(formInitValue);
    
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // 初始化设置数据
  useEffect(() => {
    if(rateDescription) {
      const isSupportCard = rateDescription.payTypeList.find(v => v.code === 'CREDIT_CARD') ? true : false;
      setIsSupportCard(isSupportCard);
    }
  }, [rateDescription]) // eslint-disable-line react-hooks/exhaustive-deps

  // 提交表单
  function submit(formData) {
     // 匹配英文姓名的正则
     const namePattern = /^[a-zA-Z]+$/;
    // 入住人
    if(!formData.lastName) {
      message.error('请填写入住人 拼音姓');
      return false;
    }
    const lastName = formData.lastName.toLowerCase().replace(/\s/g, '');
    if (!namePattern.test(lastName)) {
      message.error('请用拼音填写 姓 ');
      return false;
    }
    if(!formData.firstName) {
      message.error('请填写入住人 拼音名');
      return false;
    }
    // 将拼音姓名转成小写，并且去除掉字符里面所有空格
    const firstName =formData.firstName.toLowerCase().replace(/\s/g, '');
    if (!namePattern.test(firstName)) {
      message.error('请拼音填写 名');
      return false;
    }
    
    const person = [
      {
        "firstName": firstName,
        "lastName": lastName,
        "gender": formData.gender
      }
    ];
    // 如果定的是酒店会员价 必须填写酒店会员号
    if(rateDetail.rate.info?.type?.value === 2 && !formData.loyaltyCardNumber) {
      message.error('请填写酒店会员号');
      return false;
    }
    // 信用卡
    if(!card.number) {
      message.error('请填写信用卡 卡号');
      return false;
    }

    if(!card.expMonth && !card.expYear) {
      message.error('请填写信用卡 有效期');
      return false;
    }
    const creditCard = {
      "type": card.type,
      "expYear": card.expYear.value,
      "expMonth": card.expMonth.value,
      "number": card.number
    }
    const postData = {
      payType: 1,
      person,
      card: creditCard,
      loyaltyCardNumber:  formData.loyaltyCardNumber || "",
      preferBed: "",
      arrive: "",
      remark: ""
    };

    // 提交预订
    if (!isSubmit) {
      isSubmit = true;
      setSubmitStatus(isSubmit);
      gdsHotelBooking(rateDetail.hotelNo, rateDetail.rate.no, postData).then((res) => {
        isSubmit = false;
        // 预订成功 跳转到订单列表页
        props.history.replace('/order/list');
      }).catch((err) => { 
        isSubmit = false;
        // 预订失败
        message.error(err.message, 3);
        // 提示
        setTimeout(() => {
          // 返回详情页
          props.history.replace(`/hotel/detail/${rateDetail.hotelNo}`);
        }, 3000);
      });
    }
   
  }




  // 获取到用户选择的信用卡有效期
  function getCardExp(value) {
    selectCardDateRef.current.hide();
    setCard({...card, ...value});
  }

  // 获取卡号信息
  function formatCardNumber(e) {
    let unsupportedCard = false;
    let cardType = '';
    // const value = e.target.value.replace(/(\d{4})(?=\d)/g, '$1' + ' ');
    const value = e.target.value;
    
    if (value.charAt(0) === '4' && rateDescription.acceptCards.find(item => item === 'VI')) {
      cardType = 'VI';
    } else if (value.charAt(0) === '5' && rateDescription.acceptCards.find(item => item === 'MC')) {
      cardType = 'MC';
    } else if (value.charAt(0) === '3' && rateDescription.acceptCards.find(item => item === 'AX')) {
      // 如果有第2个参数
      if (value.charAt(1)) {
        if (value.charAt(1) === '7') {
          cardType = 'AX';
        } else {
          cardType = '';
          unsupportedCard = true;
        }
      }
    } else if (value !== '') {
      cardType = '';
      unsupportedCard = true;
    }
    const newCard = {...card};
    newCard.type = cardType;
    newCard.number = value;
    newCard.unsupportedCard = unsupportedCard;

    setCard(newCard);
  }

  

  return (
    <div className="hotel-booking-wrapper">
      <div className={(rateDetail?.rate?.info?.type?.value === 1 || rateDetail?.rate?.info?.type?.value === 2) ? 'room-info-box room-benefits' : 'room-info-box'}>
        <div className="tixing flex-row">
          <span className="iconfont icon-jifen" />
          <span className="fs-12">首单享4倍八大洲积分，可兑话费、航空积分、房券等。微信小程序“八大洲旅游预订”查询并使用。</span>
        </div>

        {rateDetail?.rate?.info?.type?.value === 1 && (
          <div className="zhuanxiang-card">
            <div className="title flex-row v-center">
              <span className="iconfont icon-huangguan" />
              <span className="bold">专享价</span>
            </div>
          </div>
        )}

        {rateDetail?.rate?.info?.type?.value === 2 && (
          <div className="huiyuan-card">
            <div className="title flex-row v-center">
              <span className="iconfont icon-dengji" />
              <span className="bold">酒店会员价</span>
            </div>
            <div className="huiyuan-tip">此价格仅限该酒店集团会员用户本人可享</div>
          </div>
        )}

        <div className="room-info">
          <div className="room-name">
            {rateDetail.roomDetail.name ? rateDetail.roomDetail.name : rateDetail.roomDetail.room}
            {rateDetail.roomDetail.size && (<span>({rateDetail.roomDetail.size}㎡)</span>)}
          </div>
          {(rateDetail.rate.meal?.cn || rateDetail.rate.bed?.cn) && (
            <div className="plan-title flex-row">
              {rateDetail.rate.meal?.cn && (<span className={rateDetail.rate.meal.cn !== '无早' ? 'success-color' : ''}>{rateDetail.rate.meal.cn}</span>)}
              {rateDetail.rate.bed?.cn && (<span>{rateDetail.rate.bed.cn}</span>)}
            </div>
          )}
         
          <div className="flex-row date-info v-center">
            <span className="iconfont icon-rili" />
            <span>
              {rateDetail.checkDate.checkinTxt} {rateDetail.checkDate.checkinDay} — {rateDetail.checkDate.checkoutTxt} {rateDetail.checkDate.checkoutDay}&nbsp;|&nbsp;共{rateDetail.checkDate.days}晚
            </span>
          </div>

          {rateDescription && (
            <>
            {JSON.stringify(rateDescription) === '{}' ? (
              <div className="rate-fail">
              <span className="bold danger-color">很抱歉，方案加载失败，请选择预订其他价格</span>
            </div>
            ) : (
              <>
                {rateDescription.cancellationRule && (
                  <div className="item-box cancel-box">
                    <div className="item-box-title">
                      <span className="bold">取消政策</span>
                      {!rateDetail.isChina && (<span className="fs-12">（以酒店当地时间为准）</span>)}
                    </div>
                    {/* 如果有中文取消政策 优先展示中文 */}
                    {rateDescription.cancellationRule.cn ? (
                      <div className={rateDescription.cancellationRule.cn === '不可取消' ? 'danger-color' : 'success-color'}>
                        {rateDescription.cancellationRule.cn}
                      </div>
                    ) : (
                      <div className="flex-row cancel-en">
                        <span className="cancel-info">
                          {rateDescription.cancellationRule.en}
                        </span>
                        <span
                            className="link-span fs-12"
                            data-cancelinfo={rateDescription.cancellationRule.en}
                            data-room={rateDetail.rate.room}
                            data-rate={rateDetail.rate.rate}
                        >
                          复制
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            </>
          )}
        </div>
        <div className="room-info-bg" />
      </div>
      {/* 提交表单 */}
      <div className="form-wrapper">
        <Form
          onFinish={submit}
          autoComplete="off"
          form={form}
        >

          <div className="section">
            {userInfo.riskLevel >= 0 && (
              <div className="tip-danger">
                友情提醒：请勿将我司产品及待遇，转售至第三方平台售卖，一经发现可能会影响到您帐号及积分的正常使用。
              </div>
            )}

            <div className="rp flex-row v-center h-between">
              <div className="flex-row v-center" onClick={showPersoninfo}>
                <span className="section-title">入住信息</span>
                <span className="fs-12 desc-light">(当前为 2 人入住价格)</span>
                <div className="icon-shuoming">?</div>
              </div>
            </div>
            <Row className="form-item">
              <Col span={8}>
                <Form.Item name="gender">
                  <Radio.Group optionType="button" buttonStyle="solid" size="middle">
                    <Radio.Button value={1}>女</Radio.Button>
                    <Radio.Button value={2}>男</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="lastName">
                  <Input placeholder="姓：han"  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="firstName">
                  <Input placeholder="名：meimei"  />
                </Form.Item>
              </Col>
            </Row>
            <div className="member-rate-item">
              {rateDetail.rate.info?.type?.value === 2 ? (
                <>
                  <div className="hotel-member-rate">
                    <span className="fs-12 warn-color">当前预订为酒店会员价，必须填写酒店会员号</span>
                  </div>
                  <div className="form-item flex-row member-rate">
                    <div className="label">酒店会员号</div>
                    <Form.Item className="item" name="loyaltyCardNumber">
                      <Input placeholder="必填" />
                    </Form.Item>
                  </div>
                </>
              ) : (
                <>
                  <div className="hotel-member-rate">
                    <span className="fs-12 warn-color">如有会员号，务必预订时填写，否则不累计酒店积分，</span>
                  </div>
                  <div className="form-item flex-row member-rate">
                    <div className="label">酒店会员号</div>
                    <Form.Item className="item" name="loyaltyCardNumber">
                      <Input placeholder="选填" />
                    </Form.Item>
                  </div>
                </>
              )}
              
            </div>
          </div>

          {(rateDescription && JSON.stringify(rateDescription) !== '{}' && isSupportCard) && (
            <div className="section card-section">
              <div className="card-title flex-row v-center" onClick={() => showCardInfo(rateDetail.rate.guaranteeType)}>
                <span className="section-title">信用卡信息</span>
                <span className="span-link">&nbsp;{rateDetail.rate.guaranteeType?.cn ? rateDetail.rate.guaranteeType?.cn : '担保'}</span>
                <div className="icon-shuoming">?</div>
              </div>
              
              <div className="div-info">酒店支持以下信用卡，若没有请联系在线客服担保或预付</div>
              {rateDescription.acceptCards.length > 0 && (
                <div className="card-type">
                  {rateDescription.acceptCards.map((card, index) => 
                    <span key={index} className={`card-style card${card}`}></span>
                  )}
                </div>
              )}
              {card.unsupportedCard && (<div className="rooms-tip">该酒店不支持你当前输入的信用卡</div>)}
              <div className="form-item">
                <div className="label">卡号</div>
                <Form.Item className="item" name="cardNumber">
                  <Input type="number" onChange={formatCardNumber} placeholder="信用卡卡号" />
                </Form.Item>
              </div>
              <div className="form-item exp-item" onClick={() => selectCardDateRef.current.show()}>
                <div className="label">有效期</div>
                {(card.expMonth && card.expYear) ? (
                  <span>{`${card.expMonth.text}/${card.expYear.text}`}</span>
                  ) : (
                  <span className="placeholder">月/年</span> 
                )}
              </div>
            </div>
          )}

          <div className="section">
            <div className="section-title" style={{paddingBottom: ".3rem"}}>其他信息</div>
            <div className="form-item">
              <div className="label">报销凭证</div>
              <span className="value">入住完成后，酒店前台索取</span>
            </div>
          </div>
          
          <div style={{marginTop : '15px'}}>
            <Suspense fallback={<div>Loading...</div>}>
              <Contact />
            </Suspense>
          </div>

          <Link to="/hotel/term" className="agreeTip flex-row v-center">
            <span>提交预订表示你接受：</span><span className="text-link">八大洲用户预订条款</span>
          </Link>

          

          <div className="bottom-box">
            <div className="flex-row h-between v-center">
              <div className="price-list" onClick={() => priceDetailRef.current.show()}>
                <div className="total-left">
                  <div className={rateDetail.rate.currencyCode === 'CNY' ? 'totalPrice flex-row foreign-currency' : 'totalPrice flex-row'}>
                    <span className="fs-10 desc-light">总额 </span>
                    {rateDetail.rate.currencyCode !== 'CNY' && (<span className="fs-10 desc-light">约</span>)}
                    <span className="fs-14">￥</span>
                    <span className="fs-18">{rateDetail.rate.cnyTotal}</span>
                    <div className="mingxi">
                      <span className="fs-10 desc-light">明细</span>
                      <span className='iconfont icon-xiangshangjiantou'></span>
                    </div>
                  </div>
                  {rateDetail.rate.currencyCode !== 'CNY' && (
                    <div className="flex-row v-center">
                      <div className="fs-12">
                        {rateDetail.rate.currencyCode} {rateDetail.rate.total}
                      </div>
                      <span className="fs-10 warn-color">（支付以当地货币为准）</span>
                    </div>
                  )}
                </div>
              </div>
              {(rateDescription && JSON.stringify(rateDescription) !== '{}' && isSupportCard) ? (
                <div className="booking">
                  {!submitStatus ? (
                    <Form.Item >
                      <Button type="primary" htmlType="submit" size="large">
                        提交预订
                      </Button>
                    </Form.Item>
                  ) : (
                    <Button type="primary" size="large">
                      预订中...
                    </Button>
                  )}
                </div>
              ) : (
                <Button type="primary"  size="large">
                  联系客服预订
                </Button>
              )}
            </div>
          </div>

        </Form>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Popup ref={priceDetailRef}>
          {(rateDescription && JSON.stringify(rateDescription) !== '{}') && <PriceDetail rateDetail={rateDetail} rateDescription={rateDescription} />}
        </Popup>
        <Popup ref={selectCardDateRef}>
          {systemDate && (<SelectCardDate cardExpDate={cardExpDateRef.current} card={card} getCardExp={getCardExp} />)}
        </Popup>
      </Suspense>
    </div>
  );
}

export default React.memo (HotelBooking);