import React, {  useEffect, useRef } from "react";
import { wechatGenerateScheme } from "../../../api";
import "./style.scss";

export default (props) => {
  const schemeRef = useRef();
 
  // 初始化设置参数
  useEffect(() => {
   
    const path = "/pages/index/index";
    const randomNum = parseInt(Math.random()*100000000);
    const query = `merchantNo=YCrUJCiPmz9C&randomNum=${randomNum}`;

    // 外部浏览器打开的请求跳转吗
    wechatGenerateScheme({path, query}).then((res) => {
      schemeRef.current = res.data;
    }).catch((err) => {});

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function goToMiniApp() {
    window.location.href = schemeRef.current.scheme;
  }
  
  return (
    <div className="ningbobank-wrapper h5">
      <div className="img-wrapper">
        <img src="https://staticfile.badazhou.com/20220223/a65e78a6c085abf027f13f8feaf7b984.jpeg" alt="" className="img img1" />
        <img src="https://staticfile.badazhou.com/20211229/19ec2bf6aa012ca9bf031655b6bdfd58.jpeg" alt="" className="img img2" />
        <img src="https://staticfile.badazhou.com/20211229/9d3fd13d2094b7164afd1737a9797265.jpeg" alt="" onClick={goToMiniApp} className="img img3" />
        <img src="https://staticfile.badazhou.com/20220223/7af5d0a774a66b9ac830f3cd65051b90.jpeg" alt="" className="img img4" />
        <img src="https://staticfile.badazhou.com/20220223/5e55d63afdfe585669966552c8e7dbcd.jpeg" alt="" className="img img5" />
        <img src="https://staticfile.badazhou.com/20220223/ce6c2234cbb6174b1c682dd49774c5ba.jpeg" alt="" className="img img6" />
      </div>
    </div>
  );
}
