import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

export default (props) => {
  return (
    <div className="silver-card-container">
      <img className="img img1" src="https://staticfile.badazhou.com/20210915/d0e8920cc367dc1c5057a3e25b110097.jpeg" alt="" />
      <Link to={`/`}>
      <img className="img img2" src="https://staticfile.badazhou.com/20210915/58696d11b7e849c3cae2292c44dbe879.jpeg" alt="" />
      </Link>
      <img className="img img3" src="https://staticfile.badazhou.com/20210915/c1f1d5111c916f7b5f0ca62963fc5143.jpeg" alt="" />
      <img className="img img4" src="https://staticfile.badazhou.com/20210915/97791364d92150014752e2d29ffbd74f.jpeg" alt="" />
      <img className="img img5" src="https://staticfile.badazhou.com/20210915/3984e7962923709963ecbfebdf2a68c5.jpeg" alt="" />
    </div>
  );
}