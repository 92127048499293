import * as actionTypes from "./constants";
import { myInfo, systemTime, loginStatus } from "../../api";

// 获取用户信息
export function getUserInfo() {
  return async dispatch => {
    try {
      const resData = await myInfo();
      dispatch({
        type: actionTypes.CHANGE_USER_INFO,
        value: resData.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.CHANGE_USER_INFO,
        value: {}
      })
    }
  }
}

// 改变登录的状态
export const changeLoginStatus = (data) => ({
  type: actionTypes.CHANGE_LOGIN_STATUS,
  value: data
});



// 获取登录状态
export function getLoginStatus() {
  return async dispatch => {
    try {
      const resData = await loginStatus();
      dispatch(changeLoginStatus(resData.data.loginStatus));
    } catch (error) {
      dispatch(changeLoginStatus(false));
    }
  }
}


// 获取系统时间
export function getSystemDate() {
  return async dispatch => {
    try {
      const resData = await systemTime();
      dispatch({
        type: actionTypes.CHANGE_SYSTEM_DATE,
        value: resData.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.CHANGE_SYSTEM_DATE,
        value: {}
      })
    }
  }
}